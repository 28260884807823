import React, { useState } from "react";
import LargeLoader from "../../../../UI-Components/Loader/loaders";
import Header from "../../../../UI-Components/Header/header";
import InsideTransactionHeader from "../InsideTransactionHeader";
import { Calendar } from "primereact/calendar";
import { showToast, TOAST } from "../../../../Helpers/ui-helper";
import { format } from "date-fns";
import { UserApiService } from "../../../../services/userApiService";
import { toteSummaryDTO } from "../../../../Models/TransactionDto";
import { DataTable } from "primereact/datatable";
import { toteSummaryDataList } from "../../../../Helpers/StaticData/transactionData";
import { Column } from "primereact/column";
import { getStringToDateFormat } from "../../../../Helpers/dateTimeHelpers";
import "./ToteSummary.scss";

const ToteSummary = () => {
  // variables
  const [date, setDate] = useState<Date | undefined>();
  const [loading, setLoading] = useState(false);
  const [toteSummaryData, setToteSummaryData] = useState<any>([]);
  const [globalFilter, setGlobalFilter] = useState("" as string);
  // functions
  const onDateChange = (date: any) => {
    if (date) {
      setDate(date);
    } else {
      showToast("Select Valid Date", TOAST.ERROR);
      return;
    }
  };

  const handleFilter = (val: string) => {
    setGlobalFilter(val);
  };

  const header = (
    <div className="d-flex justify-content-end">
      <div className="search-input">
        <input
          placeholder="Global Search"
          style={{ color: "#fff" }}
          type="search"
          value={globalFilter}
          onChange={(e: any) => handleFilter(e.target.value)}
        />
        <i className="fa fa-search icon"></i>
      </div>
    </div>
  );

  const handleSubmit = () => {
    let tempDate: any = date;
    let formatedDate = format(tempDate, "yyyyMMdd");
    setLoading(true);
    UserApiService.getToteSummaryByDate(formatedDate)
      .then((res) => {
        if (res.data) {
          let toteSummaryData = res.data;
          let toteSummaryDataList = [] as any;
          for (let key of toteSummaryData) {
            let toteSummaryObj = {} as toteSummaryDTO;
            toteSummaryObj.localDate = getStringToDateFormat(
              key.poolKey.localDate.toString()
            );
            toteSummaryObj.poolType = key.poolKey.poolType;
            toteSummaryObj.pzoneCode = key.poolKey.pzoneCode;
            toteSummaryObj.raceNo = key.poolKey.raceNo;
            toteSummaryObj.poolTotal = key.poolTotal.toFixed(2);
            toteSummaryObj.totalPayoff = key.totalPayoff.toFixed(2);
            toteSummaryObj.layoffTotal = key.layoffTotal.toFixed(2);
            toteSummaryDataList.push(toteSummaryObj);
          }
          setToteSummaryData(toteSummaryDataList);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        showToast(err?.response?.data?.message, TOAST.ERROR);
      });
  };

  return (
    <div className="bet_history_wrapper">
      <LargeLoader isLoading={loading} />
      <Header pageTitle="Reports" />
      <div className="content heightfull cont">
        <div className="content-left-panel sidebar">
          <InsideTransactionHeader selectionType="toteSummary" />
        </div>
        <div className="content-middle-panel w-100">
          <div className="content-panel-header head multiple">Tote Summary</div>
          <div className="inpage-nav m-3 flex-wrap">
            <div className="page-nav">
              <div className="action_bx">
                <Calendar
                  id="range"
                  onChange={(e: any) => onDateChange(e.value)}
                  value={date}
                  placeholder="Select date for bet history"
                  className="calendercss"
                />
              </div>
              <div>
                <button
                  onClick={() => handleSubmit()}
                  className="pz-btn primary rounded"
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
          <div className="pz-card">
            {toteSummaryData.length !== 0 ? (
              <DataTable
                className="pz-prime-table"
                value={toteSummaryData}
                paginator
                rows={15}
                header={header}
                globalFilter={globalFilter}
                emptyMessage="No Data found."
              >
                {toteSummaryDataList.map((col) => {
                  const { field, header } = col;
                  return (
                    <Column
                      sortable
                      key={field}
                      field={field}
                      header={header}
                    />
                  );
                })}
              </DataTable>
            ) : (
              <div className="no_data">
                <span>Data Not Available</span>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default ToteSummary;
