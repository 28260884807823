import { createContext } from "react";
import { PoolDTO } from "../Models/PoolsDTO";
import { RaceDetailsDTO } from "../Models/RaceDetailsDTO";
import { __SelToRunner } from "../Models/dynamicTypes";
import {  TotalsDTO } from "../Models/investmentDTO";
import { TableColumnsDTO } from "../Helpers/columns";
import { RiskTableDTO } from "../Models/RiskTableDTO";

export interface FIXEDODDSDTO {
  selectedRace: RaceDetailsDTO;
  selectedRaceTrips: __SelToRunner;
  totalsData: TotalsDTO;
  providersOddsList: any;
  riskTableData:RiskTableDTO;
  selectedPool: PoolDTO;
  setSelectedPool:(pool:PoolDTO) => void;
  onSelectPool: (pool: PoolDTO) => void;
  setProvidersOddsList: (list: any) => void;
  providersOddsCols: TableColumnsDTO[];
  setProvidersOddsCols: (cols: TableColumnsDTO[]) => void;
  selectedProgramNo: string;
  setSelectedProgramNo: (pNo: string) => void;
  selProviderOdds: any;
  setSelProviderOdds: (pOdds: any) => void;
  setRiskTableData:(data:RiskTableDTO) => void;
 }

export const FixedOddsContext = createContext({} as FIXEDODDSDTO);

export const FixedOddsContextProvider = FixedOddsContext.Provider;