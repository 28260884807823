export interface TableColumnsDTO {
  header: string;
  field: string;
  width?: string;
  toolTip?: string;
}

export const ColsPayOff: TableColumnsDTO[] = [
  { field: "selId", header: "No.", width: "" },
  { field: "payOff", header: "PayOff", width: "" },
];

export const ColsOdds: TableColumnsDTO[] = [
  { field: "selId", header: "No.", width: "5%", toolTip: "selId" },
  {
    field: "runnerName",
    header: "Runner Name",
    width: "20%",
    toolTip: "Runner Name",
  },
  {
    field: "suggestedOdds",
    header: "Suggested Odds",
    width: "10%",
    toolTip: "Suggested Odds",
  },
  { field: "odds", header: "Odds", width: "10%", toolTip: "Odds" },
  {
    field: "selLb",
    header: "MSL",
    width: "8%",
    toolTip: "Maximum Selection Liabilities",
  },
  {
    field: "maxBetAmounts",
    header: "MBA",
    width: "8%",
    toolTip: "Maximum Bet Amount",
  },
  {
    field: "mai",
    header: "MAI",
    width: "8%",
    toolTip: "Maximum Available Investment",
  },
  {
    field: "usedLB",
    header: "Liabilities",
    width: "8%",
    toolTip: "Current Liabilities",
  },
  { field: "betsCount", header: "Bets", width: "8%", toolTip: "Bets" },
  {
    field: "layOffInvestment",
    header: "LI",
    width: "5%",
    toolTip: "layOff Investment",
  },
  {
    field: "investment",
    header: "TI",
    width: "8%",
    toolTip: "Total Investment",
  },
  { field: "profit", header: "Position", width: "8%", toolTip: "Position" },
];

export const ToteCols: TableColumnsDTO[] = [
  { field: "selId", header: "No.", toolTip: "selId" },
  {
    field: "runnerName",
    header: "Runner Name",
    toolTip: "Runner Name",
  },
  {
    field: "investment",
    header: "Investment",
    toolTip: "Total Investment",
  },
  { field: "layoff", header: "Layoff", toolTip: "Layoff" },
];

export const ExoticsCol = [
  { field: "poolType", header: "Pool Type" },
  { field: "Investment", header: "Total Investment" },
  { field: "layoff", header: "Layoff" },
  { field: "totalPayoff", header: "Payoff Total" },
];

export const ColsOddsINV: TableColumnsDTO[] = [
  { field: "formattedLastUpdated", header: "Last Updated", width: "40%" },
  { field: "odds", header: "Odds", width: "20%" },
  { field: "investment", header: "Investment", width: "40%" },
];

export const ColsNewOdds: TableColumnsDTO[] = [
  { field: "selId", header: "No.", width: "5%" },
  { field: "runnerName", header: "Runner Name", width: "20%" },
  { field: "layOff", header: "Lay Off [%]", width: "8%" },
  { field: "odds", header: "Odds", width: "10%" },
  { field: "selLb", header: "Liabilities", width: "10%" },
  { field: "maxBetAmounts", header: "Max Bet Amt.", width: "10%" },
];

export const ColsDefaultRaceTrip: TableColumnsDTO[] = [
  { field: "programNo", header: "No.", width: "" },
  { field: "horseName", header: "Horse", width: "" },
  { field: "jockeyName", header: "Jockey", width: "" },
  { field: "trainerName", header: "Trainer", width: "" },
  { field: "age", header: "Age", width: "" },
  { field: "sex", header: "Sex", width: "" },
  { field: "breedType", header: "Breed Type", width: "" },
  { field: "barrierPos", header: "Bar. Pos", width: "" },
  { field: "horseWeight", header: "Horse Weight", width: "" },
  { field: "carriedWeight", header: "Carried Weight", width: "" },
  { field: "coupled", header: "Coupled", width: "" },
  { field: "scratched", header: "Scratched", width: "" },
];

export const ColsPoolRules: TableColumnsDTO[] = [
  { field: "province", header: "Province", width: "2%" },
  { field: "eventType", header: "Type", width: "2%" },
  { field: "trackName", header: "Track Name", width: "4%" },
  { field: "provider", header: "Provider", width: "4%" },
  { field: "accountId", header: "AccountId Id", width: "5%" },
  { field: "pools", header: "Pool Actions", width: "3%" },
  { field: "deleted", header: "Deleted", width: "2%" },
];

export const ColsOddsRules: TableColumnsDTO[] = [
  { field: "profileName", header: "Profile Name", width: "2%" },
  { field: "created", header: "Created", width: "3%" },
  { field: "lastUpdated", header: "Last Updated", width: "4%" },
  { field: "odds", header: "Odds Actions", width: "4%" },
  { field: "deleted", header: "Delete", width: "2%" },
];

export const ColsCustomer: TableColumnsDTO[] = [
  { field: "customerKey", header: "Customer Key", width: "4%" },
  { field: "customerName", header: "Customer Name", width: "3%" },
  { field: "email", header: "Email", width: "4%" },
  { field: "phoneNo", header: "Phone Number", width: "4%" },
  { field: "view", header: "View Summary", width: "4%" },
];

export const viewDataCols = [
  { field: "provider", header: "Provider" },
  { field: "percentage", header: "Percentage" },
];

export const customerSummaryCols = [
  { field: "localDate", header: "Local Date" },
  { field: "walletId", header: "WalletId" },
  { field: "default", header: "Default" },
  { field: "bets", header: "Bets" },
  { field: "winningBets", header: "Winning Bets" },
  { field: "betAmount", header: "BetAmount" },
  { field: "winBetAmount", header: "Win Bet Amount" },
  { field: "strickRate", header: "Strick Rate" },
];

export const oddsProfileCols: TableColumnsDTO[] = [
  { field: "action", header: "Odds Action" },
  { field: "oddsChangeProfileKey", header: "Odds Profile Key" },
  { field: "miniOddsPer", header: "Mini Odds Per" },
  { field: "selTotalPer", header: "Sel Total Per" },
];

export const CustomerRulesCols = [
  { field: "ruleId", header: "Rule ID" },
  { field: "ruleName", header: "Rule Name" },
];
export const columnsOfCustomerRule: TableColumnsDTO[] = [
  { field: "ruleName", header: "Rule Name" },
  { field: "prefNo", header: "Preference No." },
  { field: "actionType", header: "Action Type" },
  { field: "reducedMaxBet", header: "Reduced Max Bet" },
  { field: "reducedOdds", header: "Reduced Odds" },
  { field: "filter", header: "Filter" },
  { field: "edit_delete", header: "Edit / Delete" },
];
export const searchCustomerReportColumn: TableColumnsDTO[] = [
  { field: "customerKey", header: "Customer Key", width: "10%" },
  { field: "avgNpr", header: "Avarage NPR", width: "10%" },
  { field: "strickRate", header: "Strike Rate", width: "10%" },
  { field: "totalBets", header: "Total Bets", width: "7%" },
  { field: "totalInvested", header: "Total Invested", width: "10%" },
  { field: "totalRaces", header: "Total Races", width: "8%" },
  { field: "totalWinBets", header: "Total WinBets", width: "5%" },
  { field: "totalWinnings", header: "Total Winnings", width: "5%" },
  { field: "view", header: "View Summary", width: "10%" },
];
export const raceTimeColumn: TableColumnsDTO[] = [
  { field: "localDate", header: "Local Date", width: "10%" },
  { field: "raceNo", header: "Race No", width: "5%" },
  { field: "pzoneCode", header: "Pzone Code", width: "15%" },
  { field: "raceOpenTime", header: "Race Open Time", width: "10%" },
  { field: "timeZone", header: "Time Zone", width: "10%" },
  { field: "edit", header: "Edit", width: "5%" },
];
