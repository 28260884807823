import { PoolKeyDTO } from "../Models/PoolsDTO";
import { RaceKeyDTO } from "../Models/RaceDetailsDTO";
import { RaceTripKeyDTO } from "../Models/raceTripsDTO";
import { LOCAL_STORAGE } from "./constants";

export const getRaceKeyStr = (raceKey: RaceKeyDTO | any): string => {
  if (raceKey.raceNo < 10) {
    return raceKey.localDate + "~" + raceKey.pzoneCode + "~0" + raceKey.raceNo;
  }
  return raceKey.localDate + "~" + raceKey.pzoneCode + "~" + raceKey.raceNo;
};

export const getPoolKeyStr = (poolKey: PoolKeyDTO): string => {
  let no = poolKey.raceNo < 10 ? "~0" : "~";
  return (
    poolKey.localDate +
    "~" +
    poolKey.pzoneCode +
    no +
    poolKey.raceNo +
    "~" +
    poolKey.poolType +
    "~" +
    poolKey.provider
  );
};

export const getRaceTripKeyStr = (tripKey: RaceTripKeyDTO): string => {
  return (
    tripKey.localDate +
    "~" +
    tripKey.pzoneCode +
    "~" +
    tripKey.raceNo +
    "~" +
    tripKey.selId
  );
};

export const getTrackName = (pzoneCode: string) => {
  if (pzoneCode) {
    let track = pzoneCode
      .split("-")[1]
      .split("_")
      .join(" ")
      .toLowerCase()
      .replace(/\b./g, function (a) {
        return a.toUpperCase();
      });
    return track;
  }
  return pzoneCode;
};

export const raceKeySplit = (
  pzoneCode: string,
  char: string,
  index: number
) => {
  return pzoneCode?.split(char)[index];
};

export const formatNullToZero = (val: number) => {
  if (val === 98765.56789 || val === null) {
    return 0;
  }
  return Number(val.toFixed(2));
};
export const checkNull = (val: string) => {
  if (!val || val === "DEF") {
    return "";
  } else return val;
};
export const setLocalStorage = (key: string, value: string) =>
  localStorage.setItem(key, value);

export const getLocalStorage = (key: string) => localStorage.getItem(key);

// send headers to Authenticate API calls
export const getHeaders = () => {
  let jwt = getLocalStorageObject(LOCAL_STORAGE.JWT);
  let token = jwt?.type + " " + jwt?.token;
  return { Authorization: token };
};

// Get data from local storage and parse JSON
export const getLocalStorageObject = (key: string) => {
  let obj = localStorage.getItem(key);
  if (obj) return JSON.parse(obj);
  return null;
};

// Used in search input for dynamic objects
export const filterValues = (
  value: string,
  list: any[],
  filteredList: any[],
  key?: string
) => {
  let _filteredValues;
  if (!value.trim().length) {
    _filteredValues = [...list];
  } else {
    _filteredValues = filteredList.filter((obj) => {
      if (key) return obj[key].toLowerCase().includes(value.toLowerCase());
      return obj.toLowerCase().includes(value.toLowerCase());
    });
  }
  return _filteredValues;
};

// Value to show in Input Field for number type
export const getNumberValue = (value: number) => {
  return value.toString() === "0" ? "" : value.toString();
};

export const numberFormate = (value: any) => {
  return value
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const camelize = (str: any) => {
  return str.replace(/\w\S*/g, function (txt: any) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};


export const onPoolKeySeparate = (poolKey: any): string => {
  let splitStr = poolKey.split("~");
  let _getTrackName = getTrackName(splitStr[1]);
  let str = _getTrackName + " " + splitStr[2] + " " + splitStr[3];
  return str;
};