import { ROLES, ROUTES } from "./constants";
import {
  hasConnectorPermission,
  hasCustomerSummaryPermission,
  hasLogsPermission,
  hasReportPermission,
  hasRiskPermission,
  hasRulesPermission,
  hasSchedulePermission,
  hasTotePermission,
  hasUsersAccess,
} from "./permission-helpers";

export const getRoleBaseRoute = (roles: any) => {
  if (roles.includes(ROLES.ADMIN)) {
    return ROUTES.SCHEDULE;
  }
  if (hasSchedulePermission(roles)) {
    return ROUTES.SCHEDULE;
  }

  if (hasRiskPermission(roles)) {
    return ROUTES.RULES;
  }

  if (hasTotePermission(roles)) {
    return ROUTES.TOTE_MANAGEMENT;
  }

  if (hasConnectorPermission(roles)) {
    return ROUTES.CONNECTORS;
  }

  if (hasUsersAccess(roles)) {
    return ROUTES.USER_ACCESS;
  }

  if (hasRulesPermission(roles)) {
    return ROUTES.RULES;
  }

  if (hasLogsPermission(roles)) {
    return ROUTES.LOGS;
  }

  if (hasReportPermission(roles)) {
    return ROUTES.REPORTS;
  }

  if (hasCustomerSummaryPermission(roles)) {
    return ROUTES.CUSTOMER_SUMMARY;
  }

  return false;
};
