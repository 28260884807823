import { Link } from "react-router-dom";
import { ROUTES } from "../../../Helpers/constants";

export interface SelectionTypeProps {
  selectionType?: string;
}

export default function InsideTransactionHeader(props: SelectionTypeProps) {
  return (
    <>
      <div className="content-panel-header head multiple">Wagers Data</div>
      <div className="b-bottom side-nav">
        <div className="" style={{ display: "flex", flexDirection: "column" }}>
          {(props.selectionType === "wagersData" ||
            props.selectionType === "CustomerBetHistoryData" ||
            props.selectionType === "toteSummary") && (
            <>
              <Link
                to={ROUTES.WAGERSHISTORY}
                className={
                  props.selectionType === "wagersData"
                    ? "nav-btn active"
                    : "nav-btn"
                }
              >
                {" "}
                Wagers History
                <i className="fa fa-caret-right"></i>
              </Link>
              <Link
                to={ROUTES.CUSTOMERHISTORY}
                className={
                  props.selectionType === "CustomerBetHistoryData"
                    ? "nav-btn active"
                    : "nav-btn"
                }
              >
                {" "}
                Customer Wagers History
                <i className="fa fa-caret-right"></i>
              </Link>
              <Link
                to={ROUTES.TOTESUMMARY}
                className={
                  props.selectionType === "toteSummary"
                    ? "nav-btn active"
                    : "nav-btn"
                }
              >
                {" "}
                Tote Summary
                <i className="fa fa-caret-right"></i>
              </Link>
            </>
          )}
          {(props.selectionType === "raceLogs" ||
            props.selectionType === "raceTrip" ||
            props.selectionType === "poolLogs") && (
            <>
              <Link
                to={ROUTES.RACELOGS}
                className={
                  props.selectionType === "raceLogs"
                    ? "nav-btn active"
                    : "nav-btn"
                }
              >
                {" "}
                Race Logs
                <i className="fa fa-caret-right"></i>
              </Link>
              <Link
                to={ROUTES.RACETRIP}
                className={
                  props.selectionType === "raceTrip"
                    ? "nav-btn active"
                    : "nav-btn"
                }
              >
                {" "}
                Race Trip Logs
                <i className="fa fa-caret-right"></i>
              </Link>
              <Link
                to={ROUTES.POOLLOGS}
                className={
                  props.selectionType === "poolLogs"
                    ? "nav-btn active"
                    : "nav-btn"
                }
              >
                {" "}
                Pool Logs
                <i className="fa fa-caret-right"></i>
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
}
