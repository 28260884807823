import { ChangeEvent, useContext, useState } from "react";
import { RulesContext } from "../../../../../Context/rulesContext";
import { DeductionUsingOptions } from "../../../../../Helpers/StaticData/pool-data-static";
import {
  showAPIToast,
  showToast,
  TOAST,
} from "../../../../../Helpers/ui-helper";
import { getNumberValue } from "../../../../../Helpers/valueHelper";
import { ScratchProfileDTO } from "../../../../../Models/scratchProfileDTO";
import { RulesService } from "../../../../../services/rulesApiService";
import PZInputField from "../../../../../UI-Components/Inputs/inputField";
import PzDropDown from "../../../../../UI-Components/Inputs/pzDropDown";
import { ScreenType } from "../scratchProfile";

export interface IAddScrProfileProps {
  changeScreen: (screen: ScreenType) => void;
}

export default function AddScrProfile(props: IAddScrProfileProps) {
  const [profile, setProfile] = useState({} as ScratchProfileDTO);
  const rlContext = useContext(RulesContext);

  const onChangeProfile = (e: ChangeEvent<HTMLInputElement>) => {
    let _profile = { ...profile } as any;
    _profile[e.target.name] = e.target.value;
    setProfile(_profile);
  };

  const addScratchProfile = () => {
    let _profile = { ...profile };
    let context = { ...rlContext };
    context.startLoader();
    RulesService.addScratchedProfile(_profile)
      .then((res) => {

        if (res.data) {
          let profile = res.data as ScratchProfileDTO;
          context.scrProfiles[
            profile.scratchedProfileKey?.scratchedProfileId
          ] = profile;

          showToast("New Profile Added Successfully!", TOAST.SUCCESS);
          props.changeScreen(ScreenType.VIEW);
          setProfile({} as ScratchProfileDTO);
        }
        context.isLoading = false;
        context.updateRuleContext(context);
      })
      .catch((err) => {
        context.stopLoader();
        console.error(err);
        showAPIToast(err, "while adding new scratch profile", "scr_p");
      });
  };

  return (
    <div className="" style={{ flexGrow: 1 }}>
      <header className="page-head">Add New Scratch Profile</header>
      <div className="inline-height p-4">
        <div className="add-scr-profile-form">
          <div className="b-bottom p-3">
            <h2 className="font-1 text-center">Enter Below Details</h2>
          </div>

          <div className="w-75 mx-auto pt-4 ">
            <div className="py-2">
              <PZInputField
                type="text"
                label="Profile Name"
                value={profile.profileName ?? ""}
                name="profileName"
                placeholder="Enter unique profile name"
                className=" my-4 "
                onChange={onChangeProfile}
              />
            </div>

            <div className="py-2">
              <PzDropDown
                options={DeductionUsingOptions}
                value={profile.deductionUsing}
                name="deductionUsing"
                onChange={onChangeProfile}
                label="Deduction Using"
                placeHolder="Select deduction using..."
                className="my-4"
              />
            </div>
            {profile.deductionUsing === "BY_PER" && (
              <div className="py-2">
                <PZInputField
                  type="number"
                  label="Scratched Deduction [%]"
                  value={getNumberValue(profile.scratchedDedPer ?? 0)}
                  placeholder="Enter Deduction Percentage"
                  className=" my-4 "
                  name="scratchedDedPer"
                  onChange={onChangeProfile}
                />
              </div>
            )}

            <div className="p-2 text-right">
              <button
                onClick={() => props.changeScreen(ScreenType.VIEW)}
                className="pz-btn text-btn ml-auto"
              >
                {" "}
                <i className="fa fa-times"></i> Cancel
              </button>
              <button
                onClick={addScratchProfile}
                className="pz-btn primary ml-auto"
              >
                {" "}
                <i className="fa fa-check"></i> Add Profile
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
