import { ChangeEvent, useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../Context/userContext";
import { CRUD, Forms } from "../../../../Helpers/StaticData/race-data";
import { UserRoles } from "../../../../Helpers/StaticData/user-mng-data";
import { showToast, TOAST } from "../../../../Helpers/ui-helper";
import { UserDTO } from "../../../../Models/UserManagementDto";
import { UserApiService } from "../../../../services/userApiService";
import PZInputField from "../../../../UI-Components/Inputs/inputField";
import PzMultiSelect from "../../../../UI-Components/Inputs/pzMultiSelect";

export interface IModifyUserTemplateProps {
  selectedUser: UserDTO;
  changeForm: any;
  updateUserList: any;
}

export default function ModifyUserTemplate(props: IModifyUserTemplateProps) {
  const [user, setUser] = useState<UserDTO>(props.selectedUser);
  const userContext = useContext(UserContext);
  useEffect(() => {
    setUser({ ...props.selectedUser });
  }, [props.selectedUser]);

  const onUpdateUser = () => {
    userContext.startLoader();
    let _user = { ...user };

    UserApiService.updateUser(_user)
      .then((res) => {
        if (res.data) {
          showToast("User data updated successfully!", TOAST.SUCCESS);
          props.changeForm(Forms.VIEW);
          props.updateUserList(res.data, CRUD.UPDATE);
        }
        userContext.stopLoader();
      })
      .catch((err) => {
        console.error(err);
        userContext.stopLoader();
        if (err.response) {
          showToast(err.response.data.message, TOAST.ERROR);
        }
      });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let _user: any = { ...user };
    _user[e.target.name] = e.target.value;
    setUser(_user);
  };

  const selectRoles = (e: any) => {
    let _user = { ...user };
    _user.roles = e.target.value;
    setUser(_user);
  };

  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.code === "Enter") {
      onUpdateUser();
    }
  };

  const getRolesBox = () => {
    if (user.roles.includes("ADMIN")) return;
    return (
      <PzMultiSelect
        className="py-4"
        options={UserRoles}
        value={user.roles}
        onChange={selectRoles}
        placeHolder="Select roles for user"
        label="Roles *"
      />
    );
  };

  return (
    <div className="d-flex justify-content-between">
      <div className="p-3 w-25">
        <div className="p-2">
          <PZInputField
            name="email"
            value={user.email}
            onChange={handleChange}
            type="email"
            label="Email"
            onKeyUp={handleKeyUp}
            placeholder="Enter email"
            disabled
          />
        </div>
        <div className="p-2">
          <PZInputField
            name="userName"
            value={user.userName}
            onChange={handleChange}
            type="text"
            label="User Name"
            placeholder="Enter user name"
            onKeyUp={handleKeyUp}
          />
        </div>
        <div className="p-2">
          <PZInputField
            name="phoneNo"
            value={user.phoneNo}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
            type="tel"
            label="Contact No"
            placeholder="Enter email"
          />
        </div>
        <div className="p-2">
          {/* <SearchableInput
            className="m-0"
            label="Roles"
            placeholder="Add roles to the user"
            selectedValues={roles}
            setSelectedValues={selectRoles}
          /> */}
          {getRolesBox()}
        </div>

        <div className="p-3 py-4 mt-4">
          <button onClick={onUpdateUser} className="pz-btn primary">
            <i className="fa fa-pencil"></i> Update{" "}
          </button>

          <button
            onClick={() => props.changeForm(Forms.VIEW)}
            className="pz-btn text-btn"
          >
            <i className="fa fa-times"></i> Cancel{" "}
          </button>
        </div>
      </div>
    </div>
  );
}
