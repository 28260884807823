import { useContext, useRef, useState } from "react";
import { RulesContext } from "../../../../../Context/rulesContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
//@ts-ignore
import ReactCountryFlag from "react-country-flag";
import "./toteRuleTable.scss";
import { RulesService } from "../../../../../services/rulesApiService";
import { PoolRuleDTO } from "../../../../../Models/poolRuleDTO";
import {
  showAPIToast,
  showToast,
  TOAST,
} from "../../../../../Helpers/ui-helper";
import { confirmPopup } from "primereact/confirmpopup";
import { ColsPoolRules } from "../../../../../Helpers/columns";
import { OverlayPanel } from "primereact/overlaypanel";
import {
  _formatRule,
  _sortPoolRules,
} from "../../../../../Helpers/RulesManage/rulesHelper";
import { PoolsList } from "../../../../../Helpers/StaticData/pool-data-static";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { findIndex } from "../../../../../Helpers/list-helpers";

export interface IPoolRuleTableProps {
  onAddRuleClick: () => void;
  connectorList: any;
  getPoolRules: any;
}

export default function PoolRuleTable(props: IPoolRuleTableProps) {
  const ruleContext = useContext(RulesContext);
  const [selectedRow, setSelectedRow] = useState({} as PoolRuleDTO);
  const viewPanelRef = useRef(null as any);
  const editpanelRef = useRef(null as any);
  const [globalFilter, setGlobalFilter] = useState("");

  // delete selected poolRule
  const deletePoolRule = (rule: PoolRuleDTO) => {
    let context = { ...ruleContext };
    context.startLoader();
    RulesService.deletePoolRule(rule.poolRuleKey)
      .then((res) => {
        if (res.data) {
          let _rows = [...context.poolRuleRows] as PoolRuleDTO[];
          let key = rule.poolRuleKey.provider + rule.poolRuleKey.track;
          let index = _rows.findIndex(
            (x: any) => x.poolRuleKey.provider + x.poolRuleKey.track === key
          );
          _rows.splice(index, 1);
          context.poolRuleRows = _sortPoolRules(_rows);
          showToast("Pool deleted successfully!", TOAST.SUCCESS);
        }
        context.isLoading = false;
        context.updateRuleContext(context);
      })
      .catch((err) => {
        context.stopLoader();
        console.error(err);
        showAPIToast(err, "while deleting tote rule!", "del_pRule");
      });
  };

  // Confirmation popup to delete selected pool rule
  const confirmDelete = (event: any, rule: PoolRuleDTO) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to delete this pool rule?",
      icon: "pi pi-exclamation-triangle",
      accept: () => deletePoolRule(rule),
      reject: () => {},
    });
  };

  // open overlay panel to view pool list
  const openViewPanel = (e: any, rowData: PoolRuleDTO) => {
    setSelectedRow(rowData);
    if (editpanelRef && editpanelRef.current) editpanelRef.current.hide(e);
    if (viewPanelRef && viewPanelRef.current) viewPanelRef.current.toggle(e);
  };

  // Open overlay panel to edit pool rule
  const openEditPanel = (e: any, rowData: PoolRuleDTO) => {
    setSelectedRow(rowData);
    if (viewPanelRef && viewPanelRef.current) viewPanelRef.current.hide(e);
    if (editpanelRef && editpanelRef.current) editpanelRef.current.toggle(e);
  };

  // On value change of rule edit panel
  const onEditChange = (e: any) => {
    let row = { ...selectedRow } as any;
    row[e.target.name] = e.target.value;
    setSelectedRow(row);
  };

  // To update pool rule after editing
  const onUpdateRowClick = () => {
    let _rule = { ...selectedRow } as any;
    let context = { ...ruleContext };
    context.startLoader();
    delete _rule.eventType;
    delete _rule.ruleKey;
    delete _rule.trackName;
    delete _rule.province;
    delete _rule.provider;
    delete _rule.created;
    delete _rule.createdBy;
    delete _rule.lastUpdated;
    delete _rule.updatedBy;
    delete _rule.timezone;
    // _rule.provider = selectedRow.provider;
    console.log(_rule);
    RulesService.updatePoolRule(_rule)
      .then((res) => {
        if (res.data) {
          let rule = _formatRule(res.data);
          let index = findIndex(context.poolRuleRows, rule, "ruleKey");
          context.poolRuleRows[index] = rule;
          showToast("Rule updated successfully!", TOAST.SUCCESS);
          props.getPoolRules();
          if (editpanelRef && editpanelRef.current) editpanelRef.current.hide();
        }
        context.isLoading = false;
        context.updateRuleContext(context);
      })
      .catch((err) => {
        console.log(err);
        context.stopLoader();
        showAPIToast(err, "while updating pool rule", "pool-rule");
      });
  };

  // Templates
  // Pool action buttons table template
  const poolsTemplate = (rowData: PoolRuleDTO) => {
    return (
      <>
        <button className="pool-btn" onClick={(e) => openViewPanel(e, rowData)}>
          <i className="fa fa-eye"></i> &nbsp;&nbsp; View
        </button>
        <button
          disabled={rowData.deleted}
          className="pool-btn"
          onClick={(e) => openEditPanel(e, rowData)}
        >
          <i className="fa fa-pencil"></i> &nbsp;&nbsp; Edit
        </button>
      </>
    );
  };

  // Delete button cell template
  const deletCellTemplate = (rowData: PoolRuleDTO) => {
    if (rowData.deleted)
      return (
        <span className="text-danger font-weight-bold text-center">
          Deleted
        </span>
      );
    return (
      <button
        onClick={(e) => confirmDelete(e, rowData)}
        className="pool-btn danger"
      >
        Delete
      </button>
    );
  };

  // province cell template
  const provinceTemplate = (province: string) => {
    return (
      <>
        <ReactCountryFlag
          style={{
            fontSize: "2em",
            height: "1em",
            width: "1.25em",
            borderRadius: "0.5rem",
          }}
          svg
          aria-label={province}
          className="emojiFlag"
          countryCode={province}
          title={province}
        />
        &nbsp;&nbsp; <span className="font-weight-bold">{province}</span>
      </>
    );
  };

  // Table header template with global search
  const headerTemplate = (
    <div className="table-header b-bottom d-flex justify-content-end pb-3">
      <div className="search-input" style={{ width: "250px", color: "#fff" }}>
        <input
          placeholder="Global Search"
          type="search"
          value={globalFilter}
          onChange={(e: any) => setGlobalFilter(e.target.value)}
        />
        {globalFilter.length > 0 ? (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setGlobalFilter("")}
          >
            <i className="fa fa-close icon"></i>
          </span>
        ) : (
          <i className="fa fa-search icon"></i>
        )}
      </div>
    </div>
  );

  // Overlay panel to view selected row pools list
  const ViewPanel = (
    <OverlayPanel
      ref={viewPanelRef}
      showCloseIcon
      id="overlay_panel"
      style={{ width: "360px" }}
      className="pz-overlaypanel"
    >
      <div className="overlay-body">
        {selectedRow.pools?.map((pool, index) => {
          return (
            <div key={pool} className="pool-item">
              {index + 1}. &nbsp; {pool}
            </div>
          );
        })}
      </div>
    </OverlayPanel>
  );

  // Overlay panel to edit selected row provider and pools
  const EditPanel = (
    <OverlayPanel
      ref={editpanelRef}
      showCloseIcon
      id="overlay_panel"
      style={{ width: "360px" }}
      className="pz-overlaypanel"
    >
      <div className="overlay-body">
        {/* <div className="panel-input-container">
          <label className="multiselect-label">Provider</label>
          <Dropdown
            options={AllProvidersList}
            className="panel-multiselect"
            onChange={onEditChange}
            value={selectedRow.provider}
            name="provider"
          />
        </div> */}

        <div className="panel-input-container">
          <label className="multiselect-label">Pools</label>
          <MultiSelect
            options={PoolsList}
            className="panel-multiselect"
            onChange={onEditChange}
            value={selectedRow.pools}
            name="pools"
          />
        </div>

        <div className="panel-input-container">
          <label className="multiselect-label">Select Connector</label>
          <Dropdown
            value={selectedRow.accountId}
            options={props.connectorList}
            onChange={onEditChange}
            className="panel-multiselect"
            name="accountId"
          />
        </div>
        <div>
          <button onClick={onUpdateRowClick} className="update-btn p-2">
            <i className="fa fa-check"></i> &nbsp; Update
          </button>
        </div>
      </div>
    </OverlayPanel>
  );

  const rowBodyTemplate = (rowData: any, field: string) => {
    if (rowData.deleted)
      return <span className="text-muted">{rowData[field]}</span>;
    if (field === "provider") {
      return rowData.poolRuleKey[field];
    }
    return rowData[field];
  };

  return (
    <div className="pool-rules-card shadow">
      {ViewPanel}
      {EditPanel}
      {ruleContext.poolRuleRows?.length > 0 ? (
        <DataTable
          globalFilter={globalFilter}
          header={headerTemplate}
          paginator
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={12}
          rowsPerPageOptions={[12, 20, 50]}
          className="pz-prime-table"
          value={ruleContext.poolRuleRows ?? []}
        >
          {ColsPoolRules.map((col, index) => {
            if (col.field === "province")
              return (
                <Column
                  key={index}
                  style={{ width: col.width }}
                  field={col.field}
                  header={col.header}
                  sortable
                  body={(rowData) => provinceTemplate(rowData[col.field])}
                />
              );

            if (col.field === "deleted")
              return (
                <Column
                  key={index}
                  style={{ width: col.width }}
                  field={col.field}
                  header={col.header}
                  sortable
                  body={(rowData) => deletCellTemplate(rowData)}
                />
              );
            if (col.field === "pools")
              return (
                <Column
                  key={index}
                  style={{ width: col.width }}
                  field={col.field}
                  header={col.header}
                  body={(rowData) => poolsTemplate(rowData)}
                />
              );
            return (
              <Column
                key={index}
                style={{ width: col.width }}
                field={col.field}
                header={col.header}
                body={(rowData) => rowBodyTemplate(rowData, col.field)}
                sortable={col.field !== "pools"}
              />
            );
          })}
        </DataTable>
      ) : (
        <div className="empty-data">No Data Available</div>
      )}
    </div>
  );
}
