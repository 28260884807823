import axios from "axios";
import { SERVER } from "../Helpers/constants";
import { getHeaders } from "../Helpers/valueHelper";
import { ManageRequestDTO, PoolDTO, PoolKeyDTO } from "../Models/PoolsDTO";
import { RaceDetailsDTO, ResultsDTO } from "../Models/RaceDetailsDTO";
import { RaceTripDTO } from "../Models/raceTripsDTO";

export const ScheduleApiService = {
  getConnectorInfo() {
    let url =
      SERVER.URL_SCHEDULE_MNG +
      SERVER.MODULE.SCHEDULE +
      "get-pool-connectors-status";
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  getProvinces() {
    let url = SERVER.URL_SCHEDULE_MNG + SERVER.MODULE.SCHEDULE + "province/";
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },

  getResults(raceKey: string) {
    let url =
      SERVER.URL_SCHEDULE_MNG +
      SERVER.MODULE.SCHEDULE +
      "raceResults/" +
      raceKey;
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },

  getRaceTrips(raceKeyStr: string) {
    let url =
      SERVER.URL_SCHEDULE_MNG +
      SERVER.MODULE.SCHEDULE +
      "tripsRace/" +
      raceKeyStr;
    let header = getHeaders();
    return axios.get(url, { headers: header });
  },
  // getMatchedPools(poolKey: string) {
  //   let url =
  //     SERVER.URL_SCHEDULE_MNG +
  //     SERVER.MODULE.SCHEDULE +
  //     "get-matched-pools/" +
  //     poolKey;
  //   let header = getHeaders();
  //   return axios.get(url, { headers: header });
  // },
  getPoolsByTrack(pools: any) {
    let url =
      SERVER.URL_SCHEDULE_MNG + SERVER.MODULE.SCHEDULE + "add-track-pools";
    let header = getHeaders();
    return axios.post(url, pools, { headers: header });
  },
  getProvinceTrack(province: string) {
    let url =
      SERVER.URL_SCHEDULE_MNG + SERVER.MODULE.SCHEDULE + "tracks/" + province;
    let header = getHeaders();
    return axios.get(url, { headers: header });
  },
  getProviderList(country: string, poolCategory: string) {
    let url =
      SERVER.URL_SCHEDULE_MNG +
      SERVER.MODULE.SCHEDULE +
      "get-linked-providers/" +
      country +
      "/" +
      poolCategory;
    let header = getHeaders();
    return axios.get(url, { headers: header });
  },
  

  addRaceDetails(raceDetails: RaceDetailsDTO) {
    let url = SERVER.URL_SCHEDULE_MNG + SERVER.MODULE.SCHEDULE + "add-race";
    let header = getHeaders();
    return axios.post(url, raceDetails, { headers: header });
  },

  updateRaceDetails(raceDetails: RaceDetailsDTO) {
    let url = SERVER.URL_SCHEDULE_MNG + SERVER.MODULE.SCHEDULE + "update-race";
    let header = getHeaders();
    return axios.post(url, raceDetails, { headers: header });
  },

  saveAllRaceTrips(raceTrips: RaceTripDTO[]) {
    let url = SERVER.URL_SCHEDULE_MNG + SERVER.MODULE.SCHEDULE + "trips";
    let header = getHeaders();

    return axios.post(url, raceTrips, { headers: header });
  },

  saveSingleRaceTrip(raceTrip: RaceTripDTO) {
    let url = SERVER.URL_SCHEDULE_MNG + SERVER.MODULE.SCHEDULE + "trip";
    let header = getHeaders();

    return axios.post(url, raceTrip, { headers: header });
  },
  getRacePools(raceKeyStr: string) {
    let url =
      SERVER.URL_SCHEDULE_MNG +
      SERVER.MODULE.SCHEDULE +
      "poolsRace/" +
      raceKeyStr;
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  // addPoolConfig(pool: PoolDTO) {
  //   let url =
  //     SERVER.URL_SCHEDULE_MNG + SERVER.MODULE.SCHEDULE + "add-pool-config";
  //   let headers = getHeaders();
  //   return axios.post(url, pool, { headers: headers });
  // },
  getCreatePools(pool: any) {
    let url = SERVER.URL_SCHEDULE_MNG + SERVER.MODULE.SCHEDULE + "create-pools";
    let headers = getHeaders();
    return axios.post(url, pool, { headers: headers });
  },
  updatePoolConfig(pool: PoolDTO) {
    let url =
      SERVER.URL_SCHEDULE_MNG + SERVER.MODULE.SCHEDULE + "update-pool-config";
    let headers = getHeaders();
    return axios.post(url, pool, { headers: headers });
  },

  updateResults(result: ResultsDTO) {
    let url = SERVER.URL_SCHEDULE_MNG + SERVER.MODULE.SCHEDULE + "raceResults";
    // let url = "http://192.168.10.34:8086/" + SERVER.MODULE.SCHEDULE + "raceResults";
    let headers = getHeaders();
    return axios.post(url, result, { headers: headers });
  },
  managePoolRequest(poolKey: PoolKeyDTO) {
    let url =
      SERVER.URL_SCHEDULE_MNG + SERVER.MODULE.SCHEDULE + "manage-pool-request";
    let headers = getHeaders();
    return axios.post(url, poolKey, { headers: headers });
  },
  managePoolRequestToAuto(poolKey: PoolKeyDTO) {
    let url =
      SERVER.URL_SCHEDULE_MNG +
      SERVER.MODULE.SCHEDULE +
      "auto-manage-pool-request";
    let headers = getHeaders();
    return axios.post(url, poolKey, { headers: headers });
  },
  checkPoolRequest(poolKey: PoolKeyDTO) {
    let url =
      SERVER.URL_SCHEDULE_MNG + SERVER.MODULE.SCHEDULE + "check-pool-requested";
    let headers = getHeaders();
    return axios.post(url, poolKey, { headers: headers });
  },
  acceptPoolRequest(req: ManageRequestDTO) {
    let url =
      SERVER.URL_SCHEDULE_MNG +
      SERVER.MODULE.SCHEDULE +
      "accept-manage-pool-request";
    let headers = getHeaders();
    return axios.post(url, req, { headers: headers });
  },
  getAllProviderOdds(poolKey: string) {
    let url =
      SERVER.URL_SCHEDULE_MNG +
      SERVER.MODULE.SCHEDULE +
      "get-providers-odds/" +
      poolKey;
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  getSelProviderOdds(poolKey: string, selId: string) {
    let url =
      SERVER.URL_SCHEDULE_MNG +
      SERVER.MODULE.SCHEDULE +
      "get-provider-odds/" +
      poolKey +
      "/" +
      selId;
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },

  getScheduleRaces(tDate: string) {
    let url = SERVER.URL_SCHEDULE_MNG + "schedule/races/" + tDate;
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  getServerTimeZone() {
    let url = SERVER.URL_SCHEDULE_MNG + SERVER.MODULE.SCHEDULE + "timezone";
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  raceStatus(raceStatus: any) {
    let url = SERVER.URL_SCHEDULE_MNG + SERVER.MODULE.SCHEDULE + "race-status";
    let headers = getHeaders();
    return axios.post(url, raceStatus, { headers: headers });
  },

  getAllUsersPoolReq() {
    let url =
      SERVER.URL_SCHEDULE_MNG + SERVER.MODULE.SCHEDULE + "user-pool-requested";
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  getRaceTime(req: any) {
    let url = `${SERVER.URL_SCHEDULE_MNG}${SERVER.MODULE.SCHEDULE}race-time-config/${req.date}/${req.pzoneCode}`;
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  raceTimeConfig(req: any) {
    let url = `${SERVER.URL_SCHEDULE_MNG}${SERVER.MODULE.SCHEDULE}race-time-config`;
    let headers = getHeaders();
    return axios.post(url, req, { headers: headers });
  },
};
