import { useContext } from "react";
import { ChangeEvent, useState } from "react";

//conext
import {
  ScheduleContext,
  ScheduleContextDTO,
} from "../../../../Context/scheduleContext";

//helper
import { TableColumnsDTO } from "../../../../Helpers/columns";
import { LOCAL_STORAGE } from "../../../../Helpers/constants";
import {
  DefaultRaceTrip,
  RunnerFieldDTO,
} from "../../../../Helpers/StaticData/race-data";
import { showToast, TOAST } from "../../../../Helpers/ui-helper";
import {
  getLocalStorageObject,
  getNumberValue,
  setLocalStorage,
} from "../../../../Helpers/valueHelper";

//models
import { RaceDetailsDTO } from "../../../../Models/RaceDetailsDTO";
import { RaceTripDTO } from "../../../../Models/raceTripsDTO";
import { TrackInfoDTO } from "../../../../Models/trackInfoDTO";

//components
import PzCheckBox from "../../../../UI-Components/Inputs/checkBoxInput";
import PZInputField from "../../../../UI-Components/Inputs/inputField";
import PzTable from "../../../../UI-Components/Table/pzTable";
import RunnerFieldCheckboxes from "../../Components/runnerCheckboxes";

export interface IRaceTripDetailsStepProps {
  onStepChange: (step: number) => void;

  newRaceDetails: RaceDetailsDTO;
  setNewRaceDetails: (raceDetails: RaceDetailsDTO) => void;

  raceTrips: RaceTripDTO[];
  setRaceTrips: (raceTrips: RaceTripDTO[]) => void;

  selectedTrack: TrackInfoDTO;
  setSelectedTrack: (track: TrackInfoDTO) => void;
}

export default function RaceTripDetailsStep(props: IRaceTripDetailsStepProps) {
  const sContext = useContext(ScheduleContext);

  const [trip, setTrip] = useState<any>({ ...DefaultRaceTrip });

  const [isShowFields, setIsShowFields] = useState(
    getLocalStorageObject(LOCAL_STORAGE.IS_FIELDS_SHOW) ?? true
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let tripData = { ...trip } as any;
    tripData[e.target.name] = e.target.value;
    setTrip(tripData);
  };

  const handleCheckBoxChange = (e: any) => {
    let tripData = { ...trip } as any;
    tripData[e.name] = e.value;
    setTrip(tripData);
  };

  const onAddRaceTrip = () => {
    if (validateRaceTrip()) {
      let trips = [...props.raceTrips];
      let t = { ...trip } as RaceTripDTO;
      for (const _trips of trips) {
        if (_trips.programNo === t.programNo) {
          showToast("Program No. is Already Added!", TOAST.ERROR);
          return;
        }
      }
      trips.push(t);
      props.setRaceTrips(trips);
      resetRaceTrip();
    }
  };

  const validateRaceTrip = (): boolean => {
    // TODO: Make dynamic validation on input field
    if (!trip.programNo) {
      showToast("Program No. is required!", TOAST.ERROR);
      return false;
    }
    if (!trip.horseName) {
      showToast("Horse name is required!", TOAST.ERROR);
      return false;
    }
    return true;
  };

  const resetRaceTrip = () => {
    setTrip({
      age: 0,
      apprentice: false,
      apprenticeType: "",
      barrierPos: 0,
      breedType: "",
      carriedWeight: 0,
      claimingPrice: 0,
      coupled: false,
      earnings: 0,
      equipments: false,
      equipmentsType: "",
      horseName: "",
      horseWeight: 0,
      jockeyName: "",
      lastUpdated: 0,
      medication: false,
      medicationType: "",
      programNo: "",
      providerId: "",
      raceTripKey: {
        localDate: 0,
        pzoneCode: "",
        raceNo: 0,
        selId: "",
      },
      scratched: false,
      sex: "",
      speedIndex: 0,
      trainerName: "",
      isEditing: false,
    });
  };

  const onCheckBoxChange = (e: any) => {
    let context = { ...sContext };
    context.runnerFields[e.name].isTrue = e.value;
    setLocalStorage(
      LOCAL_STORAGE.SM_RUNNER_FIELDS,
      JSON.stringify(context.runnerFields)
    );
    updateColumn(context);
  };

  const updateColumn = (context: ScheduleContextDTO) => {
    let runnerFields: RunnerFieldDTO[] = Object.values(context.runnerFields);
    let cols: TableColumnsDTO[] = [];
    for (let field of runnerFields) {
      let tableCol: TableColumnsDTO = {
        field: field.key,
        header: field.label,
        width: " ",
      };
      if (field.isTrue) cols.push(tableCol);
    }
    context.setRunnerFiledsCols(cols);
  };

  // var invalidChars = ["-", "+", "e"];
  const handleKeyUp = (e: any) => {
    if (e.key === "e") {
      console.log(e.key);
      return;
    }
    if (e.keyCode === 13) {
      onAddRaceTrip();
    }
  };

  const onNext = () => {
    let trips = [...props.raceTrips];
    for (let trip of trips) {
      trip.raceTripKey.localDate = props.newRaceDetails.raceKey.localDate;
      trip.raceTripKey.pzoneCode = props.newRaceDetails.raceKey.pzoneCode;
      trip.raceTripKey.raceNo = props.newRaceDetails.raceKey.raceNo;
      trip.raceTripKey.selId = trip.programNo;
    }
    if (trips.length < 2) {
      showToast("Minimum Add 2 RaceTrips", TOAST.ERROR);
      return;
    }
    props.setRaceTrips(trips);
    props.onStepChange(4);
  };

  return (
    <div className="pz-card m-auto" id="runner-form">
      <div className="pz-card-header d-flex justify-content-between">
        <span>Enter runner details below.</span>
        <button
          onClick={() => setIsShowFields(!isShowFields)}
          className="toggle-btn"
        >
          <i className="fa fa-cogs"></i> &nbsp; Runner Fields
        </button>
      </div>

      <div className="pz-card-body">
        {RunnerFieldCheckboxes(
          isShowFields,
          sContext.runnerFields,
          onCheckBoxChange
        )}

        <div className="row w-100 m-0 p-0">
          <div className="col-2 p-0 b-right">
            <div className="runner-form">
              {sContext.runnerFields &&
                Object.values(sContext.runnerFields).map((runner: any) => {
                  if (runner.type === "number" && runner.isTrue) {
                    return (
                      <PZInputField
                        key={runner.key}
                        type={runner.type}
                        placeholder={runner.label}
                        name={runner.key}
                        onChange={handleChange}
                        onKeyUp={handleKeyUp}
                        value={getNumberValue(trip[runner.key])}
                      />
                    );
                  }
                  if (
                    runner.type === "text" &&
                    runner.isTrue &&
                    runner.key !== "providerId"
                  ) {
                    return (
                      <PZInputField
                        key={runner.key}
                        type={runner.type}
                        placeholder={runner.label}
                        name={runner.key}
                        onChange={handleChange}
                        value={trip[runner.key]}
                      />
                    );
                  }
                  if (runner.type === "checkbox" && runner.isTrue) {
                    return (
                      <PzCheckBox
                        key={runner.key}
                        name={runner.key}
                        value={trip[runner.key]}
                        label={runner.label}
                        onChange={handleCheckBoxChange}
                      />
                    );
                  }
                  return null;
                })}

              <div className="p-3 b-top d-flex">
                <a
                  href="#runner-form"
                  onClick={onAddRaceTrip}
                  className="pz-btn w-100 primary"
                >
                  Add
                </a>

                <button
                  onClick={resetRaceTrip}
                  className="pz-btn w-100 text-btn"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div className="col-10 p-0 d-flex flex-column">
            <div className="p-3 w-100" style={{ overflowX: "auto" }}>
              <PzTable
                columns={sContext.runnerFieldCols}
                data={props.raceTrips}
                // isEditAction
                // onEditClick={onEditClick}
              />
            </div>
            <div className="mt-auto text-right b-top w-100 p-3">
              <button
                onClick={() => props.onStepChange(2)}
                className="pz-btn text-btn mr-2"
              >
                <i className="fa fa-chevron-left"></i> &nbsp;&nbsp;&nbsp; Back
              </button>
              <button onClick={onNext} className="pz-btn primary">
                Next &nbsp;&nbsp;&nbsp;<i className="fa fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
