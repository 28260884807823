import { PoolConfigDTO, PoolDTO } from "../../Models/PoolsDTO";
import { PrimeItem } from "./race-data";

export const ProvidersList: PrimeItem[] = [
  { label: "DEFAULT", value: "DEFAULT" },
];

export const AllProvidersList: PrimeItem[] = [
  { label: "AU_VIC", value: "AU_VIC" },
  { label: "AU_NSW", value: "AU_NSW" },
  { label: "AMTOTE", value: "AMTOTE" },
  { label: "DEFAULT", value: "DEFAULT" },
];

export const options = [
  { label: "FIXED", value: "FIXED" },
  { label: "TOTE", value: "TOTE" },
];

export const ManagedByList: PrimeItem[] = [
  // { label: "None", value: "NONE" },
  { label: "Auto", value: "AUTO" },
  { label: "Manual", value: "MANUAL" },
];

export const PoolsList: PrimeItem[] = [
  { label: "WIN", value: "WIN" },
  { label: "PLACE", value: "PLACE" },
  { label: "QUINELLA", value: "QUINELLA" },
  { label: "SWINGER", value: "SWINGER" },
  { label: "EXACTA", value: "EXACTA" },
  { label: "TRIFECTA", value: "TRIFECTA" },
  { label: "SUPERFECTA", value: "SUPERFECTA" },
  { label: "DOUBLE", value: "DOUBLE" },
];

export const FOPoolsList: PrimeItem[] = [
  { label: "FO_WIN", value: "FO_WIN" },
  { label: "FO_PLACE", value: "FO_PLACE" },
  { label: "WIN", value: "WIN" },
  { label: "PLACE", value: "PLACE" },
  { label: "QUINELLA", value: "QUINELLA" },
  { label: "SWINGER", value: "SWINGER" },
  { label: "EXACTA", value: "EXACTA" },
  { label: "TRIFECTA", value: "TRIFECTA" },
  { label: "SUPERFECTA", value: "SUPERFECTA" },

  { label: "DOUBLE", value: "DOUBLE" },
];
export const FOPoolsListTrack: PrimeItem[] = [
  { label: "FO_WIN", value: "FO_WIN" },
  { label: "FO_PLACE", value: "FO_PLACE" },
  { label: "WIN", value: "WIN" },
  { label: "PLACE", value: "PLACE" },
  { label: "QUINELLA", value: "QUINELLA" },
  { label: "SWINGER", value: "SWINGER" },
  { label: "EXACTA", value: "EXACTA" },
  { label: "TRIFECTA", value: "TRIFECTA" },
  { label: "SUPERFECTA", value: "SUPERFECTA" },
];

export const CurrencyList: PrimeItem[] = [
  { label: "AUD", value: "AUD" },
  { label: "USD", value: "USD" },
  { label: "GPB", value: "GPB" },
  { label: "EUR", value: "EUR" },
  { label: "HKD", value: "HKD" },
];

export const _poolList: PrimeItem[] = [
  { label: "DEFAULT", value: "DEFAULT" },
  { label: "FO_WIN", value: "FO_WIN" },
  { label: "FO_PLACE", value: "FO_PLACE" },
];
export const poolList: PrimeItem[] = [
  { label: "FO_WIN", value: "FO_WIN" },
  { label: "FO_PLACE", value: "FO_PLACE" },
];
export const _eventTypeList = [
  { label: "DEFAULT", value: "DEFAULT" },
  { label: "DO", value: "DO" },
  { label: "HS", value: "HS" },
  { label: "TH", value: "TH" },
];
export const eventTypeList = [
  { label: "DO", value: "DO" },
  { label: "HS", value: "HS" },
  { label: "TH", value: "TH" },
];

export const PoolStatuses: PrimeItem[] = [
  { label: "NON_DISPLAY", value: "NON_DISPLAY" },
  { label: "Open", value: "OPEN" },
  { label: "Closed", value: "CLOSED" },
  { label: "Resulted", value: "RESULTED" },
];

export const RaceStatus: PrimeItem[] = [
  { label: "ND", value: "NON_DISPLAY" },
  { label: "Open", value: "OPEN" },
  { label: "Closed", value: "CLOSED" },
  { label: "Resulted", value: "RESULTED" },
];
export const FoPoolRaceStatus: PrimeItem[] = [
  { label: "ND", value: "NON_DISPLAY" },
  { label: "Open", value: "OPEN" },
  { label: "Closed", value: "CLOSED" },
];
export const DeductionUsingOptions: PrimeItem[] = [
  { label: "BY_AUTO", value: "BY_AUTO" },
  { label: "BY_PER", value: "BY_PER" },
];
export const ProviderPoolOptions: PrimeItem[] = [
  { label: "WIN", value: "WIN" },
  { label: "PLACE", value: "PLACE" },
];
export const OddsProviderAU: PrimeItem[] = [
  { label: "AU_NSW", value: "AU_NSW" },
  { label: "AU_VIC", value: "AU_VIC" },
  { label: "BetFair", value: "BETFAIR" },
];
export const OddsProviderUS: PrimeItem[] = [
  { label: "AMTOTE", value: "AMTOTE" },
];

export const InitPoolKey = {
  localDate: 0,
  poolType: "",
  provider: "DEFAULT",
  pzoneCode: "",
  raceNo: 0,
};
export const InitPoolConfig: PoolConfigDTO = {
  // scratchedDedPer: 0,
  // scratchedProfileId: null,
  // deductionUsing: null,
  // oddsManagedBy: null,
  // oddsCrunchPerByProvider: null,
  // oddsProfileId: null,
  // poolKey: InitPoolKey,
  // userId: null,
  // accountId: null,
  openTime: "",
  lastUpdated: null,
  minBookPer: 0,
  isSystemOpen: false,
  accountId: "",
  scratchedDedPer: 0,
  deductionUsing: null,
  scratchedProfileId: "",
  fixOddsValue: 0,
  fixOddsRule: 0,
  userId: "",
  oddsManagedBy: null,
  timeZone: "",
  mbaType: "",
  mbaVal: 0,
  layoffPer: 0,
};

export const InitPool: PoolDTO = {
  poolConfig: InitPoolConfig,
  poolDetails: {
    layOff: false,
    cancelled: false,
    currency: "",
    incBetAmt: 0,
    lastUpdated: 0,
    minBetAmt: 0,
    otherLegs: "",
    payOffList: [],
    payoff: {},
    poolKey: InitPoolKey,
    linkedWith: null,
    poolKeyString: "",
    providerId: "",
    skipFields: null,
    resulted: false,
    poolStatus: "NON_DISPLAY",
  },
};
