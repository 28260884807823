import { Chips } from "primereact/chips";
import React, { ChangeEvent, useEffect, useState } from "react";
import { format } from "date-fns";
import { getDynamicImage } from "../../../Helpers/imagesImport";
import { showAPIToast, showToast, TOAST } from "../../../Helpers/ui-helper";
import { RaceDetailsDTO, ResultsDTO } from "../../../Models/RaceDetailsDTO";
import { Dialog } from "primereact/dialog";
//@ts-ignore
import ReactCountryFlag from "react-country-flag";
import DateTimePicker from "../../../UI-Components/Inputs/dateTime";
import PZInputField from "../../../UI-Components/Inputs/inputField";
import "./selectedRaceComponent.scss";
import { FORMAT } from "../../../Helpers/constants";
import { Checkbox } from "primereact/checkbox";
import {
  getStringToPosttime,
  getZonedPostTime,
  getFormetTime,
} from "../../../Helpers/dateTimeHelpers";
import { SelectButton } from "primereact/selectbutton";
import { confirmPopup } from "primereact/confirmpopup";
import { useContext } from "react";
import { ScheduleContext } from "../../../Context/scheduleContext";
import { RaceStatus } from "../../../Helpers/StaticData/pool-data-static";
import { validateMinValue } from "../../../Helpers/Validators/input-validations";
import { ScheduleApiService } from "../../../services/scheduleApiService";
import { MultiSelect } from "primereact/multiselect";
import { useRef } from "react";
import PzCheckBox from "../../../UI-Components/Inputs/checkBoxInput";
import PoolsListComponent from "../../SchedulesManagement/PoolsListComponent/poolsListComponent";

interface SelectedRaceProps {
  race: RaceDetailsDTO;
  canModify?: boolean;
  setIsFormVisible?: any;
  setIsPoolsOpen?: any;
}

function SelectedRaceComponent(props: SelectedRaceProps) {
  const [isEdit, setIsEdit] = useState(false);
  const [race, setRace] = useState({} as RaceDetailsDTO);
  const [postTime, setPostTime] = useState(new Date());
  const [resultes, setResultes] = useState<string[]>([]);
  const { startLoader, stopLoader } = useContext(ScheduleContext);
  const [skipFieldsList, setSkipFieldsList] = useState([]);
  const [skipFields, setSkipFields] = useState([]);
  const [displayPostTime, setDisplayPostTime] = useState("" as any);
  const [displayJumpTime, setDisplayJumpTime] = useState("" as any);
  const [isPoolVisible, setIsPoolVisible] = useState(false);
  const [poolList, setPoolList] = useState([] as any);
  const [displayPoolList, setDisplayPoolList] = useState([] as any);
  const [selectedPools, setSelectedPools] = useState<string[]>([]);
  const [raceStatus, setRaceStatus] = useState([...RaceStatus]);
  const [isResulted, setIsResulted] = useState(false);
  const resultsRef = useRef<any>(null);

  useEffect(() => {
    if (props.race && props.race.raceKey && props.race.timezoneId) {
      let post = getStringToPosttime(
        props.race.postTime,
        props.race.timezoneId
      );
      let displayPostTime = getFormetTime(
        props.race?.postTime,
        FORMAT.ddMMyyyyHHmmss_DASH
      );
      setDisplayPostTime(displayPostTime);

      let displayJumpTime = getFormetTime(
        props.race?.jumpTime,
        FORMAT.ddMMyyyyHHmmss_DASH
      );
      setDisplayJumpTime(displayJumpTime);

      if (props.race.skipFields !== null) {
        onSetSkipFields();
      } else {
        setSkipFieldsList([]);
      }
      setPostTime(post);
      setRace(props.race);

      if (props.race.raceStatus === "RESULTED") {
        onGetRaceResults();
        setIsResulted(true); //new
      } else {
        setResultes([]);
        resultsRef.current = null;
        setIsResulted(false);
      }
    }
    return () => {
      setSkipFields([]);
      setSkipFieldsList([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.race]);

  useEffect(() => {
    if (race.raceStatus === "NON_DISPLAY") {
      let _raceStatus = [...raceStatus];
      _raceStatus[2].disabled = true;
      _raceStatus[3].disabled = true;
      _raceStatus[0].disabled = false;
      _raceStatus[1].disabled = false;

      setRaceStatus(_raceStatus);
    }
    if (race.raceStatus === "OPEN" || race.raceStatus === "CLOSED") {
      let _raceStatus = [...raceStatus];
      for (const status of _raceStatus) {
        status.disabled = false;
      }
      setRaceStatus(_raceStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [race.raceStatus]);

  const onGetRaceResults = () => {
    ScheduleApiService.getResults(props.race.raceKeyString)
      .then((res) => {
        if (res.data) {
          let _race = { ...race };
          _race.finishingOrder = res.data.finishingOrder;
          resultsRef.current = res.data.finishingOrder;
          setResultes(res.data.finishingOrder);
        } else {
          setResultes([]);
          resultsRef.current = null;
        }
      })
      .catch((err) => {
        console.error(err);
        showAPIToast(err, "");
      });
  };

  const onSetSkipFields = () => {
    if (props.race.skipFields) {
      var splitVals: any = props.race.skipFields
        .replace("[", "")
        .replace("]", "")
        .split(",");
      setSkipFields(splitVals);
      setSkipFieldsList(splitVals);
    }
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    let raceData = { ...race } as any;
    if (e.target.name === "distance")
      if (!validateMinValue(Number(e.target.value), 0)) return;
    raceData[e.target.name] = e.target.value;
    setRace(raceData);
  };

  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      onRaceUpdateClick();
    } else if (e.key === "Escape") onCancelEdit();
  };

  const onChangePostTime = (e: any) => setPostTime(e.target.value);

  const getRaceTab = (
    label: string,
    tabData: any,
    field: string,
    type: string,
    autoFocus?: boolean
  ) => {
    return (
      <div className="tab-item">
        {label} :{" "}
        {!isEdit && tabData && tabData !== 98765.56789 ? tabData : " - "}
        {props.canModify && isEdit && (
          <PZInputField
            type={type}
            name={field}
            onKeyUp={handleKeyUp}
            value={tabData === 98765.56789 ? "" : tabData}
            autoFocus={autoFocus}
            inputClass="p-2"
            onChange={onChangeInput}
          />
        )}
      </div>
    );
  };

  const onCancelEdit = () => {
    setRace(props.race);
    setIsEdit(false);
    if (props.race.raceStatus === "RESULTED") {
      setResultes(resultsRef.current ?? []);
    }
    setIsResulted(false);
  };

  const onSelectSkipFields = (e: any) => {
    setSkipFields(e.value);
    let raceData = { ...race } as any;
    raceData.skipFields = "[" + e.value.join(",") + "]";
    setRace(raceData);
  };

  const onRaceCancel = (e: any) => {
    let raceData = { ...race };
    raceData.cancelled = e.value;
    setRace(raceData);
  };
  const confirmUpdate = (event: any) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to proceed to update this race?",
      icon: "pi pi-exclamation-triangle",
      accept: () => onRaceUpdateClick(),
      reject: () => {},
    });
  };

  const onRaceUpdateClick = () => {
    if (race.raceStatus === "RESULTED" && !resultes.length) {
      showToast("Please enter race results!", TOAST.ERROR);
      return;
    }

    let _race = formatRaceObj();

    if (_race.raceStatus === "RESULTED") {
      updateResults(_race.raceKey, true);
    } else {
      onUpdateRaceDetails(_race);
    }
  };

  const onUpdateRaceDetails = (_race: any) => {
    ScheduleApiService.updateRaceDetails(_race)
      .then((res) => {
        if (res.data) {
          showToast(
            `Race no. ${race.raceKey.raceNo} of track ${race.trackName} is updated successfully!`,
            TOAST.SUCCESS,
            "raceDetails"
          );
          setIsEdit(false);
          if (race.raceStatus === "RESULTED") {
            setIsResulted(true);
          }
        }
      })
      .catch((err) => {
        console.error(err);
        showAPIToast(err, "");
      });
  };

  const formatRaceObj = () => {
    let tempRace = { ...race } as any;
    let postTimePattern = format(postTime, FORMAT.yyyyMMddHHmmss);
    tempRace.skipFields = "[" + skipFields.join(",") + "]";
    tempRace.postTime = getZonedPostTime(
      race,
      FORMAT.yyyyMMddHHmmss,
      postTimePattern
    );
    delete tempRace.eventType;
    delete tempRace.raceKeyString;
    delete tempRace.formattedPosttime;
    delete tempRace.formattedDate;
    delete tempRace.country;
    delete tempRace.localPostTime;
    return tempRace;
  };

  const updateResults = (raceKey: any, isUpdateRace: boolean) => {
    let result = {} as ResultsDTO;
    result.finishingOrder = resultes;
    result.raceKey = raceKey;

    ScheduleApiService.updateResults(result)
      .then((res) => {
        if (res.data) {
          if (isUpdateRace) onUpdateRaceDetails(formatRaceObj());
          else handleRaceStatus(raceKey, "RESULTED");

          showToast(
            "Race results added successfully!",
            TOAST.SUCCESS,
            "results"
          );
        }
      })
      .catch((err) => {
        console.error(err);
        showAPIToast(err, "while updating results", "result_update");
      });
  };

  const onRaceStatusChangeLive = (e: any) => {
    let status = e.target.value;
    if (
      race.raceStatus === "OPEN" &&
      status === null &&
      selectedPools?.length !== 0
    ) {
      setIsPoolVisible(true);
    } else {
      setIsPoolVisible(false);
    }
    if (!e.value || race.raceStatus === e.value) return;
    startLoader();
    if (status === "OPEN" && selectedPools?.length !== 0) {
      setIsPoolVisible(true);
      stopLoader();
      return;
    } else if (status === "RESULTED") {
      stopLoader();
      setIsResulted(true);
      return;
    }

    handleRaceStatus(race.raceKey, status);
  };

  const handleRaceStatus = (raceKey: any, status: string) => {
    const raceStatusReq = {} as any;
    raceStatusReq["pools"] = status === "OPEN" ? selectedPools : [];
    raceStatusReq["raceKey"] = raceKey;
    raceStatusReq["raceStatus"] = status;

    ScheduleApiService.raceStatus(raceStatusReq)
      .then((res) => {
        showToast("Race Status Change Successfully", TOAST.SUCCESS);
        setIsPoolVisible(false);
        stopLoader();
      })
      .catch((error) => {
        showToast(error.response?.data.message, TOAST.ERROR);
        stopLoader();
      });
  };

  const handlePoolListChange = (e: { value: any; checked: boolean }) => {
    let arr = [...selectedPools];
    if (e.checked && e.value) {
      arr.push(e.value);
    } else {
      arr.splice(selectedPools.indexOf(e.value), 1);
    }
    setSelectedPools(arr);
  };

  const handleResultInputChange = (value: any) => {
    let resultData = value.filter(function (val: any, index: any, array: any) {
      let data = array.indexOf(val) === index;
      if (!data) {
        showToast(`${val} is already exsist`, TOAST.ERROR);
      }
      return data;
    });
    if (resultData) {
      setResultes(resultData);
    }
  };

  const ResultInputTemplate = () => {
    if (isResulted && !resultsRef.current)
      return (
        <>
          <Chips
            className="input-container m-0"
            value={resultes}
            onChange={(e) => handleResultInputChange(e.value)}
            separator=","
            placeholder="Enter result and use , to seperate winners"
            style={{ maxWidth: 400 }}
          ></Chips>

          <button
            onClick={() => updateResults(race.raceKey, false)}
            disabled={resultes.length === 0}
            style={{
              padding: "5px 20px",
              height: 32,
              opacity: resultes.length === 0 ? 0.6 : 1,
            }}
            className="pz-btn primary  mx-2 d-flex align-items-center"
          >
            <i className="fa fa-pencil mr-2 "></i>
            Update
          </button>
        </>
      );
    return null;
  };

  useEffect(() => {
    if (poolList) {
      let arr = [];
      let poolData = [];
      for (let key of poolList) {
        let pData = key.poolDetails.poolKey.poolType;
        let data = key.poolDetails.poolKey;
        arr.push(data);
        poolData.push(pData);
      }
      setSelectedPools(poolData);
      setDisplayPoolList(arr);
    }
  }, [poolList]);

  const renderFooter = () => {
    return (
      <div className="action-btns">
        <button
          className="pz-btn"
          style={{ background: "#ba5aeb", borderRadius: 3 }}
          onClick={() => {
            setIsPoolVisible(false);
          }}
        >
          Close
        </button>
        <button
          className="pz-btn "
          style={{ background: "#ba5aeb", borderRadius: 3 }}
          onClick={() => handleRaceStatus(race.raceKey, "OPEN")}
        >
          Confirm
        </button>
      </div>
    );
  };

  if (props.race)
    return (
      <div className="selected-event-container">
        <div className="selected-event-header b-bottom">
          <div className="event-type-img b-right">
            <img
              src={`${getDynamicImage(props.race.eventType)}`}
              alt={props.race.eventType}
            />
          </div>
          <div className="event-details p-2">
            <div className="detail-container col-between">
              {props.race.raceKey && (
                <div>
                  <span className="trackName">
                    {" "}
                    <ReactCountryFlag
                      style={{
                        fontSize: "1.5em",
                      }}
                      svg
                      aria-label={props.race.country}
                      className="emojiFlag"
                      countryCode={props.race.country}
                      title={props.race.country}
                    />
                    &nbsp;
                    {props.race.country} -
                  </span>
                  <span className="trackName">
                    {props.race.trackName} - {props.race.raceKey.raceNo}
                  </span>
                </div>
              )}
              <div style={{ display: "flex" }}>
                <span style={{ marginTop: "15px" }}>
                  {props.race.formattedPosttime}
                </span>
                <div>
                  <PoolsListComponent
                    setIsFormVisible={props.setIsFormVisible}
                    setIsPoolsOpen={props.setIsPoolsOpen}
                    setPoolList={setPoolList}
                  />
                </div>
              </div>
            </div>
            {props.race.cancelled ? (
              <>
                {props.race.cancelled && (
                  <div className="canceled_badge">Cancelled</div>
                )}
              </>
            ) : (
              <>
                {race.raceStatus && (
                  <span className={`sts ${race.raceStatus?.toLowerCase()}`}>
                    {race.raceStatus[0] ?? ""}
                  </span>
                )}
              </>
            )}
          </div>
        </div>
        <div className="event-detail-tab">
          {getRaceTab("Distance", race.distance, "distance", "number", true)}
          {/* {getRaceTab("Course", race.courseType, "courseType", "text")} */}
          {getRaceTab("Race Type", race.raceType, "raceType", "text")}
          {getRaceTab("Surface", race.surfaceType, "surfaceType", "text")}
          {getRaceTab(
            "Track Condition",
            race.trackCondition,
            "trackCondition",
            "text"
          )}
          {!isEdit && (
            <>
              {getRaceTab("Jump Time", displayJumpTime, "", "")}
              {getRaceTab("Post Time", displayPostTime, "", "")}
              {getRaceTab("Time Zone ", race.timezoneId, "", "")}
            </>
          )}
          {props.canModify && isEdit && (
            <div className="tab-item">
              Local Date : -
              <DateTimePicker
                value={postTime}
                onChange={onChangePostTime}
                minDate={new Date()}
                inputClass="p-2"
              />
            </div>
          )}
          {props.canModify && isEdit && (
            <div className="tab-item">
              Post Time : -
              <DateTimePicker
                value={postTime}
                onChange={onChangePostTime}
                inputClass="p-2"
                timeOnly
              />
            </div>
          )}
          {props.canModify && isEdit && props.race.raceStatus === "RESULTED" && (
            <>
              <div className="tab-item">
                Race Status : -
                <div style={{ marginTop: 10, fontSize: 16 }}> RESULTED</div>
              </div>
              <div className="tab-item">
                Enter Race Resultes : -
                <Chips
                  className="input-container"
                  value={resultes}
                  onChange={(e) => handleResultInputChange(e.value)}
                  separator=","
                  placeholder="Enter result and use,to seperate winners"
                ></Chips>
              </div>
            </>
          )}

          {props.canModify && isEdit && skipFieldsList.length > 0 && (
            <div className="tab-item">
              <div>Skip Fields : -</div>
              <MultiSelect
                value={skipFields}
                options={skipFieldsList}
                onChange={onSelectSkipFields}
                className="skipFields"
                maxSelectedLabels={2}
                style={{ margin: "1rem 0px" }}
              />
            </div>
          )}
          {props.canModify && isEdit && (
            <div className="tab-item text-center">
              Cancel: -
              <PzCheckBox
                value={race.cancelled}
                name="cancelled"
                className="justify-content-center"
                label={race.cancelled ? "Cancelled" : "Not Cancelled"}
                onChange={(e: any) => onRaceCancel(e)}
              />
            </div>
          )}
        </div>

        <div className="b-top">
          <div className="d-flex justify-content-between align-items-center">
            {props.canModify && !isEdit && (
              <div className="tab-item p-3">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {props.race.raceStatus === "RESULTED" ? (
                    <span className="result-label">
                      Results: {resultes.join("-")}
                    </span>
                  ) : props.race.raceStatus === "NON_DISPLAY" &&
                    props.race.jumpTime ? (
                    <span className="closed_race_status">
                      This race is already closed
                    </span>
                  ) : (
                    <>
                      {!props.race.cancelled && (
                        <SelectButton
                          optionLabel="label"
                          optionValue="value"
                          value={race.raceStatus}
                          options={RaceStatus}
                          onChange={(e) => onRaceStatusChangeLive(e)}
                        ></SelectButton>
                      )}
                    </>
                  )}

                  <div className="ml-4 d-flex flex-initial align-items-end">
                    {ResultInputTemplate()}
                  </div>
                </div>
              </div>
            )}
            {props.canModify && !isEdit && (
              <div className="p-3 text-right">
                {props.race?.raceStatus === "NON_DISPLAY" &&
                props.race?.jumpTime ? (
                  <></>
                ) : (
                  <button
                    onClick={() => setIsEdit(!isEdit)}
                    className="pz-btn primary rounded"
                  >
                    <i className="fa fa-pencil"></i>
                    &nbsp;&nbsp;&nbsp; Edit Race
                  </button>
                )}
              </div>
            )}
          </div>
          <Dialog
            header="Select Pools"
            className="race-dialog-box"
            visible={isPoolVisible}
            style={{ minWidth: "20vw" }}
            breakpoints={{ "960px": "75vw", "640px": "100vw" }}
            modal
            onHide={() => {
              setIsPoolVisible(false);
            }}
            footer={renderFooter}
          >
            <div className="dialog-body">
              {displayPoolList.length > 0 ? (
                <>
                  {displayPoolList.map((item: any) => {
                    return (
                      <div key={item.poolType} className="p-field-checkbox">
                        <Checkbox
                          inputId={item.poolType}
                          value={item.poolType}
                          onChange={handlePoolListChange}
                          className="mr-2"
                          checked={selectedPools.includes(item.poolType)}
                        />
                        <label htmlFor={item.poolType}>{item.poolType}</label>
                      </div>
                    );
                  })}
                </>
              ) : (
                <span>No Pools Available!</span>
              )}
            </div>
          </Dialog>
          {isEdit && (
            <div className="p-3 text-right ">
              <button
                className="pz-btn text-btn text-d "
                onClick={onCancelEdit}
              >
                <i className="fa fa-times"></i>&nbsp;&nbsp;&nbsp; Cancel
              </button>
              <button
                onClick={confirmUpdate}
                className="pz-btn primary rounded mx-2"
              >
                <i className="fa fa-pencil"></i>&nbsp;&nbsp;&nbsp; Update
              </button>
            </div>
          )}
        </div>
      </div>
    );
  return (
    <div className="selected-event-container p-4 h2 text-muted">
      Please select a Race!
    </div>
  );
}
export default SelectedRaceComponent;
