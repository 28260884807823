import { useContext, useEffect, useState } from "react";
import { RulesContext } from "../../../../../Context/rulesContext";
//@ts-ignore
import ReactCountryFlag from "react-country-flag";
import { sortValuesAsc } from "../../../../../Helpers/list-helpers";
import {
  showAPIToast,
  showToast,
  TOAST,
} from "../../../../../Helpers/ui-helper";
import { filterValues, getTrackName } from "../../../../../Helpers/valueHelper";
import { TrackInfoDTO } from "../../../../../Models/trackInfoDTO";
import { ScheduleApiService } from "../../../../../services/scheduleApiService";
import { ScreenType } from "../../ScratchProfile/scratchProfile";
import "./_screenStyles.scss";
import { N_PoolRuleDTO, PoolRuleDTO } from "../../../../../Models/poolRuleDTO";
import PzMultiSelect from "../../../../../UI-Components/Inputs/pzMultiSelect";
import { PoolsList } from "../../../../../Helpers/StaticData/pool-data-static";
import { RulesService } from "../../../../../services/rulesApiService";
import PzDropDown from "../../../../../UI-Components/Inputs/pzDropDown";
import { ListBox } from "primereact/listbox";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";

export interface IAddPoolRuleScreenProps {
  changeScreen: (screen: ScreenType) => void;
  getPoolRules: any;
  connectorList: any;
}

export default function AddPoolRuleScreen(props: IAddPoolRuleScreenProps) {

  const ruleContext = useContext(RulesContext);
  const [filteredCtry, setFilteredCtry] = useState<{ province: string }[]>([]);
  const [filteredTracks, setFilteredTracks] = useState<TrackInfoDTO[]>([]);
  const [rule, setRule] = useState({} as PoolRuleDTO);
  const [selectedTracks, setSelectedTracks] = useState([] as any);
  const [providerList, setProviderList] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState();
  const [selectedCurrecyList, setSelectedCurrecyList] = useState([]);
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    const getProvinces = () => {
      ScheduleApiService.getProvinces()
        .then((res) => {
          let context = { ...ruleContext };
          console.info("API____PROVINCE FETCHED____");
          if (res.data) {
            let sortedCtry = sortValuesAsc(res.data, "province");
            context.provinceList = sortedCtry;
            // onProvinceSelect(sortedCtry[0]);
          } else {
            context.provinceList = [];
          }
          context.updateRuleContext(context);
        })
        .catch((err) => {
          console.error(err);
          showAPIToast(err, "while getting countries", "ctr");
        });
    };

    if (!ruleContext.provinceList) getProvinces();

    if (!filteredCtry.length && ruleContext.provinceList) {
      setFilteredCtry(ruleContext.provinceList);
      onProvinceSelect(ruleContext.provinceList[0].province);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleContext.provinceList, filteredCtry]);

  const onProvinceSelect = (province: string) => {
    let context = { ...ruleContext };
    context.selectedProvince = province;
    onGetProviderList(province);
    ScheduleApiService.getProvinceTrack(province)
      .then((res) => {
        if (res.data) {
          let tracks = res.data as TrackInfoDTO[];
          let tracksList = [] as TrackInfoDTO[];

          let sortedTracks = sortValuesAsc(tracks, "trackName");
          for (let track of sortedTracks) {
            let splited = track.pzoneCode.split("-");
            track.eventType = splited[2];
            track.province = splited[0];
            tracksList.push(track);
          }
          context.tracks = tracksList;
          context.selectedTrack = tracksList[0];
          setFilteredTracks(tracksList);
        } else {
          context.tracks = [];
          setFilteredTracks([]);
        }
        context.updateRuleContext(context);
      })
      .catch((err) => {
        console.error(err);
        showAPIToast(err, "while fetching tracks");
        context.tracks = [];
        context.updateRuleContext(context);
      });

    setChecked(false);
    setSelectedCurrecyList([]);
    setSelectedTracks([]);
  };

  const onGetProviderList = (province: string) => {
    let poolCategory = "TOTE";
    ScheduleApiService.getProviderList(province, poolCategory)
      .then((res) => {
        if (res.data) {
          setProviderList(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
        showAPIToast(err, "while fetching providerlist");
      });
  };

  const onSearchProvince = (e: any) => {
    let result = filterValues(
      e.target.value,
      ruleContext.provinceList,
      filteredCtry,
      "province"
    );
    setFilteredCtry(result);
  };

  const onRuleChange = (e: any) => {
    let _rule = { ...rule } as any;
    _rule[e.target.name] = e.target.value;
    setRule(_rule);
  };

  const onAddPoolRule = () => {
    if (!selectedTracks.length) {
      showToast("select track", TOAST.ERROR);
      return;
    }
    let _rule = { ...rule };
    let reqBody = {} as N_PoolRuleDTO;
    reqBody.connectorAccountId = _rule.accountId;
    reqBody.poolTypes = _rule.pools;
    reqBody.provider = _rule.provider;
    reqBody.province = selectedTracks[0].province;
    let list = [];
    for (const track of selectedTracks) {
      list.push(track.pzoneCode);
    }
    reqBody.trackCodes = list;

    let context = { ...ruleContext };
    context.startLoader();
    RulesService.addPoolRules(reqBody)
      .then((res) => {
        if (res.data) {
          for (const data of res.data) {
            if (data.status === "SUCCESS") {
              showToast(
                "Tote rule added successfully! In  " + getTrackName(data.track),
                TOAST.SUCCESS,
                data.track
              );
            } else if (data.status === "FAILED") {
              showToast(
                "Tote rule not added In  " + getTrackName(data.track),
                TOAST.ERROR,
                data.track
              );
            }
          }
          props.getPoolRules();
          context.isLoading = false;
          props.changeScreen(ScreenType.VIEW);
        }
        context.updateRuleContext(context);
      })
      .catch((err) => {
        context.stopLoader();
        console.error(err);
        showAPIToast(err, "while adding tote rule", "add_rule");
      });
  };

  const trackDetailTemplate = (track: any) => {
    return (
      <button key={track.pzoneCode} className="trackbtn">
        <span>{track.trackName}</span>
        <span>{track.eventType}</span>
      </button>
    );
  };
  const onSelecteTrack = (e: any) => {
    if (filteredTracks.length > e.value.length) {
      setChecked(false);
    }else if (filteredTracks.length === e.value.length) {
      setChecked(true);
    }
    setSelectedTracks(e.value);
  };

  const onEditChange = (e: any) => {
    let _rule = { ...rule } as any;
    _rule["provider"] = e.value.provider;
    setRule(_rule);

    let tList: any = [];
    for (const connector of props.connectorList) {
      if (connector.currency === e.value.currency) {
        tList.push(connector);
      }
    }
    setSelectedCurrecyList(tList);
    setSelectedProvider(e.value);
  };
  const onSelectAllTracks = () => {
    let _check = checked;
    if (!_check) {
      let tList: any = Object.values(filteredTracks);
      setSelectedTracks(tList);
    } else {
      setSelectedTracks([]);
    }
    setChecked(!checked);
  };
  return (
    <div style={{minWidth:'1000px'}}>
      <header className=" page-head b-bottom">
        <span className="b-right">
          <button
            onClick={() => props.changeScreen(ScreenType.VIEW)}
            className="pz-btn icon-btn hover"
          >
            <i className="fa fa-arrow-left"></i> Back
          </button>
          &nbsp;&nbsp;&nbsp;
        </span>
        &nbsp;&nbsp;&nbsp;
        <span>Add New Tote Pool Rule</span>
      </header>
      <div className="add-form-content inline-height">
        <div className="ctry-container">
          <div className="search-box">
            <input
              onChange={(e) => onSearchProvince(e)}
              type="search"
              className="search-ctry"
              maxLength={2}
            />
            <span className="search-icon">
              <i className="fa fa-search"></i>
            </span>
          </div>

          {filteredCtry.map((ctry) => {
            return (
              <button
                key={ctry.province}
                onClick={() => onProvinceSelect(ctry.province)}
                className={
                  ruleContext.selectedProvince === ctry.province
                    ? "ctry-btn active"
                    : "ctry-btn"
                }
              >
                {ctry.province}
              </button>
            );
          })}
        </div>
        <div className="track-filter">
          <div className="filter-header">
            <div>Select Tracks</div>
            <Checkbox
              inputId="binary"
              checked={checked}
              onChange={onSelectAllTracks}
            />
          </div>
          <div className="">
            <ListBox
              value={selectedTracks}
              options={Object.values(filteredTracks)}
              onChange={onSelecteTrack}
              multiple
              filter
              optionLabel="trackName"
              itemTemplate={trackDetailTemplate}
              listStyle={{ maxHeight: "750px" }}
              className="listTracks"
            />
          </div>
        </div>
        <div className="add-pool-container pz-card">
          <div className="pz-card-header d-flex justify-content-between">
            <h2 className="font-weight-bold">Enter Rule Details</h2>
            {ruleContext.selectedTrack && (
              <ReactCountryFlag
                style={{
                  fontSize: "2.5em",
                  height: "1em",
                  width: "1.25em",
                  borderRadius: "0.5rem",
                }}
                svg
                aria-label={ruleContext.selectedTrack?.province}
                className="emojiFlag"
                countryCode={ruleContext.selectedTrack?.province}
                title={ruleContext.selectedTrack?.province}
              />
            )}
          </div>
          <div>
            <div className="d-flex mt-4">
              <div className="p-3" style={{ width: "25rem" }}>
                <PzMultiSelect
                  options={PoolsList}
                  onChange={onRuleChange}
                  value={rule.pools}
                  label="Select Pools"
                  name="pools"
                />
              </div>
              <div
                className="input-container"
                style={{
                  width: "auto",
                  display: "inline",
                  alignSelf: "center",
                }}
              >
                <div className="input" style={{ width: "25rem" }}>
                  <label className="input-label">Select Data Provider</label>
                  <Dropdown
                    value={selectedProvider}
                    options={providerList}
                    onChange={onEditChange}
                    className="prime-drop"
                    optionLabel="provider"
                  />
                </div>
              </div>
              <div className="p-3" style={{ width: "25rem" }}>
                <PzDropDown
                  value={rule.accountId}
                  label="Select Bet Connector"
                  options={selectedCurrecyList}
                  onChange={onRuleChange}
                  name="accountId"
                />
              </div>
            </div>

            <div className="m-4 text-right">
              <button
                className="pz-btn text-btn"
                onClick={() => props.changeScreen(ScreenType.VIEW)}
              >
                <i className="fa fa-times"></i> &nbsp;&nbsp; Cancel
              </button>
              <button className="pz-btn primary" onClick={onAddPoolRule}>
                <i className="fa fa-check"></i> &nbsp;&nbsp; Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
