import { RaceTripDTO } from "../../Models/raceTripsDTO";

export enum Forms {
  ADD,
  UPDATE,
  DELETE,
  VIEW,
}

export enum CRUD {
  ADD,
  UPDATE,
  DELETE,
}

export interface UIMenuButtonDTO {
  label: any;
  iconClass: string;
  name?: string;
  id: number;
  selection: any;
}

export interface PrimeItem {
  label: string;
  value: string;
  disabled?: boolean;
  currency?:string;
}


export interface StepsDTO {
  index: number;
  label: string;
  active: boolean;
  completed: boolean;
}
export interface RunnerFieldDTO {
  isTrue: boolean;
  key: string;
  label: string;
  type: string;
}

export const StepsList: StepsDTO[] = [
  { index: 1, label: "Track Details", active: true, completed: false },
  { index: 2, label: "Race Details", active: false, completed: false },
  { index: 3, label: "Race Trip Details", active: false, completed: false },
  { index: 4, label: "Verification", active: false, completed: false },
];

export const DefaultRaceTrip: RaceTripDTO = {
  age: 0,
  apprentice: false,
  apprenticeType: "",
  barrierPos: 0,
  breedType: "",
  carriedWeight: 0,
  claimingPrice: 0,
  coupled: false,
  earnings: 0,
  equipments: false,
  equipmentsType: "",
  horseName: "",
  horseWeight: 0,
  jockeyName: "",
  lastUpdated: 0,
  medication: false,
  medicationType: "",
  programNo: "",
  providerId: "",
  raceTripKey: {
    localDate: 0,
    pzoneCode: "",
    raceNo: 0,
    selId: "",
  },
  raceTripKeyStr: "",
  scratched: false,
  sex: "",
  speedIndex: 0,
  trainerName: "",
  isEditing: false,
};

export const RunnerColCheckboxes = {
  programNo: {
    isTrue: true,
    key: "programNo",
    label: "No.",
    type: "text",
  },
  horseName: {
    isTrue: true,
    key: "horseName",
    label: "Horse",
    type: "text",
  },
  jockeyName: {
    isTrue: true,
    key: "jockeyName",
    label: "Jockey",
    type: "text",
  },
  trainerName: {
    isTrue: true,
    key: "trainerName",
    label: "Trainer",
    type: "text",
  },
  age: {
    isTrue: true,
    key: "age",
    label: "Age",
    type: "number",
  },
  sex: {
    isTrue: true,
    key: "sex",
    label: "Sex",
    type: "text",
  },
  breedType: {
    isTrue: true,
    key: "breedType",
    label: "Breed Type",
    type: "text",
  },

  barrierPos: {
    isTrue: true,
    key: "barrierPos",
    label: "Barr. Pos",
    type: "number",
  },
  horseWeight: {
    isTrue: true,
    key: "horseWeight",
    label: "Horse Weight",
    type: "number",
  },
  carriedWeight: {
    isTrue: true,
    key: "carriedWeight",
    label: "Carried Weight",
    type: "number",
  },

  providerId: {
    isTrue: true,
    key: "providerId",
    label: "Provider",
    type: "text",
  },

  // skipFields: {
  //   isTrue: false,
  //   key: "skipFields",
  //   label: "Skip Fields",
  //   type: "text",
  // },

  apprenticeType: {
    isTrue: false,
    key: "apprenticeType",
    label: "Apprentice Type",
    type: "text",
  },

  equipmentsType: {
    isTrue: false,
    key: "equipmentsType",
    label: "Equipment Type",
    type: "text",
  },

  medicationType: {
    isTrue: false,
    key: "medicationType",
    label: "Medication Type",
    type: "text",
  },

  claimingPrice: {
    isTrue: false,
    key: "claimingPrice",
    label: "Claiming Price",
    type: "number",
  },
  earnings: {
    isTrue: false,
    key: "earnings",
    label: "Earnings",
    type: "number",
  },
  speedIndex: {
    isTrue: false,
    key: "speedIndex",
    label: "Speed Index",
    type: "number",
  },

  apprentice: {
    isTrue: false,
    key: "apprentice",
    label: "isApprentice",
    type: "checkbox",
  },
  equipments: {
    isTrue: false,
    key: "equipments",
    label: "Equipments",
    type: "checkbox",
  },
  medication: {
    isTrue: false,
    key: "medication",
    label: "Medication",
    type: "checkbox",
  },
  coupled: {
    isTrue: true,
    key: "coupled",
    label: "Coupled",
    type: "checkbox",
  },
  scratched: {
    isTrue: true,
    key: "scratched",
    label: "Scrached",
    type: "checkbox",
  },
};
