import PZInputField from "../../../../UI-Components/Inputs/inputField";
import { ChangeEvent, useContext, useState } from "react";
import { CRUD } from "../../../../Helpers/StaticData/race-data";
import { showAPIToast, showToast, TOAST } from "../../../../Helpers/ui-helper";
import { UserApiService } from "../../../../services/userApiService";
import PzMultiSelect from "../../../../UI-Components/Inputs/pzMultiSelect";
import { UserRoles } from "../../../../Helpers/StaticData/user-mng-data";
import { UserContext } from "../../../../Context/userContext";

export interface IAddNewUserFormProps {
  updateUserList: any;
  setSelectedUser: any;
}

export default function AddNewUserForm(props: IAddNewUserFormProps) {
  const defaultNote = { note: "fields with * are required." };
  const defaultUser = {
    userName: "",
    phoneNo: "",
    email: "",
    roles: [] as string[],
    password: "",
  };
  const [user, setUser] = useState(defaultUser);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState<any>(defaultNote);
  const userContext = useContext(UserContext);

  const setRoles = (e: any) => {
    let _user = { ...user };
    _user.roles = e.target.value;
    setUser(_user);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let _user = { ...user } as any;
    _user[e.target.name] = e.target.value;
    setUser(_user);
  };

  const handlePassConfirmChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  const clearForm = () => {
    setUser(defaultUser);
    setError(defaultNote);
    setConfirmPassword("")
  };

  const addNewUser = () => {
    if (validateUser()) {
      userContext.startLoader();
      let _user = { ...user };
      UserApiService.addNewUser(_user)
        .then((res) => {
          if (res.data) {
            props.updateUserList(res.data, CRUD.ADD);
            props.setSelectedUser(res.data.user ?? _user);
            userContext.stopLoader();
            showToast("User added successfully.", TOAST.SUCCESS);
          }
        })
        .catch((err) => {
          userContext.stopLoader();
          showAPIToast(err, "while adding user", "add_user");
        });
    }
  };

  const handleContactChange = (e: ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (val && isNaN(Number(val))) {
      return;
    }
    let _user = { ...user };
    _user.phoneNo = val;
    setUser(_user);
  };

  const validateUser = (): boolean => {
    if (!user.userName) {
      setError({ userName: "User Name is required!" });
      return false;
    }
    if (!user.email) {
      setError({ email: "E-mail is required!" });
      return false;
    }
    if (!user.password) {
      setError({ password: "Password is required!" });
      return false;
    }
    if(user.password !== confirmPassword){
      setError({ password: "Password is not match!" });
      return false;
    }
    if (!confirmPassword) {
      setError({ confirmPassword: "Password confirmation is required!" });
      return false;
    }
    if (!user.phoneNo) {
      setError({ phoneNo: "Contact Number is required!" });
      return false;
    }
    if (!user.roles?.length) {
      setError({ roles: "Roles can not be empty!" });
      return false;
    }

    setError({ note: "fields with * are required." });
    return true;
  };

  return (
    <div>
      <div className="form-container b">
        <div className="form-header">User Creation Form</div>
        <div className="form-body">
          <div className="d-flex">
            <PZInputField
              name="userName"
              value={user.userName}
              onChange={handleChange}
              type="text"
              label="User Name *"
              placeholder="Enter user name"
              autoComplete="off"
            />
            <PZInputField
              name="email"
              value={user.email}
              onChange={handleChange}
              type="email"
              label="Email *"
              autoComplete="off"
              placeholder="Enter email"
            />
          </div>
          <div className="d-flex">
            <PZInputField
              name="password"
              value={user.password}
              onChange={handleChange}
              type="password"
              label="Password *"
              placeholder="Enter password"
              autoComplete="off"
            />
            <PZInputField
              name="re-pass"
              value={confirmPassword}
              onChange={handlePassConfirmChange}
              type="password"
              label="Re-Password *"
              placeholder="Re-Enter password"
              autoComplete="off"
            />
          </div>
          <PZInputField
            value={user.phoneNo}
            name="phoneNo"
            onChange={handleContactChange}
            type="tel"
            label="Contact Number *"
            placeholder="Enter Contact Number"
            maxLength={10}
          />
          {/* <SearchableInput
            label="Roles *"
            placeholder="Add roles to the user"
            selectedValues={selectedRoles}
            setSelectedValues={setRoles}
          /> */}
          <PzMultiSelect
            className="py-4"
            options={UserRoles}
            value={user.roles}
            onChange={setRoles}
            placeHolder="Select roles for user"
            label="Roles *"
            optionDisabled={(e: any) => e.disabled}
          />
        </div>

        <div
          className={error["note"] ? "warning-container" : "error-container"}
        >
          {error && Object.values(error)}
        </div>
        <div className="form-footer">
          <button className="pz-btn text-btn primary" onClick={clearForm}>
            <i className="fa fa-times"></i> &nbsp; Clear
          </button>
          <button className="pz-btn primary" onClick={addNewUser}>
            <i className="fa fa-plus"></i> &nbsp; Create
          </button>
        </div>
      </div>
    </div>
  );
}
