import Loader from "react-loader-spinner";
import './loaders.scss';

export default function InlineLoader(props: { showSpinner: boolean }) {

    if (props.showSpinner)
        return (
            <div className="loader-inline">
                <Loader
                    type="Puff"
                    color="#ededed"
                    height={70}
                    width={70}
                />
            </div>
        )
    return null;
}