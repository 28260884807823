import { LOCAL_STORAGE, SERVER } from "../Helpers/constants";
import axios from "axios";
import { getHeaders, getLocalStorageObject } from "../Helpers/valueHelper";

export const DashboardService = {
  getActiveRaces() {
    let url = SERVER.URL_RISK + "risk/dashboard/active-races";
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  getBetFrequency() {
    let url =
      SERVER.URL_RISK + "risk/dashboard/bet-frequency?minute=120";
    let jwt = getLocalStorageObject(LOCAL_STORAGE.JWT);
    let token = jwt?.type + " " + jwt?.token;

    let headers = { Authorization: token };

    return axios.get(url, { headers: headers });
  },
};
