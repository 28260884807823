import { useEffect, useState } from "react";
import { PoolRequestDTO } from "../../../../Models/PoolsDTO";

export interface IRequestTimerProps {
  duration: number;
  onTimerFinished: (reqId:string) => void;
  poolReq:PoolRequestDTO;
}
var interval: any;

export default function MessageTimer(props: IRequestTimerProps) {
  
  const [duration, setDuration] = useState(props.duration);

  useEffect(() => {
    var timer = props.duration;
    if (props.duration > 0) {
      interval = setInterval(() => {
        if (timer === 0) {
          props.onTimerFinished(props.poolReq.reqId);
          clearInterval(interval);
          return;
        }
        timer -= 1;
        setDuration(timer);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.duration]);

  if (duration >= 0) {
    return (
      <div className="req-timer-container" >
        <span className="timer">{duration}s</span>
      </div>
    );
  }
  return null;
}
