import { ExoticsCol } from "../../../Helpers/columns";
interface IProviderPoolTabProps {
  exoticTableData: any;
}
export default function ToteExostic({
  exoticTableData: exoticTableData,
}: IProviderPoolTabProps) {
  
  const cellTemplate = (
    poolType: any,
    field: string,
    _exoticTableData: any
  ) => {
    switch (field) {
      case "poolType":
        return (
          <div>
            {_exoticTableData[poolType]?.poolStatus === "OPEN" ? (
              <i className="fa fa-eye text-success"></i>
            ) : (
              <i className="fa fa-eye text-danger"></i>
            )}{" "}
            &nbsp;&nbsp;
            {poolType}
          </div>
        );
      case "Investment":
        return _exoticTableData[poolType].poolTotal
          ? _exoticTableData[poolType].poolTotal.toFixed(2)
          : 0;
      case "layoff":
        return _exoticTableData[poolType].layoffTotal
          ? _exoticTableData[poolType].layoffTotal.toFixed(2)
          : 0;
      case "totalPayoff":
        return _exoticTableData[poolType].totalPayoff
          ? _exoticTableData[poolType].totalPayoff.toFixed(2)
          : 0;
    }
  };
  return (
    <div className="p-2">
      <table className="pz-table">
        <thead>
          <tr>
            {ExoticsCol.map((col) => {
              return (
                <th key={col.field} data-placement="right">
                  {col.header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {exoticTableData &&
            Object.keys(exoticTableData).map((poolType: any, index: number) => {
              return (
                <tr className="row-t" key={index}>
                  {ExoticsCol.map((col) => {
                    return (
                      <td key={col.field}>
                        {cellTemplate(poolType, col.field, exoticTableData)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
