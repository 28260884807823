import { ROLES } from "./constants";

export const hasUsersAccess = (roles: string[]) => {
  return (
    roles.includes(ROLES.ADMIN) ||
    roles.includes(ROLES.USER_MGT_READ) ||
    roles.includes(ROLES.USER_MGT_MODIFY) ||
    roles.includes(ROLES.USER_MGT_DELETE)
  );
};

export const hasRiskPermission = (roles: string[]) => {
  return (
    roles.includes(ROLES.ADMIN) ||
    roles.includes(ROLES.FIX_ODDS_MGT_READ) ||
    roles.includes(ROLES.FIX_ODDS_MGT_MODIFY) ||
    roles.includes(ROLES.FIX_ODDS_MGT_DELETE)
  );
};

export const hasSchedulePermission = (roles: string[]) => {
  return (
    roles.includes(ROLES.ADMIN) ||
    roles.includes(ROLES.SCHEDULE_MGT_READ) ||
    roles.includes(ROLES.SCHEDULE_MGT_MODIFY) ||
    roles.includes(ROLES.SCHEDULE_MGT_DELETE)
  );
};

export const hasTotePermission = (roles: string[]) => {
  return roles.includes(ROLES.ADMIN) || roles.includes(ROLES.TOTE_MGT_READ);
};

export const hasReportPermission = (roles: string[]) => {
  return roles.includes(ROLES.ADMIN) || roles.includes(ROLES.REPORT_READ);
};

export const hasLogsPermission = (roles: string[]) => {
  return roles.includes(ROLES.ADMIN) || roles.includes(ROLES.LOG_READ);
};

export const hasRulesPermission = (roles: string[]) => {
  return (
    roles.includes(ROLES.ADMIN) ||
    roles.includes(ROLES.RULE_MGT_READ) ||
    roles.includes(ROLES.RULE_MGT_MODIFY) ||
    roles.includes(ROLES.RULE_MGT_DELETE)
  );
};

export const hasConnectorPermission = (roles: string[]) => {
  return (
    roles.includes(ROLES.ADMIN) ||
    roles.includes(ROLES.CONNECTOR_MGT_READ) ||
    roles.includes(ROLES.CONNECTOR_MGT_MODIFY) ||
    roles.includes(ROLES.CONNECTOR_MGT_DELETE)
  );
};

export const hasCustomerSummaryPermission = (roles: string[]) => {
  return (
    roles.includes(ROLES.ADMIN) ||
    roles.includes(ROLES.CUS_MGT_READ) ||
    roles.includes(ROLES.CUS_MGT_MODIFY) ||
    roles.includes(ROLES.CUS_MGT_DELETE)
  );
};
