import "./_inputs.scss";

export interface IPZInputFieldProps {
  label?: string;
  type: string;
  placeholder?: string;
  min?: string;
  max?: string;
  name?: string;
  onChange: any;
  onKeyUp?: any;
  value: string;
  disabled?: boolean;
  className?: string;
  inputClass?: string;
  autoFocus?: boolean;
  style?: any;
  maxLength?: number;
  autoComplete?: string;
  step?: string;
}
export default function PZInputField(props: IPZInputFieldProps) {
  return (
    <div className={`input-container ${props.className ?? ""}`}>
      <div className="input">
        <input
          type={props.type}
          placeholder={props.placeholder}
          min={props.min}
          max={props.max}
          onChange={props.onChange}
          onKeyUp={props.onKeyUp}
          value={props.value ?? ""}
          name={props.name}
          disabled={props.disabled}
          autoFocus={props.autoFocus}
          className={props.inputClass}
          maxLength={props.maxLength}
          autoComplete={props.autoComplete}
          step={props.step}          
        />
        {props.label && (
          <label className="input-label" htmlFor={props.label}>
            {props.label}
          </label>
        )}
      </div>
    </div>
  );
}
