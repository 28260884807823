import { FORMAT, LOCAL_STORAGE, SERVER } from "../../Helpers/constants";
import { JWTResponseDTO } from "../../Models/UserManagementDto";
import RaceWidgets from "./Components/RaceWidgets/raceWidgets";
import { sortObject } from "../../Helpers/list-helpers";
import { getPostTimeFormat } from "../../Helpers/dateTimeHelpers";
import BetFrequency from "./Components/BetFrequency/betFrequency";
import { BetFrequencyDTO } from "../../Models/FrequencyDTOs";
import "./dashboard.scss";
import Header from "../../UI-Components/Header/header";
import { useEffect, useState } from "react";
import { DashboardService } from "../../services/dashboardService";
import { CLOG, showAPIToast } from "../../Helpers/ui-helper";
import { ActiveRaceDTO } from "../../Models/activeRaceDTO";
import {
  DashboardContextDTO,
  DashboardContextProvider,
} from "../../Context/dashboardContext";
import {
  getLocalStorageObject,
  getTrackName,
  raceKeySplit,
} from "../../Helpers/valueHelper";

var ws: WebSocket | null;
var localContext: DashboardContextDTO = {} as DashboardContextDTO;

export default function Dashboard() {

  const [dashContext, setDashContext] = useState({} as DashboardContextDTO);
  const [upcRaces, setUpcRaces] = useState<any>(null);
  
  useEffect(() => {
    const listenToDashSocket = () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
      let jwt = getLocalStorageObject(LOCAL_STORAGE.JWT) as JWTResponseDTO;
      let url = SERVER.WS_URL_RACEPOOLS + "dashboard?token=" + jwt.token;
      ws = new WebSocket(url);

      ws.onopen = () => {
        CLOG("__WSO__DASH SOCKET OPEN___", "green");
      };

      ws.onerror = (e: any) => {
        console.error("SOCKET ERROR => ", e);
      };

      ws.onclose = () => {
        CLOG("__WSC__DASH SOCKET CLOSED___", "red");
      };

      ws.onmessage = (e: any) => {
        if (e?.data) {
          let context = { ...localContext };
          let data = JSON.parse(e.data);

          switch (data.messageType) {
            case "DASHBOARD_IP":
              let ipFreq = data.msg.ip as BetFrequencyDTO[];
              let userFreq = data.msg.user as BetFrequencyDTO[];

              if (ipFreq) context.ipFrequencies = ipFreq;
              if (userFreq) context.userFrequencies = userFreq;

              localContext = context;
              setDashContext(() => context);
              break;
            case "ACTIVE_RACES":
              let racesObj = getRaces(data.msg, context);
              let sortedObj = sortObject(
                racesObj,
                "raceKeyString",
                "localPostTime"
              );
              setUpcRaces(sortedObj);
              break;
            default:
              break;
          }
        }
      };
    };
    if (!ws && !upcRaces) {
      listenToDashSocket();
    }
  }, [dashContext, upcRaces]);

  useEffect(() => {
    const fetchActiveRace = () => {
      return new Promise<DashboardContextDTO>((resolve) => {
        let context = { ...dashContext };
        DashboardService.getActiveRaces()
          .then((res) => {
            if (res.data) {
              let racesObj = getRaces(res.data, context);
              let sortedRaces = sortObject(
                racesObj,
                "raceKeyString",
                "localPostTime"
              );
              setUpcRaces(sortedRaces);
              // setDashContext(context);
              // getBetFrequencies(context);
            }
            resolve(context);
          })
          .catch((err) => {
            console.log(err);
            resolve(context);
            showAPIToast(err, "while fetching active races", "act-races");
          });
      });
    };

    const getBetFrequencies = (context: DashboardContextDTO) => {
      return new Promise<DashboardContextDTO>((resolve) => {
        context.ipFrequencies = [];
        context.userFrequencies = [];
        DashboardService.getBetFrequency()
          .then((res) => {
            if (res.data.ip) context.ipFrequencies = res.data.ip;
            if (res.data.user) context.userFrequencies = res.data.user;
            resolve(context);
          })
          .catch((err) => {
            console.error(err);
            resolve(context);
          });
      });
    };

    if (!upcRaces) {
      fetchActiveRace()
        .then((context) => {
          getBetFrequencies(context)
            .then((context) => {
              setDashContext(context);
              localContext = context;
            })
            .catch((err) => {
              console.log("Promise Error => ", err);
            });
        })
        .catch((err) => {
          console.log("FR Promise Error => ", err);
        });
    }

    return () => {
      if (ws) {
        ws.close();
        ws = null;
      }
    };
    // eslint-disable-next-line
  }, []);

  const getRaces = (races: any, context: DashboardContextDTO) => {
    for (let raceKey in races) {
      let _race = races[raceKey] as ActiveRaceDTO;
      _race.eventType = raceKeySplit(_race.raceKey.pzoneCode, "-", 2);
      _race.trackName = getTrackName(_race.raceKey.pzoneCode);
      _race.province = raceKeySplit(_race.raceKey.pzoneCode, "-", 0);
      _race.raceKeyString = raceKey;
      _race.localPostTime = Number(
        getPostTimeFormat(_race.timezone, _race.postTime, FORMAT.yyyyMMddHHmmss)
      );
      if (!context.selectedRace) context.selectedRace = _race;
    }
    return races;
  };

  return (
    <DashboardContextProvider
      value={{ ...dashContext, updateContext: (ctx) => setDashContext(ctx) }}
    >
      <Header pageTitle="Dashboard" />
      <div className="dashboard-container">
        <BetFrequency />
        <div className="dash-body">
          <div className="first-half">
            <div className="d-col-1">
              <div className="col-card">11</div>
              <div className="col-card">12</div>
            </div>
            <div className="d-col-2">
              <div className="col-card">21</div>
            </div>
            <div className="d-col-3">
              <div className="col-card">31</div>
              <div className="col-card">32</div>
              <div className="col-card">33</div>
            </div>
          </div>
          <div className="second-half">
            <RaceWidgets races={upcRaces} />
          </div>
        </div>
      </div>
    </DashboardContextProvider>
  );
}
