import { useContext, useEffect, useState } from "react";
import { UserContextDTO, UserContextProvider } from "../../Context/userContext";
import { AppContext } from "../../Context/appContext";
import { CRUD, Forms } from "../../Helpers/StaticData/race-data";
import { UserDetailsDTO, UserDTO } from "../../Models/UserManagementDto";
import Header from "../../UI-Components/Header/header";
import AddNewUserForm from "./Components/AddNewUser/addNewUser";
import ViewSelectedUser from "./Components/SelectedUser/selectedUser";
import "./userAccessMangement.scss";
import { UserApiService } from "../../services/userApiService";
import { sortUsers } from "../../Helpers/list-helpers";
import { showToast, TOAST } from "../../Helpers/ui-helper";
import LargeLoader from "../../UI-Components/Loader/loaders";

export default function UserAccessManagement() {
  const [selected, setSelected] = useState({
    form: Forms.VIEW,
  });
  const [selectedUser, setSelectedUser] = useState<UserDTO>({} as any);
  const [usersList, setUsersList] = useState<UserDetailsDTO[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<UserDetailsDTO[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [userContextState, setUserContextState] = useState(
    {} as UserContextDTO
  );
  const appContext = useContext(AppContext);

  useEffect(() => {
    const getAllUserData = () => {
      let userCtx = { ...userContextState };
      UserApiService.getAllUsers()
        .then((res) => {
          if (res.data) {
            userCtx.usersList = res.data;
            let users = sortUsers(res.data, "userName");
            setUsersList(users);
            setFilteredUsers(users);
          }
          setUserContextState(userCtx);
        })
        .catch((err) => {
          if (err.response) showToast(err.response.data.message, TOAST.ERROR);
          else
            showToast("Server did not respond! Please try later!", TOAST.ERROR);
        });
    };
    if (appContext.user && !usersList.length) getAllUserData();

    if (usersList.length && !selectedUser.uid) {
      onSelectUser(usersList[0].user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext.user, usersList]);

  const onSelectUser = (user: UserDTO) => {
    let sel = { ...selected };
    sel.form = Forms.VIEW;
    setSelected(sel);
    setSelectedUser(user);
  };

  const setSelection = (selection: any, value: any) => {
    let selectedVals: any = { ...selected };
    selectedVals[selection] = value;
    setSelected(selectedVals);
  };

  const updateUsersList = (userData: UserDetailsDTO, mode: CRUD) => {
    let users = [...usersList];
    let index = users.findIndex((x) => x.user.uid === userData.user.uid);
    switch (mode) {
      case CRUD.ADD:
        users.push(userData);
        setSelectedUser(userData.user);
        break;

      case CRUD.UPDATE:
        // let index = users.findIndex((x) => x.user.uid === userData.user.uid);
        if (index !== -1) {
          users[index].user = userData.user;
          setSelectedUser(users[index].user);
        }
        break;

      case CRUD.DELETE:
        // let i = users.findIndex((x) => x.user.uid === userData.user.uid);
        if (index !== -1) {
          users[index].user = userData.user;
          setSelectedUser(users[index].user);
        }
        break;
    }
    users = sortUsers(users, "userName");
    setUsersList(users);
    setFilteredUsers(users);
  };

  // Templates
  const getSelectedFormTemplate = () => {
    switch (selected.form) {
      case Forms.ADD:
        return (
          <AddNewUserForm
            setSelectedUser={onSelectUser}
            updateUserList={updateUsersList}
          />
        );
      case Forms.VIEW:
        return (
          <ViewSelectedUser
            updateUserList={updateUsersList}
            selectedUser={selectedUser}
          />
        );
      default:
        return null;
    }
  };
  const getTemplateTitle = () => {
    switch (selected.form) {
      case Forms.ADD:
        return <span>Add New User</span>;
      case Forms.VIEW:
        return <span>View User</span>;
      case Forms.UPDATE:
        return <span>Update Existing Users</span>;
      default:
        break;
    }
  };
  const onAddUserClilck = () => {
    setSelection("form", Forms.ADD);
    setSearchValue("");
    onSearchUser(null);
    setSelectedUser({} as any);
  };

  const startLoader = () => {
    let context = { ...userContextState };
    context.isLoading = true;
    setUserContextState(context);
  };
  const stopLoader = () => {
    let context = { ...userContextState };
    context.isLoading = false;
    setUserContextState(context);
  };

  const onSearchUser = (e: any) => {
    let _filteredValues;
    if (!e) {
      setFilteredUsers([...usersList]);
      return;
    }

    let value = e.target.value;
    if (!value.trim().length) {
      _filteredValues = [...usersList];
    } else {
      _filteredValues = usersList.filter((userObj) => {
        return (
          userObj.user.email.toLowerCase().includes(value.toLowerCase()) ||
          userObj.user.userName.toLowerCase().includes(value.toLowerCase())
        );
      });
    }
    setSearchValue(value);
    setFilteredUsers(_filteredValues);
  };

  // clear Search Result
  const handleClearSearch = () => {
    setFilteredUsers([...usersList]);
    setSearchValue("");
  };

  return (
    <UserContextProvider
      value={{
        isLoading: userContextState.isLoading,
        usersList: userContextState.usersList,
        startLoader: startLoader,
        stopLoader: stopLoader,
        updateUserList: updateUsersList,
      }}
    >
      <Header pageTitle="User & Access Management" />
      <LargeLoader isLoading={userContextState.isLoading} />
      <div className="content heightfull">
        <div className="content-left-panel" style={{ width: "25%" }}>
          <div className="sidebar-tab">
            <div style={{ width: "50%" }}>
              <button
                onClick={onAddUserClilck}
                className="pz-btn text-btn w-100"
              >
                <i className="fa fa-plus"></i> Add User
              </button>
            </div>
            {selected.form === Forms.VIEW && (
              <div className="src-container">
                <input
                  onChange={onSearchUser}
                  type="text"
                  value={searchValue}
                  className="src-input"
                  placeholder="Search Users"
                />
                {searchValue.length > 0 ? (
                  <span
                    onClick={() => handleClearSearch()}
                    className="fa fa-close src-icon"
                    style={{ cursor: "pointer" }}
                  ></span>
                ) : (
                  <span className="fa fa-search src-icon"></span>
                )}
              </div>
            )}
          </div>
          <div className="user-cards-container">
            {filteredUsers.map((userData) => {
              return (
                <div key={userData.user.uid}>
                  <div
                    className={
                      selectedUser.email === userData.user.email
                        ? "user-card active"
                        : "user-card"
                    }
                    onClick={() => onSelectUser(userData.user)}
                  >
                    <div className="d-flex flex-column">
                      <span className="email">{userData.user.email}</span>
                      <span className="username">{userData.user.userName}</span>
                    </div>

                    <div className="d-flex flex-column justify-content-between align-items-end">
                      <span>
                        {userData.user.isSuspended && (
                          <i className="fa fa-user-times suspend-icon"></i>
                        )}
                        {userData.user.isDeleted && (
                          <i className="fa fa-trash delete-icon"></i>
                        )}
                      </span>
                      <span>
                        {userData.user.roles.includes("ADMIN") && (
                          <span className="badge m-1">ADMIN</span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
            {filteredUsers.length === 0 && (
              <div className="d-flex justify-content-center mt-5">
                <span className="content-not-found">User Not Found</span>
              </div>
            )}
          </div>
        </div>

        <div className="content-middle-panel" style={{ width: "100%" }}>
          <div className="content-panel-header multiple head">
            {getTemplateTitle()}
          </div>
          {getSelectedFormTemplate()}
        </div>
      </div>
      {/* <Footer /> */}
    </UserContextProvider>
  );
}
