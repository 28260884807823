import { toast } from "react-toastify";

export const CLOG = (msg: string, color?: string) => {
  console.log("%c" + msg, `color:${color}`);
};
export enum TOAST {
  ERROR,
  SUCCESS,
  WARNING,
  NORMAL,
}
export const showToast = (message: string, type: TOAST, toastId?: string) => {
  let options = {
    position: toast.POSITION.BOTTOM_LEFT,
    autoClose: 3500,
    toastId: toastId ?? "",
    style: { fontSize: 16 },
  };
  switch (type) {
    case TOAST.ERROR:
      return toast.error(message, options);
    case TOAST.SUCCESS:
      return toast.success(message, options);
    case TOAST.WARNING:
      return toast.warn(message, options);
    default:
      break;
  }
};

export const showAPIToast = (err: any, elseMsg: string, key?: string) => {
  if (err.response && err.response.data && err.response.data.message) {
    showToast(err.response.data.message, TOAST.ERROR);
  } else {
    showToast(
      "Something went wrong " + elseMsg + "! please try later!",
      TOAST.ERROR,
      key ?? "NO_RESPONSE"
    );
  }
};

export const detailTemplate = (dataList: { label: string; data: any }[]) => {
  return (
    <div className="detail">
      {dataList.map((obj) => {
        return (
          <div key={obj.label} className="detail-container">
            <label>{obj.label}</label>
            <span className={obj.label === "Status" ? obj.data : ""}>
              {!obj.data
                ? "-"
                : obj.data === 98765.56789
                ? "-"
                : obj.data === ""
                ? "-"
                : obj.data === "0"
                ? "-"
                : obj.data === 0
                ? "-"
                : obj.data}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export const getFloatButton = (props: { onClick: any }) => {
  return (
    <div className="add-profile-btn">
      <button onClick={props.onClick}>
        <i className="fa fa-plus"></i>
      </button>
    </div>
  );
};

export const millisToTime = (millis: number) => {
  let d = new Date(1000 * Math.ceil(millis / 1000));
  function pad(i: any) {
    return ("0" + i).slice(-2);
  }
  let m = pad(d.getUTCMinutes());
  let s = pad(d.getUTCSeconds());
  let h = d.getUTCHours();
  let str = m + ":" + s;

  if (h) {
    str = h + ":" + m + ":" + s;
  }

  return str;
};
