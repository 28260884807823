import { useState } from "react";
import "./betFrequency.scss";
import IPFrequency from "./FrequencyTabs/ipFrequencyTab";
import UserFrequency from "./FrequencyTabs/userFrequencyTab";
export interface IBetFrequencyProps {}

enum Tab {
  IP,
  USER,
}

export default function BetFrequency(props: IBetFrequencyProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.USER);

  const openFrequencies = () => {
    setIsOpen(!isOpen);
  };

  const renderFrequenciesTempalte = () => {
    switch (selectedTab) {
      case Tab.IP:
        return <IPFrequency />;
      case Tab.USER:
        return <UserFrequency />;
    }
  };

  const noteTemplate = () => {
    switch (selectedTab) {
      case Tab.IP:
        return "Note: Shownig multiple users betting with same IP Address!";
      case Tab.USER:
        return "Note: Showing single user betting with multiple IP Addresses!";
    }
  };

  const tabHeaderTemplate = () => {
    return (
      <>
        <button
          onClick={() => setSelectedTab(Tab.USER)}
          className={selectedTab === Tab.USER ? "bf-tab active" : "bf-tab"}
        >
          {" "}
          <i className="fa fa-user-circle"></i> &nbsp; USER
        </button>
        <button
          onClick={() => setSelectedTab(Tab.IP)}
          className={selectedTab === Tab.IP ? "bf-tab active" : "bf-tab"}
        >
          {" "}
          <i className="fa fa-globe"></i> &nbsp; IP
        </button>
      </>
    );
  };

  return (
    <div
      className={isOpen ? "bf-container bf-container-active" : "bf-container"}
    >
      <button className="bf-head" onClick={() => openFrequencies()}>
        <i className="fa fa-globe globe-icon"></i>
        &nbsp; &nbsp;&nbsp;Bet Frequency
      </button>
      <div className={isOpen ? "bf-body open" : "bf-body close"}>
        <div className="position-relative">
          <header
            className=" p-3 b-bottom font-weight-bold font-italic"
            style={{ background: "#232323" }}
          >
            -
          </header>
          <section>
            <div className="bf-tab-container">
              <div className="bf-tab-header">{tabHeaderTemplate()}</div>

              <div className="note text-muted font-italic font-weight-bold">
                {noteTemplate()}
              </div>

              <div className="bf-tab-body">{renderFrequenciesTempalte()}</div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
