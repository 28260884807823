interface IProviderPoolTabProps {
  toteDataTable: any;
  selectedPoolType: string;
  setSelectedPool: (selectedPool: string) => void;
}
export default function TotePoolTab({
  toteDataTable,
  setSelectedPool,
  selectedPoolType,
}: IProviderPoolTabProps) {
  return (
    <div className="poolListWrapper">
      <div className="poolHeader">Select a Pool</div>
      <div
        className="poolBtnContainer"
        style={{ justifyContent: "space-between", padding: "5px" }}
      >
        <div style={{ display: "flex" }}>
          {toteDataTable &&
            Object.keys(toteDataTable)
              .sort((a, b) => (a > b ? -1 : 1))
              .map((poolType: any, index: number) => {
                return (
                  <div key={index}>
                    <div>
                      <button
                        key={index}
                        className={
                          poolType === selectedPoolType
                            ? "tab-btn active"
                            : "tab-btn"
                        }
                        onClick={() => setSelectedPool(poolType)}
                      >
                        {toteDataTable[poolType].poolStatus === "OPEN" ? (
                          <i className="fa fa-eye text-success"></i>
                        ) : (
                          <i className="fa fa-eye text-danger"></i>
                        )}{" "}
                        &nbsp;&nbsp;
                        {poolType}
                      </button>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
}
