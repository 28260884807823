import axios from "axios";
import { SERVER } from "../Helpers/constants";
import { getHeaders } from "../Helpers/valueHelper";
import { PoolKeyDTO } from "../Models/PoolsDTO";

export const FixedOddsManagementService = {
  applySuggestedOdds(oddsObj: { poolKey: PoolKeyDTO; reqOdds: any }) {
    let url = SERVER.URL_RISK + SERVER.MODULE.RISK + "apply-suggested-odd";
    let headers = getHeaders();
    return axios.post(url, oddsObj, { headers: headers });
  },
  getRacePools(raceKeyStr: string) {
    let header = getHeaders();
    let url =
      SERVER.URL_SCHEDULE_MNG +
      SERVER.MODULE.SCHEDULE +
      "poolsRace/" +
      raceKeyStr;
    return axios.get(url, { headers: header });
  },
  getLiabilities(poolKeyStr: string) {
    let url =
      SERVER.URL_RISK + SERVER.MODULE.RISK + "get-pool-lb/" + poolKeyStr;
    let header = getHeaders();

    return axios.get(url, { headers: header });
  },

  getSuggestedOdds(poolKeyStr: string) {
    let url =
      SERVER.URL_RISK + SERVER.MODULE.RISK + "get-suggested-odds/" + poolKeyStr;

    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  // getPoolInvestments(poolKey: string) {
  //   let url =
  //     SERVER.URL_RISK + SERVER.MODULE.RISK + "get-pool-investment/" + poolKey;
  //   let headers = getHeaders();
  //   return axios.get(url, { headers: headers });
  // },
  displayPools(display: boolean, raceKey: string) {
    let url =
      SERVER.URL_SCHEDULE_MNG +
      SERVER.MODULE.SCHEDULE +
      "poolsRace/" +
      raceKey +
      "/" +
      display;
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  changeFOPoolStatus(raceKey: string, poolStatus: string) {
    let url =
      SERVER.URL_SCHEDULE_MNG +
      SERVER.MODULE.SCHEDULE +
      "pool-status/" +
      raceKey +
      "/" +
      poolStatus;

    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  addMaxBetAmounts(poolKeyStr: string, sel: string, amount: number) {
    let url =
      SERVER.URL_RISK +
      SERVER.MODULE.RISK +
      `add-max-bet/${poolKeyStr}/${sel}/${amount}`;
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  addSelLiabilities(poolKeyStr: string, sel: string, amount: number) {
    let url =
      SERVER.URL_RISK +
      SERVER.MODULE.RISK +
      `add-sel-lb/${poolKeyStr}/${sel}/${amount}`;
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },

  updatePoolLB(amount: number, poolKey: string) {
    let url =
      SERVER.URL_RISK + SERVER.MODULE.RISK + `add-pool-lb/${poolKey}/${amount}`;

    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  addSelLayoff(poolKey: string, selId: string, amount: number) {
    let url =
      SERVER.URL_RISK +
      SERVER.MODULE.RISK +
      `add-sel-layoff/${poolKey}/${selId}/${amount}`;

    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  getOddsInv(poolKey: string, selId: string) {
    let url =
      SERVER.URL_RISK + SERVER.MODULE.RISK + `get-odds-inv/${poolKey}/${selId}`;

    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  editBookPer(poolKey: string, bookPer: any) {
    let url =
      SERVER.URL_RISK +
      SERVER.MODULE.RISK +
      `change-book-per/${poolKey}/${bookPer}`;
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
};
