import "./selectedUser.scss";
import { UserDTO } from "../../../../Models/UserManagementDto";
import { CRUD, Forms } from "../../../../Helpers/StaticData/race-data";
import ModifyUserTemplate from "./modifyUser";
import { useContext, useState } from "react";
import { confirmDialog } from "primereact/confirmdialog"; // To use confirmDialog method
import { format } from "date-fns";
import { showAPIToast, showToast, TOAST } from "../../../../Helpers/ui-helper";
import { RoleToDescMap } from "../../../../Helpers/StaticData/user-mng-data";
import { UserApiService } from "../../../../services/userApiService";
import { FORMAT } from "../../../../Helpers/constants";
import { UserContext } from "../../../../Context/userContext";

export interface IViewSelectedUserProps {
  selectedUser: UserDTO;
  updateUserList: any;
}

export default function ViewSelectedUser(props: IViewSelectedUserProps) {
  const [selectedForm, setSelectedForm] = useState(Forms.VIEW);
  const userContext = useContext(UserContext);

  const getInfoDataTemplate = (label: string, data: any) => {
    return (
      <div className="info-data">
        <span>{label} </span>
        <span>{data}</span>
      </div>
    );
  };

  const dataTemplate = () => {
    return (
      <div className="d-flex h-100">
        <div className="roles-col">
          <div className="header">Roles</div>
          <div>
            {props.selectedUser?.roles?.map((role) => {
              return (
                <div key={role} className="role-container">
                  <span className="role-key">{role}</span>
                  <span className="role-desc">
                    {RoleToDescMap[role] && RoleToDescMap[role].desc}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="data-info w-100 p-3">
          {props.selectedUser.isDeleted && (
            <div className="user-status">DELETED</div>
          )}
          {getInfoDataTemplate("User Name : ", props.selectedUser.userName)}
          {getInfoDataTemplate("E-mail : ", props.selectedUser.email)}
          {getInfoDataTemplate("Contact No. : ", props.selectedUser.phoneNo)}
        </div>
      </div>
    );
  };

  const changeForm = (form: Forms) => {
    setSelectedForm(form);
  };

  const onDeleteUser = () => {
    confirmDialog({
      message: `Sure to delete ${props.selectedUser.userName}'s data?`,
      header: "Deleting User?",
      icon: "pi pi-exclamation-triangle",
      accept: () => deleteUser(),
      acceptLabel: `Yes! I'm Sure`,
      rejectLabel: "No",
      reject: () => { },
    });
  };

  const onReStoreUser = () => {
    confirmDialog({
      message: `Sure to Re-Active ${props.selectedUser.userName}'s data?`,
      header: "Re-Activating User?",
      icon: "pi pi-exclamation-triangle",
      accept: () => reActivateUser(),
      acceptLabel: `Yes! I'm Sure`,
      rejectLabel: "No",
      reject: () => { },
    });
  };

  const deleteUser = () => {
    userContext.startLoader();
    UserApiService.deletUser(props.selectedUser)
      .then((res) => {
        if (res.status === 200) {
          props.updateUserList(res.data, CRUD.DELETE);

        }
        userContext.stopLoader();

      })
      .catch((err) => {
        userContext.stopLoader();
        showAPIToast(err, "while deleting user", "d_user");
      });
  };

  const reActivateUser = () => {
    userContext.startLoader();
    UserApiService.reActivateDeletedUser(props.selectedUser.uid)
      .then((res) => {
        props.updateUserList(res.data, CRUD.UPDATE);
        userContext.stopLoader();
      })
      .catch((err) => {
        userContext.stopLoader();
        console.error(err);
        if (err.response) {
          showToast(err.response.data.message, TOAST.ERROR);
        } else {
          showToast("Something went wrong! Please try again!", TOAST.ERROR);
        }
      });
  };

  return (
    <div className="selected-user-container">
      <div className="info-header">
        <div className="username">
          {" "}
          <i className="fa fa-user"></i> &nbsp;{props.selectedUser.userName}
        </div>
        <div className="email">
          <i className="fa fa-envelope" style={{ fontSize: "1.5rem" }}></i>{" "}
          &nbsp; {props.selectedUser.email}
        </div>
      </div>

      <div className="info-body">
        <div className="info-body-col">
          {selectedForm === Forms.VIEW ? (
            dataTemplate()
          ) : (
            <ModifyUserTemplate
              updateUserList={props.updateUserList}
              changeForm={changeForm}
              selectedUser={props.selectedUser}
            />
          )}

          {selectedForm === Forms.VIEW && (
            <div className="info-body-footer">
              <div className="footer-info">
                <div className="">
                  <span style={{ fontWeight: 600, color: "#AAAAAA" }}>
                    Updated on{" "}
                  </span>
                  <span>
                    {props.selectedUser.updatedDateTime &&
                      format(
                        new Date(props.selectedUser.updatedDateTime),
                        FORMAT.ddMMyyyyHHmmss_DASH
                      )}
                  </span>
                </div>
                <div className="">
                  <span style={{ fontWeight: 600, color: "#AAAAAA" }}>
                    Created on{" "}
                  </span>
                  <span>
                    {props.selectedUser.createdDateTime &&
                      format(
                        new Date(props.selectedUser.createdDateTime),
                        FORMAT.ddMMyyyyHHmmss_DASH
                      )}
                  </span>
                </div>
              </div>
              <div>
                {!props.selectedUser.isDeleted && (
                  <>
                    <button
                      onClick={() => changeForm(Forms.UPDATE)}
                      className="pz-btn primary"
                    >
                      <i className="fa fa-pencil"></i> Modify{" "}
                    </button>
                    <button
                      onClick={onDeleteUser}
                      className="pz-btn danger-btn mx-2"
                    >
                      <i className="fa fa-trash"></i> Delete{" "}
                    </button>
                  </>
                )}
                {props.selectedUser.isDeleted && (
                  <button onClick={onReStoreUser} className="pz-btn warning">
                    Restore User
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
