import { SelectButton } from "primereact/selectbutton";
import { useContext, useState } from "react";
import { FixedOddsContext } from "../../../Context/fixedOddsContext";
import { FoPoolRaceStatus } from "../../../Helpers/StaticData/pool-data-static";
import { showToast, TOAST } from "../../../Helpers/ui-helper";
import { onPoolKeySeparate } from "../../../Helpers/valueHelper";
import { PoolDTO } from "../../../Models/PoolsDTO";
import { FixedOddsManagementService } from "../../../services/fixedOddsManagementService";
import LargeLoader from "../../../UI-Components/Loader/loaders";

interface IProviderPoolTabProps {
  poolsList: PoolDTO[];
}

const PoolsTab = ({ poolsList }: IProviderPoolTabProps) => {
  const { selectedPool, onSelectPool, selectedRace } =
    useContext(FixedOddsContext);
  const [isLoading, setIsLoading] = useState(false);

  const getPoolStr = (poolType: string) => {
    switch (poolType) {
      case "FO_WIN":
        return "WIN FIXED";
      case "FO_PLACE":
        return "PLACE FIXED";
      default:
        return poolType;
    }
  };

  const onFOPoolStatusChange = (e: any) => {
    setIsLoading(true);
    FixedOddsManagementService.changeFOPoolStatus(
      selectedRace.raceKeyString,
      e.value
    )
      .then((res) => {
        if (res && res.data) {
          for (const pool of res.data) {
            if (pool.trackPoolStatus === "SUCCESS") {
              showToast(
                "In " +
                  onPoolKeySeparate(pool.poolKey) +
                  "  Status Changed Successfully",
                TOAST.SUCCESS,
                pool.poolKey
              );
            } else if (pool.trackPoolStatus === "FAIL") {
              showToast(
                pool.message + " In  " + onPoolKeySeparate(pool.poolKey),
                TOAST.ERROR,
                pool.poolKey
              );
            }
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err.response.data.message);
        showToast(err, TOAST.ERROR);
        setIsLoading(false);
      });
  };

  return (
    <div className="poolListWrapper">
      <LargeLoader isLoading={isLoading} />

      <div className="poolHeader">Select a Pool</div>
      <div
        className="poolBtnContainer"
        style={{ justifyContent: "space-between", padding: "5px" }}
      >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {Object.values(poolsList)
            .sort((a, b) =>
              a.poolDetails.poolKey.poolType > b.poolDetails.poolKey.poolType
                ? -1
                : 1
            )
            .map((pool: PoolDTO, index: number) => {
              return (
                <div key={index}>
                  <div>
                    <button
                      key={pool.poolDetails.poolKeyString}
                      className={
                        pool.poolDetails.poolKeyString ===
                        selectedPool?.poolDetails?.poolKeyString
                          ? "tab-btn active"
                          : "tab-btn"
                      }
                      onClick={() => onSelectPool(pool)}
                    >
                      {pool.poolDetails.poolStatus === "OPEN" ? (
                        <i className="fa fa-eye text-success"></i>
                      ) : (
                        <i className="fa fa-eye text-danger"></i>
                      )}
                      &nbsp;&nbsp;
                      {getPoolStr(pool.poolDetails.poolKey.poolType)}
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
        <div>
          {!selectedRace.cancelled && (
            <SelectButton
              optionLabel="label"
              optionValue="value"
              options={FoPoolRaceStatus}
              onChange={onFOPoolStatusChange}
            ></SelectButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default PoolsTab;
