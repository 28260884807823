import React from "react";
import { __SelToRunner } from "../Models/dynamicTypes";

export interface ToteContextDTO {
  selectedPool: string;
  selectedRaceTrips: __SelToRunner;
  poolTableData: any;
}

export const ToteContext = React.createContext<ToteContextDTO>(
  {} as ToteContextDTO
);

export const ToteContextProvider = ToteContext.Provider;
