import React from "react";
import { PrimeItem } from "../Helpers/StaticData/race-data";
import { PoolRequestDTO } from "../Models/PoolsDTO";
import { JWTResponseDTO, UserDTO } from "../Models/UserManagementDto";

export interface AppContextDTO {
  user: UserDTO;
  jwtResponse: JWTResponseDTO;
  race: any | null;
  reqObj: PoolRequestDTO | null;
  connectorsOptions: PrimeItem[];
  clearData: () => void;
  openRace: (route: string, race: any) => void;
  getConnectorOptions: (ctxt: AppContextDTO) => void;
  updateContext: (context: AppContextDTO) => void;
  updatePoolRequest: (req: PoolRequestDTO | null) => void;

}

export const AppContext = React.createContext<AppContextDTO>({} as any);

export const AppContextProvider = AppContext.Provider;

export const AppContextConsumer = AppContext.Consumer;
