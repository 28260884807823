import React, { useState, useEffect, useRef } from "react";
import { Sidebar } from "primereact/sidebar";
import { ScheduleApiService } from "../../../services/scheduleApiService";
import { showToast, TOAST } from "../../../Helpers/ui-helper";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import "./RaceTimeConfig.scss";
import { raceTimeColumn } from "../../../Helpers/columns";
import { raceTimeDTO } from "../../../Models/raceTimeDTO";
import { format } from "date-fns";
import {
  getStringToDateFormat,
  getDateAndTimeFormat,
} from "../../../Helpers/dateTimeHelpers";
import LargeLoader from "../../../UI-Components/Loader/loaders";
import { OverlayPanel } from "primereact/overlaypanel";
import { Calendar } from "primereact/calendar";

const RaceTimeConfig = (props: any) => {
  // variables
  const { isSidebarOpen, setIsSidebarOpen, selectedRaceTime } = props;
  const [raceTimeData, setRaceTimeData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const panelRef = useRef(null as any);
  const [raceDate, setRaceDate] = useState<any>(null);
  const [raceRowData, setRaceRowData] = useState({} as any);

  // callbacks
  useEffect(() => {
    setIsLoading(true);
    ScheduleApiService.getRaceTime(selectedRaceTime)
      .then((res) => {
        if (res.data) {
          let raceTimelist: any = [];
          for (let key of res.data) {
            let raceTimeDataObj: any = { ...key } as raceTimeDTO;
            raceTimeDataObj.localDate = getStringToDateFormat(
              key.raceKey.localDate.toString()
            );
            raceTimeDataObj.raceOpenTime = getDateAndTimeFormat(
              key.raceOpenTime,
              "day"
            );
            raceTimeDataObj.pzoneCode = key.raceKey.pzoneCode;
            raceTimeDataObj.raceNo = key.raceKey.raceNo;
            raceTimelist.push(raceTimeDataObj);
          }
          setRaceTimeData(raceTimelist);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        showToast(error.response?.data.message, TOAST.ERROR);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // functions
  const handleCancel = () => {
    setIsSidebarOpen(false);
  };

  const editTemplate = (rowData: any) => {
    return (
      <button className="round_btn" onClick={(e) => handleRowEdit(e, rowData)}>
        <i className="fa fa-pencil"></i>
      </button>
    );
  };

  const handleRowEdit = (e: any, rowData: any) => {
    let formatDate = getDateAndTimeFormat(rowData.raceOpenTime, "year");
    setRaceDate(new Date(formatDate));
    setRaceRowData(rowData);
    if (panelRef && panelRef.current) {
      panelRef.current.toggle(e);
    }
  };
  const handleDateChange = (e: any) => {
    setRaceDate(e.value);
  };

  const handleUpdateRaceTime = () => {
    setIsLoading(true);
    let raceOpenTimeFormat = format(raceDate, "yyyyMMddHHmmss");
    let trackConfig = {} as any;
    trackConfig.raceKey = raceRowData.raceKey;
    trackConfig.timeZone = raceRowData.timeZone;
    trackConfig.raceOpenTime = raceOpenTimeFormat;
    ScheduleApiService.raceTimeConfig(trackConfig)
      .then((res) => {
        if (res.data) {
          let raceTimelist: any = [];
          for (let key of raceTimeData) {
            if (key.raceNo === raceRowData.raceNo) {
              key.raceOpenTime = getDateAndTimeFormat(
                res.data.raceOpenTime,
                "day"
              );
            }
            raceTimelist.push(key);
          }
          setRaceTimeData(raceTimelist);
          showToast(
            `Race ${raceRowData.raceNo} Is Updated Successfully`,
            TOAST.SUCCESS
          );
          panelRef.current?.hide();
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        showToast(error.response?.data.message, TOAST.ERROR);
      });
  };
  return (
    <div className="race-time-wrapper">
      <LargeLoader isLoading={isLoading} />
      <Sidebar
        visible={isSidebarOpen}
        onHide={handleCancel}
        className="sidebar"
      >
        {raceTimeData.length !== 0 ? (
          <>
            <div className="heading">
              <span className="title">Race Time Config</span>
            </div>
            <div className="race-time-body">
              <DataTable className="dataTable" value={raceTimeData}>
                {raceTimeColumn.map((col, index) => (
                  <Column
                    key={index}
                    style={{ width: col.width }}
                    field={col.field}
                    header={col.header}
                    body={col.field === "edit" && editTemplate}
                  />
                ))}
              </DataTable>
            </div>
            <OverlayPanel
              ref={panelRef}
              showCloseIcon
              style={{ width: "350px" }}
              className="pz-overlaypanel"
            >
              <div className="overlay_panel_heading">
                <h3 className="mb-3">Edit Race</h3>
              </div>
              <div className="edit_race_body mb-3">
                <label htmlFor="time">Date & Time</label>
                <Calendar
                  id="time"
                  value={raceDate}
                  onChange={(e) => handleDateChange(e)}
                  showTime
                  showSeconds
                  hourFormat="12"
                  dateFormat="dd/mm/yy"
                />
              </div>

              <button
                className="overlay_update_btns"
                onClick={() => handleUpdateRaceTime()}
              >
                Update
              </button>
              <button
                className="overlay_cancel_btns ml-2"
                onClick={() => panelRef.current?.hide()}
              >
                Cancel
              </button>
            </OverlayPanel>
          </>
        ) : (
          <span className="empty_data">No Data Found</span>
        )}
      </Sidebar>
    </div>
  );
};

export default RaceTimeConfig;
