import { useContext } from "react";
import { DashboardContext } from "../../../../../Context/dashboardContext";
import { millisToTime } from "../../../../../Helpers/ui-helper";

export interface IUserFrequencyProps {}

export default function UserFrequency(props: IUserFrequencyProps) {
  const dashContext = useContext(DashboardContext);

  return (
    <div>
      {dashContext &&
        dashContext?.userFrequencies?.map((betF) => {
          return (
            <div className="bf-info-body" key={betF.betFrequency.key}>
              <div className="bf-info-all">
                <div className="row">
                  <div className="col-4 px-4 label text-left">User</div>
                  <div className="col-8 px-4 text-left">
                    {betF.betFrequency.customerEmail}
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 px-4 label text-left">Frequency</div>
                  <div className="col-8 px-4 text-left">
                    {millisToTime(betF.betFrequency.frequency)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 px-4 label text-left">Total Bets</div>
                  <div className="col-8 px-4 text-left">
                    {betF.betFrequency.noOfBets}
                  </div>
                </div>
              </div>
              <div>
                {betF.frequencys?.map((freq) => {
                  return (
                    <div key={freq.betIp} className="bf-info-single">
                      <div className="row">
                        <div className="col-4 px-4 label text-left">IP</div>
                        <div className="col-8 px-4 text-left">{freq.betIp}</div>
                      </div>
                      <div className="row">
                        <div className="col-4 px-4 label text-left">
                          Frequency
                        </div>
                        <div className="col-8 px-4 text-left">
                          {millisToTime(freq.frequency)}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 px-4 label text-left">
                          Total Bets
                        </div>
                        <div className="col-8 px-4 text-left">
                          {freq.noOfBets}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}

      {dashContext?.userFrequencies?.length === 0 && (
        <div className="text-muted text-center h3 font-weight-bold">
          {" "}
          No data available
        </div>
      )}
    </div>
  );
}
