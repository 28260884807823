import { AutoComplete } from "primereact/autocomplete";
import { confirmPopup } from "primereact/confirmpopup";
import { ChangeEvent, useEffect, useState } from "react";
import {
  ReportFieldList,
  Functions,
} from "../../../../../../Helpers/StaticData/eventTypeList";
import {
  _eventTypeList,
  _poolList,
} from "../../../../../../Helpers/StaticData/pool-data-static";
import { showToast, TOAST } from "../../../../../../Helpers/ui-helper";
import { CustomerRule } from "../../../../../../Models/customerRulesDto";
import { RulesService } from "../../../../../../services/rulesApiService";
import { ScheduleApiService } from "../../../../../../services/scheduleApiService";
import PzCheckBox from "../../../../../../UI-Components/Inputs/checkBoxInput";
import PZInputField from "../../../../../../UI-Components/Inputs/inputField";
import PzDropDown from "../../../../../../UI-Components/Inputs/pzDropDown";
import LargeLoader from "../../../../../../UI-Components/Loader/loaders";
import { ScreenType } from "../../CustomerRules";
import "./addCustomerRules.scss";
export interface IAddCustomerRulesScreenProps {
  changeScreen: (screen: ScreenType) => void;
  selectedRowData: any;
  isUpdated: any;
  onRuleIdAdded: string;
  isRuleIdAdded: any;
}
export interface customerRuleData {
  prefNo: string;
  ruleName: string;
}

export default function AddCustomerRules(props: IAddCustomerRulesScreenProps) {
  const [countryList, setCountryList] = useState([]);

  const [ruleId, setRuleId] = useState({
    country: "",
    eventType: "",
    poolType: "",
  });
  const [isDefault, setIsDefault] = useState(false);

  const [customerRuleData, setCustomerRuleData] = useState(
    {} as customerRuleData
  );

  const [filters, setFilters] = useState({
    reportField: "",
    filter: [{ funcName: "", funcValue: "" }],
  });
  const [filterFunctionsList, setFilterFunctionsList] = useState<any>(null);
  const [jsonFilter, setjsonFilter] = useState({} as any);
  const [isSelectedBtn, setIsSelectedBtn] = useState("");
  const [actionTypeValue, setActionTypeValue] = useState("");
  const [functionList, setFunctionList] = useState(Functions);
  const [isSelectedFunction, setIsSelectedFunction] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDoubleHashFuncSelected, setIsDoubleHashFuncSelected] =
    useState(false);

  const REDUCED_MAX_BET = "REDUCED_MAX_BET";
  const REDUCED_ODDS = "REDUCED_ODDS";
  const REJECTED = "REJECTED";
  const DEFAULT = "DEFAULT";

  useEffect(() => {
    if (props.isUpdated && props.selectedRowData) {
      let _selectedRowData = { ...props.selectedRowData };
      let split_List = _selectedRowData.customerRuleKey.ruleId.split("~");
      onSetRuleKey(split_List);
      setCustomerRuleData({
        prefNo: _selectedRowData.prefNo,
        ruleName: _selectedRowData.customerRuleKey.ruleName,
      });
      setjsonFilter(_selectedRowData.filter);
      if (
        _selectedRowData.reducedMaxBet &&
        _selectedRowData.actionType === REDUCED_MAX_BET
      ) {
        setActionTypeValue(_selectedRowData.reducedMaxBet);
        setIsSelectedBtn(REDUCED_MAX_BET);
      } else if (
        _selectedRowData.reducedOdds &&
        _selectedRowData.actionType === REDUCED_ODDS
      ) {
        setActionTypeValue(_selectedRowData.reducedOdds);
        setIsSelectedBtn(REDUCED_ODDS);
      } else {
        setIsSelectedBtn(REJECTED);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedRowData]);

  useEffect(() => {
    getProvinces();
    if (props.isRuleIdAdded) {
      let splitList = props.onRuleIdAdded.split("~");
      onSetRuleKey(splitList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProvinces = () => {
    setIsLoading(true);
    ScheduleApiService.getProvinces()
      .then((res) => {
        if (res.data) {
          let provinces = Object.values(res.data).map((p: any) => p.province);
          let _countrList: any = [];
          _countrList.push({ label: DEFAULT, value: DEFAULT });
          for (const province of provinces) {
            let _countryobj: any = {};
            _countryobj.label = province;
            _countryobj.value = province;
            _countrList.push(_countryobj);
          }
          setCountryList(_countrList);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        showToast("province Data Not Found!", TOAST.ERROR);
        console.log(err);
      });
  };

  const onSetRuleKey = (splitList: any) => {
    let _ruleId = ruleId;
    _ruleId.country = splitList[0];
    _ruleId.eventType = splitList[1];
    _ruleId.poolType = splitList[2];
    setRuleId(_ruleId);
  };

  const onChangeRuleKey = (e: any) => {
    let _ruleId = { ...ruleId } as any;
    if (e.target.value === DEFAULT) {
      _ruleId.country = e.target.value;
      _ruleId.eventType = e.target.value;
      _ruleId.poolType = e.target.value;
      setIsDefault(true);
    } else {
      _ruleId[e.target.name] = e.target.value;
    }
    setRuleId(_ruleId);
  };

  const onDefaultSelect = (e: any) => {
    let _ruleId = { ...ruleId };
    setIsDefault(e.value);
    if (e.value) {
      _ruleId.country = DEFAULT;
      _ruleId.eventType = DEFAULT;
      _ruleId.poolType = DEFAULT;
    } else {
      _ruleId.country = "";
      _ruleId.eventType = "";
      _ruleId.poolType = "";
    }
    setRuleId(_ruleId);
  };

  const onChangeRuleData = (e: ChangeEvent<HTMLInputElement>) => {
    let _ruleData = { ...customerRuleData } as any;
    _ruleData[e.target.name] = e.target.value;
    setCustomerRuleData(_ruleData);
  };

  const onChangeReportField = (e: any) => {
    let _filterDataObj = { ...filters };
    let _jsonFilterObj = { ...jsonFilter };
    let jsonFilterObjKeys = Object.keys(_jsonFilterObj);
    for (const key of jsonFilterObjKeys) {
      if (key === e.target.value) {
        showToast(key + "Already Selected", TOAST.ERROR);
        return;
      }
    }
    _filterDataObj.reportField = e.target.value;
    setFilters(_filterDataObj);
  };

  const onSearchFunctions = (event: { query: string }) => {
    let _filteredFunctions;
    let _functionList = [...functionList];

    if (!event.query.trim().length) {
      _filteredFunctions = _functionList;
    } else {
      _filteredFunctions = _functionList.filter((func) => {
        return func.name.toLowerCase().startsWith(event.query.toLowerCase());
      });
    }
    setFilterFunctionsList(_filteredFunctions);
  };

  const deleteFuncListValue = () => {
    let _functionList = [...functionList];
    for (var i = 0; i < _functionList.length; i++) {
      if (
        _functionList[i].value === "##AND" ||
        _functionList[i].value === "##OR"
      ) {
        _functionList.splice(i, 1);
        i--;
      }
    }
    setFunctionList(_functionList);
    setFilterFunctionsList(_functionList);
  };

  const onChangeFunction = (e: any, index: number) => {
    let _filterDataObj = { ...filters } as any;
    if (e.target.name === "funcName") {
      let _funcName = e.value.value ? e.value.value : e.value;
      if (index > 0) {
        deleteFuncListValue();
      }
      if (_funcName === "##AND" || _funcName === "##OR") {
        setIsDoubleHashFuncSelected(true);
        _filterDataObj.filter[index]["funcValue"] = "";
        if (
          !_filterDataObj.filter[index + 1] &&
          !_filterDataObj.filter[index + 2]
        ) {
          _filterDataObj.filter.push(
            { funcName: "", funcValue: "" },
            { funcName: "", funcValue: "" }
          );
        }
      }
      _filterDataObj.filter[index][e.target.name] = _funcName;
    } else {
      _filterDataObj.filter[index][e.target.name] = e.target.value;
    }
    setFilters(_filterDataObj);
  };
  const onFunctionAdd = () => {
    let _filterDataObj = { ...filters };
    for (const filterVal of _filterDataObj.filter) {
      if (!filterVal.funcName && !filterVal.funcValue) {
        showToast("Add Function Values", TOAST.ERROR);
        return;
      }
    }
    _filterDataObj.filter.push({ funcName: "", funcValue: "" });
    setFilters(_filterDataObj);
  };

  const onFunctionRemove = (index: number) => {
    let _filterDataObj = { ...filters };
    if (_filterDataObj.filter.length > 3) {
      _filterDataObj.filter.splice(index, 1);
    }
    setFilters(_filterDataObj);
  };

  const onAddFunction = () => {
    setIsSelectedFunction(false);
    setFunctionList(Functions);
    let _jsonFilterObj = { ...jsonFilter };
    let _filterDataObj = { ...filters };
    let tempList = [];

    if (!_filterDataObj.reportField) {
      showToast("select Customer Report Field", TOAST.ERROR);
      return false;
    }
    for (const data of _filterDataObj.filter) {
      let str;
      if (
        !data.funcName ||
        (data.funcName !== "##AND" &&
          !data.funcValue &&
          data.funcName !== "##OR" &&
          !data.funcValue)
      ) {
        showToast("select Function Or fill proper value", TOAST.ERROR);
        return false;
      }
      let length = Functions.filter((a) => a.value === data.funcName);
      if (length.length === 0) {
        showToast(data.funcName + "is Invalid function name", TOAST.ERROR);
        return;
      }
      if (data.funcValue) {
        str = data.funcName + "(" + data.funcValue + ")";
      } else {
        str = data.funcName;
      }
      tempList.push(str);
    }
    _jsonFilterObj[_filterDataObj.reportField] = tempList.toString();
    setjsonFilter(_jsonFilterObj);
    setFilters({
      reportField: "",
      filter: [{ funcName: "", funcValue: "" }],
    });
    setIsDoubleHashFuncSelected(false);
  };

  const onClearFunction = () => {
    setFilters({
      reportField: "",
      filter: [{ funcName: "", funcValue: "" }],
    });
    setIsDoubleHashFuncSelected(false);
    setFunctionList(Functions);
    setIsSelectedFunction(false);
  };

  const onEditFilter = (key: string, value: any) => {
    setIsDoubleHashFuncSelected(false);
    setIsSelectedFunction(true);
    let _filterObj: any = {};
    let tempList = value.split(",");
    let list = [];
    for (const data of tempList) {
      let funcObj: any = {};
      if (data === "##AND" || data === "##OR") {
        setIsDoubleHashFuncSelected(true);
        funcObj["funcName"] = data.split("(")[0];
        funcObj["funcValue"] = "";
      } else {
        funcObj["funcName"] = data.split("(")[0];
        funcObj["funcValue"] = data.substring(
          data.indexOf("(") + 1,
          data.indexOf(")")
        );
      }
      list.push(funcObj);
    }
    _filterObj.reportField = key;
    _filterObj.filter = list;
    setFilters(_filterObj);
  };

  const onDeleteFilter = (key: string) => {
    let _jsonData = { ...jsonFilter };
    delete _jsonData[key];
    setjsonFilter(_jsonData);
  };

  const onBtnSelected = (value: string) => {
    setIsSelectedBtn(value);
    setActionTypeValue("");
  };

  const onSubmitCustomerRule = () => {
    if (isValidateCustomerRule()) {
      setIsLoading(true);
      let customerRule = CustomerRuleRequestObj() as CustomerRule;
      RulesService.addCustomerRule(customerRule)
        .then((res) => {
          if (res) {
            setIsLoading(false);
            props.changeScreen(ScreenType.VIEW);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.message) {
            showToast(err.response.data.message, TOAST.ERROR);
          } else {
            showToast(
              "something went wrong on submit cutomer rule!",
              TOAST.ERROR
            );
          }
          setIsLoading(false);
        });
    }
  };

  const confirmUpdate = (e: any) => {
    confirmPopup({
      target: e.currentTarget,
      message: "Are you sure you want to update this customer rule?",
      icon: "pi pi-exclamation-triangle",
      accept: () => onUpdateCustomerRule(),
      reject: () => {},
    });
  };

  const isValidateCustomerRule = () => {
    let _ruleKey = { ...ruleId };
    let _ruleData = { ...customerRuleData };
    if (
      !_ruleKey.country ||
      !_ruleKey.eventType ||
      !_ruleKey.poolType ||
      Object.values(_ruleData).length === 0 ||
      !_ruleData.prefNo ||
      !_ruleData.ruleName
    ) {
      showToast("select all Values", TOAST.ERROR);
      return false;
    }
    if (Object.keys(jsonFilter).length === 0) {
      showToast("Fill Proper Filter Values", TOAST.ERROR);
      return false;
    }
    if (!isSelectedBtn || (isSelectedBtn !== REJECTED && !actionTypeValue)) {
      showToast("Fill Action Type Value", TOAST.ERROR);
      return false;
    }
    return true;
  };

  const CustomerRuleRequestObj = () => {
    let _RuleKey = { ...ruleId };
    let customerRule = {} as CustomerRule;
    let _cRuleKeyObj: any = {};
    _cRuleKeyObj.ruleId =
      _RuleKey.country + "~" + _RuleKey.eventType + "~" + _RuleKey.poolType;
    _cRuleKeyObj.ruleName = customerRuleData.ruleName;

    customerRule.actionType = isSelectedBtn;
    customerRule.customerRuleKey = _cRuleKeyObj;

    customerRule.prefNo = Number(customerRuleData.prefNo);
    if (isSelectedBtn === REDUCED_MAX_BET) {
      customerRule.reducedMaxBet = Number(actionTypeValue);
    } else if (isSelectedBtn === REDUCED_ODDS) {
      customerRule.reducedOdds = Number(actionTypeValue);
    }
    customerRule.filter = jsonFilter;
    return customerRule;
  };

  const onUpdateCustomerRule = () => {
    if (isValidateCustomerRule()) {
      setIsLoading(true);
      let customerRule = CustomerRuleRequestObj() as CustomerRule;
      RulesService.updateCustomerRule(customerRule)
        .then((res) => {
          if (res && res.data) {
            setIsLoading(false);
            props.changeScreen(ScreenType.VIEW);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.message) {
            showToast(err.response.data.message, TOAST.ERROR);
          } else {
            showToast("something went wrong in update data!", TOAST.ERROR);
          }
          setIsLoading(false);
        });
    }
  };
  return (
    <div style={{minWidth:'1000px'}}>
      <LargeLoader isLoading={isLoading} />
      <header className=" page-head b-bottom">
        <span className="b-right">
          <button
            onClick={() => props.changeScreen(ScreenType.VIEW)}
            className="btn icon-btn hover"
            style={{ color: "white" }}
          >
            <i className="fa fa-arrow-left"></i> Back
          </button>
          &nbsp;&nbsp;&nbsp;
        </span>
        &nbsp;&nbsp;&nbsp;
        <span>Add Customer Rule</span>
      </header>
      <div className="d-flex justify-content-left">
        <div className="form">
          <div className="b-bottom p-3">
            {props.isUpdated ? (
              <h2 className="font-1 text-center">Update Below Details</h2>
            ) : (
              <h2 className="font-1 text-center">Enter Below Details</h2>
            )}
          </div>
          <div className="d-flex p-3">
            <div style={{ width: "20%" }}>
              <PzDropDown
                options={countryList}
                onChange={onChangeRuleKey}
                placeHolder="Select a Country"
                name="country"
                value={ruleId.country}
                label="Country"
                disabled={props.isRuleIdAdded || props.isUpdated || isDefault}
              />
            </div>
            <div style={{ width: "20%" }}>
              <PzDropDown
                options={_eventTypeList}
                onChange={onChangeRuleKey}
                placeHolder="Select a event type"
                name="eventType"
                value={ruleId.eventType}
                label="event type"
                disabled={props.isRuleIdAdded || props.isUpdated || isDefault}
              />
            </div>
            <div style={{ width: "20%" }}>
              <PzDropDown
                options={_poolList}
                onChange={onChangeRuleKey}
                placeHolder="Select a pool type"
                name="poolType"
                value={ruleId.poolType}
                label="Pool type"
                disabled={props.isRuleIdAdded || props.isUpdated || isDefault}
              />
            </div>
            <PzCheckBox
              value={isDefault}
              name="default"
              className="justify-content-center"
              label="Default"
              onChange={(e: any) => onDefaultSelect(e)}
              disabled={props.isRuleIdAdded || props.isUpdated}
            />
          </div>
          <div className="d-flex justify-content-flex-start  p-3">
            <div className="py-2">
              <PZInputField
                type="text"
                label="Rule Name"
                value={customerRuleData.ruleName}
                name="ruleName"
                placeholder="Enter Rule Name"
                className="my-2"
                onChange={onChangeRuleData}
                disabled={props.isUpdated}
              />
            </div>
            <div className="py-2">
              <PZInputField
                type="number"
                label="Pref No."
                value={customerRuleData.prefNo}
                name="prefNo"
                placeholder="Enter Pref No."
                className="my-2"
                onChange={onChangeRuleData}
                disabled={props.isUpdated}
              />
            </div>
          </div>

          <div className="p-3">
            <span className="label">Filter : </span>
            <div style={{ border: "1px solid #565656" }}>
              <div>
                <div className="d-flex p-2">
                  <div style={{ width: "20%" }}>
                    <PzDropDown
                      options={ReportFieldList}
                      onChange={onChangeReportField}
                      placeHolder="Select Customer Report Field"
                      name="eventType"
                      value={filters.reportField}
                      disabled={isSelectedFunction}
                    />
                  </div>
                  <div>
                    {filters.filter.map((val: any, index) => {
                      return (
                        <div className="d-flex" key={index}>
                          <div className="input-container-add">
                            <div className="input">
                              <AutoComplete
                                value={val.funcName}
                                suggestions={filterFunctionsList}
                                completeMethod={onSearchFunctions}
                                field="name"
                                name="funcName"
                                placeholder="Enter # For Select Function"
                                onChange={(e: any) =>
                                  onChangeFunction(e, index)
                                }
                                disabled={
                                  isDoubleHashFuncSelected && index === 0
                                }
                              />
                            </div>
                          </div>
                          {val?.funcName !== "##AND" &&
                            val?.funcName !== "##OR" && (
                              <>
                                <div>
                                  <PZInputField
                                    type="number"
                                    value={val.funcValue}
                                    name="funcValue"
                                    placeholder="Enter Function Value"
                                    onChange={(e: any) =>
                                      onChangeFunction(e, index)
                                    }
                                  />
                                </div>
                              </>
                            )}
                          {val?.funcName !== "##AND" &&
                            val?.funcName !== "##OR" &&
                            isDoubleHashFuncSelected && (
                              <div className="p-3">
                                <button
                                  className="btn-func"
                                  onClick={() => onFunctionRemove(index)}
                                >
                                  <span className="btn-container">
                                    <i className="fa fa-minus"></i>
                                  </span>
                                </button>
                              </div>
                            )}
                        </div>
                      );
                    })}
                    {isDoubleHashFuncSelected && (
                      <div>
                        <button
                          className="btn-func"
                          onClick={() => onFunctionAdd()}
                        >
                          <span className="btn-container">
                            <i className="fa fa-plus"></i>
                          </span>
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="p-3">
                    <div className="d-flex">
                      <button
                        className="btn-func"
                        onClick={() => onAddFunction()}
                      >
                        <span className="btn-container">
                          <span
                            style={{ fontWeight: "bold", fontSize: "13px" }}
                          >
                            {isSelectedFunction
                              ? "Update Function"
                              : "Add Function"}
                          </span>
                        </span>
                      </button>
                      <button className="btn-func" onClick={onClearFunction}>
                        <span className="btn-container">
                          <span
                            style={{ fontWeight: "bold", fontSize: "13px" }}
                          >
                            Clear Function
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="p-3">
            <div>
              <span className="label">Action Type : </span>
              <button
                className={
                  isSelectedBtn === REDUCED_MAX_BET
                    ? "button-style-selected m-1"
                    : "button-style m-1"
                }
                onClick={() => onBtnSelected(REDUCED_MAX_BET)}
              >
                REDUCED_MAX_BET
              </button>
              <button
                className={
                  isSelectedBtn === REDUCED_ODDS
                    ? "button-style-selected m-1"
                    : "button-style m-1"
                }
                onClick={() => onBtnSelected(REDUCED_ODDS)}
              >
                REDUCED_ODDS
              </button>
              <button
                className={
                  isSelectedBtn === REJECTED
                    ? "button-style-selected m-1"
                    : "button-style m-1"
                }
                onClick={() => onBtnSelected(REJECTED)}
              >
                REJECTED
              </button>
            </div>
          </div>
          {isSelectedBtn === REDUCED_MAX_BET && (
            <div className="p-3">
              <div className="py-2" style={{ width: "20%" }}>
                <PZInputField
                  type="number"
                  label="reducedMaxBet"
                  value={actionTypeValue}
                  placeholder="Enter ReducedMaxBet"
                  className="my-2"
                  onChange={(e: any) => setActionTypeValue(e.target.value)}
                />
              </div>
            </div>
          )}
          {isSelectedBtn === REDUCED_ODDS && (
            <div className="p-3">
              <div className="py-2" style={{ width: "20%" }}>
                <PZInputField
                  type="number"
                  label="reducedOdds"
                  value={actionTypeValue}
                  name="reducedOdds"
                  placeholder="Enter ReducedOdds"
                  className="my-2"
                  onChange={(e: any) => setActionTypeValue(e.target.value)}
                />
              </div>
            </div>
          )}
          <div className="p-3">
            {!props.isUpdated && (
              <button
                className="button-style-selected"
                onClick={onSubmitCustomerRule}
              >
                Submit
              </button>
            )}
            {props.isUpdated && (
              <button className="button-style-selected" onClick={confirmUpdate}>
                Update
              </button>
            )}
          </div>
        </div>
        {Object.keys(jsonFilter).length > 0 && (
          <div style={{ width: "30%", background: "#464646" }}>
            <div className="b-bottom p-3">
              <h2 className="font-1 text-center">Filter Functions</h2>
            </div>
            <div>
              {Object.keys(jsonFilter).map((res, index) => {
                return (
                  <div className="contain_filter" key={index}>
                    <div>
                      <span className="m-1 label">
                        {res} : {jsonFilter[res]}
                      </span>
                    </div>
                    <div className="d-flex">
                      <span
                        className="m-2"
                        onClick={() => onEditFilter(res, jsonFilter[res])}
                        style={{ fontSize: "15px", cursor: "pointer" }}
                      >
                        <i className="fa fa-pencil"></i>
                      </span>
                      <span
                        className="m-2"
                        onClick={() => onDeleteFilter(res)}
                        style={{ fontSize: "15px", cursor: "pointer" }}
                      >
                        <i className="fa fa-trash"></i>
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
