import React, {  useState } from "react";
import { showToast, TOAST } from "../../Helpers/ui-helper";
import {
  BetfairBalanceResponse,
  BetFairUrlResponse,
  BFConnectorResponse,
} from "../../Models/connectorDto";
import { RulesService } from "../../services/rulesApiService";
import "./connectors.scss";
import { format } from "date-fns";
import { dateTimeConversion } from "../../Helpers/dateTimeHelpers";
import ViewConnectors from "./Screens/ViewConnectors";
import AddConnectors, { ConnectorInfo } from "./Screens/AddConnectors";
import Header from "../../UI-Components/Header/header";

export enum ScreenType {
  ADD,
  VIEW,
}

export default function Connector() {

  const [selectedScreen, setSelectedScreen] = useState(ScreenType.VIEW);
  const [isLoading, setIsLoading] = useState(false);
  const [isAccountIdDisable, setIsAccountIdDisable] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [connectorInfo, setConnectorInfo] = useState({} as ConnectorInfo);
  const [getAllConnectors, setAllConnectors] = useState<BFConnectorResponse[]>(
    []
  );
  const [filteredConnectors, setFilteredConnectors] = useState<
    BFConnectorResponse[]
  >([]);
  const [betfairBalanceResponse, setBetfairBalanceResponse] = useState(
    {} as BetfairBalanceResponse
  );
  const [betfairUrlResponse, setBetfairUrlResponse] = useState(
    {} as BetFairUrlResponse
  );
  const [isLoggedIn, setIsLoggedIn] = useState(false);  

  const changeScreen = (screen: number) => {
    if (screen === 1) {
      setSelectedScreen(ScreenType.VIEW);
    } else {
      setSelectedScreen(ScreenType.ADD);
    }
  };

  const ScreenTemplate = () => {
    switch (selectedScreen) {
      case ScreenType.VIEW:
        return (
          <ViewConnectors
            changeScreen={changeScreen}
            onBetFairLoginWindow={onBetFairLoginWindow}
            getAllConnectorsData={getAllConnectorsData}
            getAllConnectors={getAllConnectors}
            filteredConnectors={filteredConnectors}
            setFilteredConnectors={setFilteredConnectors}
          />
        );
      case ScreenType.ADD:
        return (
          <AddConnectors
            changeScreen={changeScreen}
            betFairLoginStatus={betFairLoginStatus}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            setAccountId={setAccountId}
            accountId={accountId}
            betfairBalanceResponse={betfairBalanceResponse}
            setBetfairBalanceResponse={setBetfairBalanceResponse}
            betfairUrlResponse={betfairUrlResponse}
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            connectorInfo={connectorInfo}
            setConnectorInfo={setConnectorInfo}
            isAccountIdDisable={isAccountIdDisable}
            setBetfairUrlResponse={setBetfairUrlResponse}
            setIsAccountIdDisable={setIsAccountIdDisable}
          />
        );
      default:
        break;
    }
  };

  const betFairLoginStatus = () => {
    setIsAccountIdDisable(true);
    setIsLoading(true);
    RulesService.checkAccountIdAlreadyAddedOrNot(accountId)
      .then((res) => {
        if (res.data === false) {
          onBetFairLoginWindow("add", null);
        } else {
          showToast("This Account Id is already added", TOAST.ERROR);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onBetFairLoginWindow = (checkViewOrAdd: string, dataObj: any) => {
    let vendorId =
      dataObj == null ? betfairUrlResponse.accountId : dataObj.vendorId;
    let accId = dataObj !== null ? dataObj.accountId : accountId;
    let wp = window.open(
      "https://identitysso.betfair.com.au/view/vendor-login?client_id=" +
        vendorId +
        "&response_type=code&redirect_uri=/verify/ashva~" +
        accId +
        "?ashva=ashva",
      "betFair Login",
      "width=700,height=500"
    );
    let bodyReq: any = {};
    bodyReq.accountId = accId;
    bodyReq.connectorType = dataObj
      ? dataObj.connectorType
      : connectorInfo.connectorType;
    bodyReq.url = dataObj ? dataObj.url : connectorInfo.connectorUrl;
    setIntervalForBetfairStatus(wp, bodyReq, checkViewOrAdd);
  };

  const setIntervalForBetfairStatus = (
    wp: any,
    bodyReq: any,
    checkViewOrAdd: string
  ) => {
    let timer = setInterval((x: any) => {
      if (wp?.closed) {
        setIsLoading(false);
        clearInterval(timer);
      }
      RulesService.checkBetfairLoginStatus(bodyReq)
        .then((res) => {
          if (res.data) {
            setBetfairBalanceResponse(res.data);
            if (checkViewOrAdd === "view") {
              onCheckBetfairReLoginStatus(res.data);
            }
            setIsLoggedIn(true);
            wp?.close();
            clearInterval(timer);
            showToast("BetFair Login Successfully ", TOAST.SUCCESS);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            showToast(err.response["data"].message, TOAST.ERROR);
          } else {
            showToast("Server did not respond! Please try later!", TOAST.ERROR);
          }
          setIsLoading(false);
        });
    }, 2000);
  };
  const onCheckBetfairReLoginStatus = (data: any) => {
    RulesService.checkBetfairReLoginStatus(data.accountId)
      .then((res) => {
        if (res.data) {
          showToast("Login Successfully Done", TOAST.SUCCESS);
          getAllConnectorsData();
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          showToast(err.response["data"].message, TOAST.ERROR);
        } else {
          showToast("Server did not respond! Please try later!", TOAST.ERROR);
        }
        setIsLoading(false);
      });
  };

  const getAllConnectorsData = () => {   

    setIsLoading(true);
    RulesService.getConnectors()
      .then((res) => {
        if (res.data) {
          convertDateTimeFormate(res.data);
        }         
        setAllConnectors(res.data);
        setFilteredConnectors(res.data);
        setIsLoading(false);       
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          showToast(err.response["data"].message, TOAST.ERROR);
        } else {
          showToast("Server did not respond! Please try later!", TOAST.ERROR);
        }
        setIsLoading(false);
      });
  };

  const dateTimeConvert = (datetime: any, timezone: string) => {
    let f_date = new Date(datetime);
    let dateConvertStr = format(f_date, "yyyyMMddHHmmss");
    var formatedDate = dateTimeConversion(timezone, dateConvertStr);
    return formatedDate;
  };

  const convertDateTimeFormate = (resData: any) => {
    for (const data of resData) {
      if (data.lastConnected) {
        data.lastConnected = dateTimeConvert(data.lastConnected, data.timezone);
      }
      if (data.lastDisconnected) {
        data.lastDisconnected = dateTimeConvert(
          data.lastDisconnected,
          data.timezone
        );
      }
    }
  };

  return (
    <>
      <div>
        <Header pageTitle="Connectors" />
        <div className="content heightfull">{ScreenTemplate()}</div>

        {ScreenType.VIEW === selectedScreen && (
          <div className="add-profile-btn-connector">
            <button onClick={() => setSelectedScreen(ScreenType.ADD)}>
              <i className="fa fa-plus"></i>
            </button>
          </div>
        )}
      </div>
    </>
  );
}
