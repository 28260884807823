import { createContext } from "react";
import { ActiveRaceDTO } from "../Models/activeRaceDTO";
import { BetFrequencyDTO } from "../Models/FrequencyDTOs";

export interface DashboardContextDTO {
  // upcRaces: any;
  selectedRace: ActiveRaceDTO;
  ipFrequencies: BetFrequencyDTO[];
  userFrequencies: BetFrequencyDTO[];
  updateContext: (context: DashboardContextDTO) => void;
}

export const DashboardContext = createContext<DashboardContextDTO>({} as any);

export const DashboardContextProvider = DashboardContext.Provider;

export const DashboardContextConsumer = DashboardContext.Consumer;
