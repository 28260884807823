import { getFloatButton } from "../../../../../Helpers/ui-helper";
import { ScreenType } from "../../ScratchProfile/scratchProfile";
//@ts-ignore
import PoolRuleTable from "../ToteRuleTable.tsx/toteRuleTable";

export interface IViewPoolRulesScreenProps {
  changeScreen: (screen: ScreenType) => void;
  connectorList: any;
  getPoolRules: any;
}

export default function ViewPoolRulesScreen(props: IViewPoolRulesScreenProps) {
  return (
    <div style={{minWidth:'1000px'}}>
      <div>
        <div className="content-panel-header head multiple">
          Available Tote Pool Rules
        </div>
        <div className="content inline-height p-4">
          <PoolRuleTable
            onAddRuleClick={() => props.changeScreen(ScreenType.ADD)}
            connectorList={props.connectorList}
            getPoolRules={props.getPoolRules}
          />
        </div>
        {getFloatButton({ onClick: () => props.changeScreen(ScreenType.ADD) })}
      </div>
    </div>
  );
}
