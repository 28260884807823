import React, { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import "./AddFunction.scss";
import {
  ReportFieldList,
  Functions,
} from "../../../../Helpers/StaticData/eventTypeList";
import PzDropDown from "../../../../UI-Components/Inputs/pzDropDown";
import PZInputField from "../../../../UI-Components/Inputs/inputField";
import { AutoComplete } from "primereact/autocomplete";
import { showToast, TOAST } from "../../../../Helpers/ui-helper";
import { AddFunctionProps } from "../../../../Models/searchCustomerReportDTO";

const AddFunction = (props: AddFunctionProps) => {
  // variables
  const {
    isSidebarOpen,
    setIsSidebarOpen,
    filterValueObj,
    setFilterValueObj,
    isEditFunction,
    setIsEditFunction,
    filterValue,
    isDoubleHashFuncSelected,
    setIsDoubleHashFuncSelected,
  } = props;
  const [filterFunctionsList, setFilterFunctionsList] = useState<any>(null);
  const [functionList, setFunctionList] = useState(Functions);

  const [filters, setFilters] = useState({
    reportField: "",
    filter: [{ funcName: "", funcValue: "" }],
  });

  // callbacks
  useEffect(() => {
    if (isEditFunction && filterValue) {
      setFilters(filterValue);
      if (
        filterValue?.filter[0]?.funcName === "##AND" ||
        filterValue?.filter[0]?.funcName === "##OR"
      ) {
        setIsDoubleHashFuncSelected(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue]);

  //   functions
  const handleCancel = () => {
    setIsSidebarOpen(false);
    setIsDoubleHashFuncSelected(false);
    setIsEditFunction(false);
    setFilters({ reportField: "", filter: [{ funcName: "", funcValue: "" }] });
  };

  const handleChangeReportField = (e: any) => {
    let _filters = { ...filters } as any;
    let _filterObj = { ...filterValueObj } as any;
    let _filterObjKey = Object.keys(_filterObj);
    for (let key of _filterObjKey) {
      if (key === e.target.value) {
        showToast(`${key} is already selected`, TOAST.ERROR);
        return;
      }
    }
    _filters.reportField = e.target.value;
    setFilters(_filters);
  };
  const deleteFuncListValue = () => {
    let _functionList = [...functionList];
    for (var i = 0; i < _functionList.length; i++) {
      if (
        _functionList[i].value === "##AND" ||
        _functionList[i].value === "##OR"
      ) {
        _functionList.splice(i, 1);
        i--;
      }
    }
    setFunctionList(_functionList);
    setFilterFunctionsList(_functionList);
  };

  const handleFunctionChange = (e: any, index: number) => {
    let _filterObject = { ...filters } as any;
    if (e.target.name === "funcName") {
      let _funcName = e.value.value ? e.value.value : e.value;
      if (index > 0) {
        deleteFuncListValue();
      }
      if (_funcName === "##AND" || _funcName === "##OR") {
        setIsDoubleHashFuncSelected(true);
        _filterObject.filter[index]["funcValue"] = "";
        if (
          !_filterObject.filter[index + 1] &&
          !_filterObject.filter[index + 2]
        ) {
          _filterObject.filter.push(
            { funcName: "", funcValue: "" },
            { funcName: "", funcValue: "" }
          );
        }
      }
      _filterObject.filter[index][e.target.name] = _funcName;
    } else {
      _filterObject.filter[index][e.target.name] = e.target.value;
    }
    setFilters(_filterObject);
  };

  const handleOnSearchFunction = (event: { query: string }) => {
    let _filteredFunction;
    let _functionalList = [...functionList];
    if (!event.query.trim().length) {
      _filteredFunction = _functionalList;
    } else {
      _filteredFunction = _functionalList.filter((func) => {
        return func.name.toLowerCase().startsWith(event.query.toLowerCase());
      });
    }
    setFilterFunctionsList(_filteredFunction);
  };

  const handleAddFunction = () => {
    setIsEditFunction(false);
    setFunctionList(Functions);
    let _filterDataObject = { ...filters } as any;
    if (!_filterDataObject) {
      showToast("Plase Fill The All Fields", TOAST.ERROR);
      return false;
    }
    let _filterValueObject = { ...filterValueObj };
    let functionValueList = [];
    if (!_filterDataObject.reportField) {
      showToast("Select Customer Report Field", TOAST.ERROR);
      return false;
    }
    for (let data of _filterDataObject.filter) {
      let str;
      if (
        !data.funcName ||
        (data.funcName !== "##AND" &&
          !data.funcValue &&
          data.funcName !== "##OR" &&
          !data.funcValue)
      ) {
        showToast("Select All Fields", TOAST.ERROR);
        return false;
      } else {
        setIsDoubleHashFuncSelected(true);
      }
      let function_name = Functions.filter(
        (val) => val.value === data.funcName
      );
      if (function_name.length === 0) {
        showToast(`${data.funcName} is Invalid function name`, TOAST.ERROR);
        return;
      }
      if (data.funcValue) {
        str = `${data.funcName}(${data.funcValue})`;
      } else {
        str = data.funcName;
      }

      functionValueList.push(str);
    }
    _filterValueObject[_filterDataObject.reportField] =
      functionValueList.toString();
    setFilterValueObj(_filterValueObject);
    if (isEditFunction) {
      if (
        filterValue?.filter[0]?.funcName === "##AND" ||
        filterValue?.filter[0]?.funcName === "##OR"
      ) {
        setIsDoubleHashFuncSelected(true);
      }
    } else {
      setFilters({
        reportField: "",
        filter: [{ funcName: "", funcValue: "" }],
      });
    }
    setIsSidebarOpen(false);
    setIsDoubleHashFuncSelected(false);
  };

  const onFunctionAdd = () => {
    let _filtersObject = { ...filters } as any;
    for (let filterVal of _filtersObject.filter) {
      if (!filterVal.funcName && !filterVal.funcValue) {
        showToast("Add Function Values", TOAST.ERROR);
        return;
      }
    }
    _filtersObject.filter.push({ funcName: "", funcValue: "" });
    setFilters(_filtersObject);
  };

  const onFunctionRemove = (index: number) => {
    let _filterObject = { ...filters };
    if (_filterObject.filter.length > 3) {
      _filterObject.filter.splice(index, 1);
    }
    setFilters(_filterObject);
  };

  const handleClearFunction = () => {
    setIsEditFunction(false);
    setIsDoubleHashFuncSelected(false);
    setFilters({ reportField: "", filter: [{ funcName: "", funcValue: "" }] });
  };

  return (
    <div className="add-customer-wrapper">
      <Sidebar
        visible={isSidebarOpen}
        onHide={handleCancel}
        className="sidebar"
        baseZIndex={1}
      >
        <div className="header">
          <span className="title">
            {isEditFunction ? "Edit Function" : "Add Function"}
          </span>
        </div>
        <div>
          <span className="title">Filter</span>
          <div
            className={
              isDoubleHashFuncSelected ? "dubble-hash-fun-row" : "customer_row"
            }
          >
            <div className="dropdown_row">
              <PzDropDown
                options={ReportFieldList}
                onChange={handleChangeReportField}
                placeHolder="Select Customer Report"
                name="reportField"
                value={filters.reportField}
                disabled={isEditFunction}
              />
            </div>
            {filters?.filter.map((val: any, index: number) => {
              return (
                <div className="d-flex" key={index}>
                  <div className="input-container-add dropdown_row">
                    <div className="input">
                      <AutoComplete
                        value={val?.funcName}
                        placeholder="Enter # For Select Function"
                        suggestions={filterFunctionsList}
                        field="name"
                        name="funcName"
                        completeMethod={handleOnSearchFunction}
                        onChange={(e: any) => handleFunctionChange(e, index)}
                        disabled={isDoubleHashFuncSelected && index === 0}
                      />
                    </div>
                  </div>
                  {val.funcName !== "##AND" && val.funcName !== "##OR" && (
                    <div className="dropdown_row">
                      <PZInputField
                        type="number"
                        value={val.funcValue}
                        name="funcValue"
                        placeholder="Enter Function Value"
                        onChange={(e: any) => handleFunctionChange(e, index)}
                      />
                    </div>
                  )}
                  {val.funcName !== "##AND" &&
                    val.funcName !== "##OR" &&
                    isDoubleHashFuncSelected && (
                      <div className="p-3">
                        <button
                          className="button_fun"
                          onClick={() => onFunctionRemove(index)}
                        >
                          <span className="btn-container">
                            <i className="fa fa-minus"></i>
                          </span>
                        </button>
                      </div>
                    )}
                </div>
              );
            })}
          </div>
          {isDoubleHashFuncSelected && (
            <div>
              <button className="button_fun" onClick={() => onFunctionAdd()}>
                <span className="btn-container">
                  <i className="fa fa-plus"></i>
                </span>
              </button>
            </div>
          )}
          <div className="customer_row">
            <button className="add_btn" onClick={handleAddFunction}>
              <span
                className={
                  isEditFunction ? "fa fa-pencil icon" : "fa fa-plus icon"
                }
              ></span>
              <span>{isEditFunction ? "Edit Function" : "Add Function"}</span>
            </button>
            <button className="add_btn ml-4" onClick={handleClearFunction}>
              <span className="fa fa-close icon"></span>
              <span>Clear Function</span>
            </button>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default AddFunction;
