import { Fragment, useContext, useEffect, useState } from "react";
import { ScheduleContext } from "../../../../Context/scheduleContext";
import { ColsPayOff } from "../../../../Helpers/columns";
import {
  detailTemplate,
  showAPIToast,
  showToast,
  TOAST,
} from "../../../../Helpers/ui-helper";
import { PoolDTO } from "../../../../Models/PoolsDTO";
import { FixedOddsManagementService } from "../../../../services/fixedOddsManagementService";
import PzTable from "../../../../UI-Components/Table/pzTable";
import warning from "../../../../assets/images/warning-icon.svg";
import { getLocalStorageObject } from "../../../../Helpers/valueHelper";
import { LOCAL_STORAGE } from "../../../../Helpers/constants";
import { UserDTO } from "../../../../Models/UserManagementDto";
import { ScheduleApiService } from "../../../../services/scheduleApiService";

interface IPoolCardsProps {
  toggleSidebar: any;
  connectorInfo: any;
}

export default function PoolCards(props: IPoolCardsProps) {
  const {
    togglePoolForm,
    racePoolsObj,
    setPools,
    selectedRace,
    setPoolToEdit,
  } = useContext(ScheduleContext);

  const [isFOPools, setIsFOPools] = useState(false);
  const [filteredPools, setFilteredPools] = useState<PoolDTO[]>([]);
  const _user = getLocalStorageObject(LOCAL_STORAGE.USER) as UserDTO;
  // const [reqObj, setReqObj] = useState<PoolRequestDTO | null>(null);

  useEffect(() => {
    const extractPayoff = () => {
      let pools = [...Object.values(racePoolsObj)] as PoolDTO[];
      setIsFOPools(false);
      setFilteredPools(pools);
    };

    if (racePoolsObj) extractPayoff();
    else {
      setFilteredPools([]);
      setIsFOPools(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [racePoolsObj]);

  const onEditPool = (pool: PoolDTO) => {
    let _pool = JSON.parse(JSON.stringify(pool));
    setPoolToEdit(_pool);
    togglePoolForm(true);
  };

  // Handle click on Displaya all pools buttons
  const onDisplayBtnClick = (display: boolean) => {
    FixedOddsManagementService.displayPools(display, selectedRace.raceKeyString)
      .then((res) => {
        if (res.status === 200) {
          let pools = [...Object.values(racePoolsObj)] as PoolDTO[];
          let poolList = pools;
          for (let pool of poolList) {
            if (
              pool.poolDetails.poolStatus !== "RESULTED" &&
              (pool.poolDetails.payoff === null ||
                Object.keys(pool.poolDetails.payoff ?? {}).length === 0)
            ) {
              if (display) pool.poolDetails.poolStatus = "OPEN";
              else pool.poolDetails.poolStatus = "NON_DISPLAY";
            }
          }
          setPools(pools);
          showToast(
            display
              ? "Pools displayed successfully!"
              : "Pool not displayed successfully!",
            TOAST.SUCCESS,
            "pool" + display
          );
        }
      })
      .catch((err) => {
        console.error(err);
        showAPIToast(err, "while updating display pools", "d_pools");
      });
  };

  // Filter FixedOdds pool on FO button click
  const filterFOPools = (isFO: boolean) => {
    if (isFO) {
      let poolsList: any = [...Object.values(racePoolsObj)].filter((x: any) =>
        x.poolDetails.poolKey.poolType.startsWith("FO_")
      );
      setFilteredPools(poolsList);
    } else {
      let poolList: any = Object.values(racePoolsObj);
      setFilteredPools(poolList);
    }
    setIsFOPools(isFO);
  };

  const DisplayBtnTemplates = () => {
    return (
      <div className="d-flex my-3 justify-content-between">
        {filteredPools.length > 0 && (
          <div>
            {!selectedRace.cancelled && (
              <>
                <button
                  className="display-btns"
                  onClick={() => onDisplayBtnClick(true)}
                >
                  <i className="fa fa-eye text-success"></i> &nbsp; Display All
                  Pools
                </button>

                <button
                  className="display-btns"
                  onClick={() => onDisplayBtnClick(false)}
                >
                  <i className="fa fa-eye-slash text-danger"></i> &nbsp;Not
                  Display All Pools
                </button>
              </>
            )}
          </div>
        )}
        {Object.values(racePoolsObj).length > 0 && (
          <div className="ml-auto">
            <button
              className={isFOPools ? "display-btns active" : "display-btns"}
              onClick={() => filterFOPools(!isFOPools)}
            >
              FO
            </button>
          </div>
        )}
      </div>
    );
  };

  const warningTemplate = (poolKey: string) => {
    let raceKey = selectedRace?.raceKeyString;
    if (raceKey) {
      let poolsObj = props.connectorInfo[raceKey]?.poolConnectorStatus[poolKey];

      if (poolsObj && poolsObj.connectionStatus !== "CONNECTED") {
        return (
          <img
            src={warning}
            style={{ height: 30, width: 30 }}
            alt="Connector Disconnected"
            title={`Connector is ${poolsObj?.connectionStatus}`}
          />
        );
      }
    }
  };

  const requestManagePool = (pool: PoolDTO) => {
    let user = getLocalStorageObject(LOCAL_STORAGE.USER) as UserDTO;
    if (!user) {
      return showToast("Session not found! Please login again!", TOAST.ERROR);
    }

    ScheduleApiService.managePoolRequest(pool.poolDetails.poolKey)
      .then((res) => {
        if (res.data) {
          // let req = res.data as PoolRequestDTO;
          // setReqObj(req);
        } else {
          // setReqObj(null);
        }
      })
      .catch((err) => {
        console.log(err);
        showAPIToast(err, "while requesting pool management", "pool_mng");
      });
  };

  const getReqButton = (pool: PoolDTO) => {
    if (_user.uid !== pool.poolConfig?.userId) {
      return (
        <div className="d-flex align-items-center">
          <button onClick={() => requestManagePool(pool)} className="btnreq">
            Request to manage
          </button>
        </div>
      );
    }
    return null;
  };

  // Pool Card UI
  const PoolCard = (props: { pool: PoolDTO }) => {
    let pool = props.pool;
    return (
      <div className="pool-card">
        <div className="pool-info">
          <h5 className="b-bottom pb-2 d-flex justify-content-between">
            <b>
              <em>
                {pool.poolDetails.poolKey.poolType} -{" "}
                <span className="h6"> {pool.poolDetails.poolKey.provider}</span>
              </em>
            </b>
          </h5>
          {detailTemplate([
            { label: "Currency", data: pool.poolDetails.currency },
            { label: "Provider", data: pool.poolDetails.poolKey.provider },
            { label: "Minimum Bet Amt.", data: pool.poolDetails.minBetAmt },
            { label: "Inc. Bet Amt.", data: pool.poolDetails.incBetAmt },
            { label: "Other Legs", data: pool.poolDetails.otherLegs },
            { label: "Status", data: pool.poolDetails.poolStatus },
          ])}
          <div className="pt-2 b-top d-flex justify-content-between align-items-center">
            <div>
              <button
                className="pz-btn icon-btn"
                onClick={() => onEditPool(pool)}
              >
                <i className="fa fa-pencil"></i>
              </button>
            </div>
            <div>
              {pool &&
                pool.poolDetails &&
                pool.poolDetails.poolKey.poolType.startsWith("FO_") &&
                pool.poolDetails.poolStatus !== "CLOSED" &&
                pool.poolDetails.poolStatus !== "RESULTED" &&
                !pool.poolDetails.cancelled &&
                (pool.poolDetails.payoff === null ||
                  Object.keys(pool.poolDetails.payoff ?? {}).length === 0) &&
                getReqButton(pool)}
            </div>
            {warningTemplate(props.pool.poolDetails.poolKeyString)}
            {pool.poolDetails.cancelled && (
              <div className="badge badge-danger">Cancelled</div>
            )}
          </div>
        </div>
        <div className="pool-payoff">
          <em>
            <div className="pb-2 b-bottom" style={{ fontWeight: 700 }}>
              {" "}
              Winner Pay Offs
            </div>
          </em>
          <div className="mt-2">
            <PzTable
              emptyMessage="No Payoffs Available!"
              data={pool.poolDetails.payOffList}
              columns={ColsPayOff}
            />
          </div>
        </div>
      </div>
    );
  };

  // const onTimerFinished = () => {
  //   setReqObj(null);
  // };

  // const timerTemplate = () => {
  //   if (reqObj?.duration && _user?.uid !== reqObj?.currentUserId) {
  //     return (
  //       <RequestTimer
  //         onTimerFinished={onTimerFinished}
  //         duration={reqObj.duration}
  //         reqObj={reqObj}
  //         pool={poolToEdit}
  //       />
  //     );
  //   }
  // };

  return (
    <>
      <div className="p-cards">
        <div className="pc-header">
          <span>Pools</span>
          <button
            className="pz-btn icon-btn"
            onClick={() => props.toggleSidebar(false)}
          >
            <i className="fa fa-times"></i>
          </button>
        </div>
        <DisplayBtnTemplates />
        {racePoolsObj.length === 0 && (
          <div className="text-secondary text-center h3 font-weight-bold font-italic">
            {" "}
            Pools not available. <br /> Please add any pool.
          </div>
        )}
        {filteredPools.map((_pool, index) => {
          return (
            <Fragment key={`${_pool.poolDetails.poolKeyString} - ${index}`}>
              <PoolCard key={index} pool={_pool} />
            </Fragment>
          );
        })}
      </div>
      {/* {timerTemplate()}  */}
    </>
  );
}
