import React, { useEffect } from "react";
import { useContext } from "react";
import { ScheduleContext } from "../../../Context/scheduleContext";
import { PoolDTO } from "../../../Models/PoolsDTO";
import "./poolsListComponent.scss";

export interface IPoolsListComponentProps {
  setIsFormVisible: any;
  setIsPoolsOpen: any;
  setPoolList?: any;
}

export default function PoolsListComponent(props: IPoolsListComponentProps) {
  const { togglePoolForm, setPoolToEdit, racePoolsObj } =
    useContext(ScheduleContext);

  const _pools = Object.values(racePoolsObj ?? {}) as PoolDTO[];
  let _poolsSort = _pools.sort((a, b) =>
    a.poolDetails?.poolKey?.poolType > b.poolDetails?.poolKey?.poolType ? -1 : 1
  );

  useEffect(() => {
    if (_poolsSort) {
      props.setPoolList(_poolsSort);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [racePoolsObj]);

  const onEditPool = (pool: PoolDTO) => {
    setPoolToEdit(pool);
    togglePoolForm(true);
    props.setIsPoolsOpen(true);
  };

  return (
    <>
      {_poolsSort.length > 0 && (
        <div
          style={{
            marginLeft: "120px",
            display: "flex",
            flexWrap: "wrap",
            marginTop: "auto",
          }}
        >
          {_poolsSort.map((pool, index) => {
            return (
              <div className="poolNamebtnCont" key={index}>
                <button className="btn" onClick={() => onEditPool(pool)}>
                {pool.poolDetails.poolStatus === 'OPEN'?<i className="fa fa-eye text-success"></i>:<i className="fa fa-eye text-danger"></i>}&nbsp;&nbsp;
                  {pool.poolDetails?.poolKey.poolType}
                </button>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
