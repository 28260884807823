import {  useState } from "react";
import {
  ActivePoolBetDTO,
  ActiveRaceDTO,
} from "../../../../Models/activeRaceDTO";
import "./raceWidget.scss";
import { getDynamicImage } from "../../../../Helpers/imagesImport";
//@ts-ignore
import ReactCountryFlag from "react-country-flag";
import PzCheckBox from "../../../../UI-Components/Inputs/checkBoxInput";
import { LOCAL_STORAGE, ROUTES } from "../../../../Helpers/constants";
import TimerPostTime from "../../../../UI-Components/Timers/TimerPostTime/timerPostTime";
import { useHistory } from "react-router";

export default function RaceWidgets(props: { races: any }) {
  
  const history = useHistory();
  const [showPools, setShowPools] = useState<boolean>(false);

  const openRaceInSchedule = (race:any) => {
    localStorage.setItem(LOCAL_STORAGE.SELECTEDRACEKEY, JSON.stringify(race.raceKey));
    history.push(ROUTES.SCHEDULE);
  }

  const poolTemplates = (race: ActiveRaceDTO) => {
    if (showPools) {
      let pools = Object.values(race.poolLb) as ActivePoolBetDTO[];
      if (!pools.length)
        return (
          <div className="p-2 text-muted font-italic font-weight-bold text-center d-flex justify-content-center align-items-center pools-container">
            {" "}
            Pools Data not available for this race!{" "}
          </div>
        );
      return (
        <div>
          {pools.map((pool) => {
            return (
              <div key={pool.poolKeyString} className="pools-container">
                <div className="pools-header">{pool.poolKey.poolType}</div>
                <div className="pools-body">
                  <div className="pools-tab">
                    <div className="p-h">Bets</div>
                    <div className="p-b">{pool.bets}</div>
                  </div>
                  <div className="pools-tab">
                    <div className="p-h">Investment</div>
                    <div className="p-b">{pool.investment}</div>
                  </div>
                  <div className="pools-tab">
                    <div className="p-h">Used LB</div>
                    <div className="p-b">{pool.usedLb}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  var _races = Object.values(props.races ?? {}) as ActiveRaceDTO[];
  return (
    <div className="active-race-container">
      <div className="a-race-filter p-2">

      <span className="name">Upcoming Races</span>
        <PzCheckBox
          value={showPools}
          label="Show Pools"
          className="m-0 d-inline-flex"
          onChange={(e: any) => setShowPools(e.value)}
        />
      </div>

      <div className="a-race-grid">
        {_races.map((race) => {
          return (
            <div key={race.raceKeyString} className="a-race-card">
              <div className="arc-head">
                <div className="d-flex align-items-center">
                  <img
                    className="event-img"
                    src={getDynamicImage(race.eventType)}
                    alt={race.eventType}
                  />
                  <span className="name">
                    {race.trackName} - {race.raceKey?.raceNo}
                  </span>
                </div>
                <div>
                  <ReactCountryFlag
                    style={{
                      fontSize: "2.25em",
                      marginRight: "0.5rem",
                      borderRadius: "0.5rem",
                    }}
                    svg
                    aria-label={race.province}
                    className="emojiFlag"
                    countryCode={race.province}
                    title={race.province}
                  />
                </div>
              </div>
              <div className="arc-body">
                <div className="data-tabs">
                  <div className="tab">
                    <div className="tb-head">Bets</div>
                    <div className="tb-body">
                      {race.bets ?? 0}
                    </div>
                  </div>
                  <div className="tab">
                    <div className="tb-head">Investment</div>
                    <div className="tb-body">
                      {race.investment?.toFixed(2) ?? 0.0}
                    </div>
                  </div>
                  <div className="tab">
                    <div className="tb-head">Used LB</div>
                    <div className="tb-body">
                      {race.usedLb?.toFixed(2) ?? 0}
                    </div>
                  </div>
                </div>
                {poolTemplates(race)}
              </div>

              <div className="arc-footer">
                <TimerPostTime
                  key={race.raceKeyString}
                  postTimeString={race.postTime}
                  timeZone={race.timezone}
                />
                <button
                  // onClick={() => appContext.openRace(ROUTES.SCHEDULE, race)}
                  onClick={() => openRaceInSchedule(race)}
                  className="op-btn"
                  title="Open race in schedule management"
                >
                  <span className="fa fa-external-link"></span>
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}