import { TableColumnsDTO } from "../../Helpers/columns";
import "./pzTable.scss";

export interface IPayOffTableProps {
  data: any[];
  columns: TableColumnsDTO[];
  isEditAction?: boolean;
  onEditClick?: any;
  onDeleteClick?: any;
  emptyMessage?: string;
  onRowDoubleClick?: any;
}

export default function PayOffTable(props: IPayOffTableProps) {
  if (props.data)
    return (
      <table className="pz-table">
        <thead>
          <tr>
            {props.isEditAction && <th style={{ width: "7rem" }}>Actions</th>}
            {props.columns.map((col) => {
              return (
                <th key={col.field} style={{ width: col.width }}>
                  {col.header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {props.data.map((rowData, index) => {
            return (
              <tr key={index}>
                {props.isEditAction && (
                  <td>
                    <button
                      onClick={() => props.onDeleteClick(index)}
                      className="pz-btn icon-btn mr-2"
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                    <button
                      onClick={() => props.onEditClick(rowData, index)}
                      className="pz-btn icon-btn"
                    >
                      <i className="fa fa-pencil"></i>
                    </button>
                  </td>
                )}
                {props.columns.map((col: any) => {
                  return <td key={col.field}>{rowData[col.field]}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  return <div className="text-muted h5 m-auto p-3">{props.emptyMessage}</div>;
}
