import React, { useState, useEffect } from "react";
import ViewSearchCustomer from "../ViewSearchCustomer/ViewSearchCustomer";
import { UserApiService } from "../../../../services/userApiService";
import { showToast, TOAST } from "../../../../Helpers/ui-helper";
import ViewCustomerSummary from "../ViewCustomerSummary/ViewCustomerSummary";

export enum CustomerScreenType {
  SEARCH_CUSTOMER,
  VIEW_CUSTOMER,
}

const ActiveCustomer = () => {
  // variables
  const [isLoading, setIsLoading] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [activeCustomerSession, setActiveCustomerSession] = useState<any[]>([]);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
  });
  const [selectedCustomer, setSelectedCustomer] = useState<any>({});
  const [customerScreen, setCustomerScreen] = useState(
    CustomerScreenType.SEARCH_CUSTOMER
  );

  // callbacks
  useEffect(() => {
    getSessionIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // functions
  const changeScreen = (screen: number) => {
    if (screen === 1) {
      setCustomerScreen(CustomerScreenType.VIEW_CUSTOMER);
    } else {
      setCustomerScreen(CustomerScreenType.SEARCH_CUSTOMER);
    }
  };

  const getSessionIds = () => {
    setIsLoading(true);
    UserApiService.getActiveCustomersSession()
      .then((res) => {
        if (res && res.data) {
          let sessionIds = res.data;
          if (sessionIds) {
            let fIndex = 0;
            let lIndex = fIndex + lazyParams.rows;
            getCustomersData(sessionIds.slice(fIndex, lIndex));
          }
          setActiveCustomerSession(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err.response?.data?.message, TOAST.ERROR);
      });
  };

  const getCustomersData = (sessionIds: string[]) => {
    if (sessionIds.length === 0) return;
    setIsLoading(true);
    UserApiService.getCustomers(sessionIds)
      .then((res) => {
        if (res && res.data) {
          let list: any = [];
          for (const key of res.data) {
            let obj: any = {};
            obj["customerKey"] = key.customerKey.customerKey;
            obj["email"] = key.email;
            obj["phoneNo"] = key.phoneNo;
            obj["customerName"] = key.customerName;
            list.push(obj);
          }
          setCustomerData(list);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err.response["data"].message, TOAST.ERROR);
      });
  };

  const onPage = (event: any) => {
    let values = { ...lazyParams };
    let fIndex = (event.page + 1) * event.rows - event.rows;
    let data = [...activeCustomerSession];
    let lIndex = fIndex + event.rows;
    getCustomersData(data.slice(fIndex, lIndex));
    values = {
      first: event.first,
      rows: event.rows,
      page: event.page + 1,
    };
    setLazyParams(values);
  };

  const openCustomerPanel = (rowData: any) => {
    setSelectedCustomer(rowData);
    changeScreen(CustomerScreenType.VIEW_CUSTOMER);
  };

  const ScreenTemplate = () => {
    switch (customerScreen) {
      case CustomerScreenType.SEARCH_CUSTOMER:
        return (
          <ViewSearchCustomer
            customerData={customerData}
            isLoading={isLoading}
            activeCustomerSession={activeCustomerSession}
            customerType="active"
            getSessionIds={getSessionIds}
            onPage={onPage}
            openCustomerPanel={openCustomerPanel}
            lazyParams={lazyParams}
          />
        );
      case CustomerScreenType.VIEW_CUSTOMER:
        return (
          <ViewCustomerSummary
            changeScreen={changeScreen}
            selectedCustomer={selectedCustomer}
          />
        );
      default:
        break;
    }
  };

  return <div>{ScreenTemplate()}</div>;
};

export default ActiveCustomer;
