import { useEffect, useRef, useState } from "react";
import {
  FORMAT,
  LOCAL_STORAGE,
  ROUTES,
  SERVER,
} from "../../../Helpers/constants";
import { getDynamicImage } from "../../../Helpers/imagesImport";
import { findIndex } from "../../../Helpers/list-helpers";
import {
  getLocalStorageObject,
  getRaceKeyStr,
  getTrackName,
  raceKeySplit,
  setLocalStorage,
} from "../../../Helpers/valueHelper";
import { RaceDetailsDTO } from "../../../Models/RaceDetailsDTO";
import { JWTResponseDTO } from "../../../Models/UserManagementDto";
import InlineLoader from "../../../UI-Components/Loader/inline-loader";
import warning from "../../../assets/images/warning-icon.svg";
import "./raceSchedular.scss";
import { format } from "date-fns";
import {
  CLOG,
  showAPIToast,
  showToast,
  TOAST,
} from "../../../Helpers/ui-helper";
import { Calendar } from "primereact/calendar";
import {
  getPostTimeFormat,
  getStringToDate,
} from "../../../Helpers/dateTimeHelpers";
import { RaceTripDTO } from "../../../Models/raceTripsDTO";
import { PoolDTO } from "../../../Models/PoolsDTO";
//@ts-ignore
import ReactCountryFlag from "react-country-flag";
import { ScheduleApiService } from "../../../services/scheduleApiService";
import { TrackRacesDTO } from "../../../Models/trackwiseRacesDTO";

export interface IRaceSchedularProps {
  updateRaceDetails: (race: RaceDetailsDTO) => void;

  updateRaceTrips: (raceTrip: RaceTripDTO, raceKey: string) => void;

  updatePoolData: (pool: PoolDTO, raceKey: string) => void;

  //FIXME CHANGE TO CONNECTOR WARNING
  raceConnectorInfo?: any;
  setRaceConnectorInfo?: (connectorWarning: any) => void;

  //FIXME REMOVE FROM HEARE
  isSelectedTrackwisePools?: any;
  setIsSelectedTrackwisePools?: any;

  selectedRace: any;
  onRaceClick: (race: RaceDetailsDTO) => void;

  selectedTrack: TrackRacesDTO;
  setSelectedTrack: (track: TrackRacesDTO) => void;
}

enum RaceTypes {
  UPCOMING,
  RESULTED,
}

var search_string: string;

export default function RaceSchedular(props: IRaceSchedularProps) {
  const [selectedDate, setSelectedDate] = useState({ date: new Date() });

  //FILTER
  const [provinceEventListFilter, setProvinceEventListFilter] = useState({
    selectedEvent: "ALL",
    selectedTrackType: RaceTypes.UPCOMING,
    selectedCountries: [] as string[],
  });

  const [searchString, setSearchString] = useState("");

  //Events and Country List
  const [provinceEventListData, setProvinceEventListData] = useState({
    eventTypes: [] as string[],
    countryCodes: [] as string[],
  });

  //boolean
  const [scheduleToggle, setScheduleToggle] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [waitingToReconnect, setWaitingToReconnect] = useState<Boolean>(true);
  const selectedRace = useRef<RaceDetailsDTO>();
  const selectedTrack = useRef<TrackRacesDTO>();
  const tracksObj = useRef<any>();

  const [isFixedOdds, setIsFixedOdds] = useState(
    window.location.pathname === ROUTES.FIXED_ODDS_MANAGEMENT
  );

  const [filteredTrackList, setFilteredTrackList] = useState<TrackRacesDTO[]>(
    []
  );

  const [connectorInfo, setConnectorInfo] = useState<any>(null);

  const wsAllRace = useRef<WebSocket | null>(null);

  const isSocketConnect = useRef<Boolean>(false);
  const interval = useRef<any>(null);

  useEffect(() => {
    selectedRace.current = props.selectedRace;
  }, [props.selectedRace]);

  useEffect(() => {
    selectedTrack.current = props.selectedTrack;
  }, [props.selectedTrack]);

  useEffect(() => {
    let selected = getLocalStorageObject(LOCAL_STORAGE.RM_SEL_EVENTTYPE);
    if (selected) setProvinceEventListFilter(selected);
    else {
      let data = { ...provinceEventListFilter };
      setProvinceEventListFilter({ ...provinceEventListFilter });
      setLocalStorage(LOCAL_STORAGE.RM_SEL_EVENTTYPE, JSON.stringify(data));
    }
    setIsFixedOdds(window.location.pathname === ROUTES.FIXED_ODDS_MANAGEMENT);
    let rKey = localStorage.getItem(LOCAL_STORAGE.SELECTEDRACEKEY);
    if (rKey) {
      let navigationRaceKey = JSON.parse(rKey);
      let localDate = navigationRaceKey.localDate;
      let date = getStringToDate(localDate.toString());
      onChangeDate(date);
    } else {
      onChangeDate(new Date());
    }

    return () => {
      localStorage.removeItem(LOCAL_STORAGE.RM_SEL_EVENTTYPE);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    if (!waitingToReconnect) {
      return;
    }

    if (!wsAllRace.current) {
      let jwtData: JWTResponseDTO = getLocalStorageObject(LOCAL_STORAGE.JWT);
      if (!jwtData || !jwtData.token) {

        return;
      }
      let _date = { ...selectedDate };
      let socketUrl = SERVER.WS_URL + "todayRaces?token=" + jwtData.token;


      const client = new WebSocket(socketUrl);
      wsAllRace.current = client;
      wsAllRace.current.onopen = () => {
        console.log('ws connected');
        setIsLoading(false);
        getAllRaceData(_date.date);
        // getRacePools(selectedRaceRef.current?.raceKeyString);
      }

      wsAllRace.current.onmessage = (e: any) => {
        let message = JSON.parse(e.data);
        extractScheduleMessage(message);

      }
      wsAllRace.current.onclose = () => {

        if (wsAllRace.current) {
          // Connection failed
          setIsLoading(true);
          console.log('ws closed by server');
        } else {
          setIsLoading(false);
          setWaitingToReconnect(false);
          // Cleanup initiated from app side, can return here, to not attempt a reconnect
          console.log('ws closed by app component unmount');
          return;
        }

        // if (waitingToReconnect) {
        //   return;
        // };

        // Setting this will trigger a re-run of the effect,
        // cleaning up the current websocket, but not setting
        // up a new one right away
        setWaitingToReconnect(false);

        // This will trigger another re-run, and because it is false,
        // the socket will be set up again
        setTimeout(() => setWaitingToReconnect(true), 500);

      }
      return () => {

        console.log('Cleanup');
        // Dereference, so it will set up next time
        wsAllRace.current = null;

        client.close();
      }
    }
  }, [waitingToReconnect]);

  useEffect(() => {
    getConnectorStatus();
    //setWaitingToReconnect(true);
    // const listneOnWs = () => {
    //   let _date = { ...selectedDate };
    //   let jwtData: JWTResponseDTO = getLocalStorageObject(LOCAL_STORAGE.JWT);
    //   let socketUrl = SERVER.WS_URL + "todayRaces?token=" + jwtData.token;
    //   if (
    //     wsAllRace.current &&
    //     wsAllRace?.current?.readyState === WebSocket.OPEN
    //   )
    //     wsAllRace.current?.close();

    //   wsAllRace.current = new WebSocket(socketUrl);
    //   wsAllRace.current.onopen = () => {
    //     isSocketConnect.current = true;
    //     if (interval.current) {
    //       clearInterval(interval.current);
    //     }
    //     getAllRaceData(_date.date);
    //   };
    //   wsAllRace.current.onclose = () => {
    //     CLOG("___WSC____TODAYRACES SOCKET CLOSED____", "red");
    //     // reConnectSocket();
    //     if (isSocketConnect.current) {
    //       isSocketConnect.current = false;
    //       console.info("-o-o-o-o- RACEPOOLS SOCKET RECONNECTING IN 2 SECONDS");
    //       setIsLoading(true);
    //       if (interval.current) {
    //         clearInterval(interval.current);
    //       }
    //       interval.current = setInterval(() => {
    //         if (!isSocketConnect.current) listneOnWs();
    //       }, 2000);
    //     }
    //   };

    //   wsAllRace.current.onmessage = (e: any) => {
    //     let message = JSON.parse(e.data);
    //     extractScheduleMessage(message);
    //   };
    // };

    // if (!wsAllRace || wsAllRace?.current?.readyState !== WebSocket.OPEN) {
    //   listneOnWs();
    // }
    // return () => {
    //   isSocketConnect.current = false;
    //   wsAllRace?.current?.close();
    //   if (interval.current) {
    //     clearInterval(interval.current);
    //   }
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getConnectorStatus = (data?: any) => {
    if (data) {
      setConnectorInfo(data);
      if (!isFixedOdds) {
        props.setRaceConnectorInfo && props.setRaceConnectorInfo(data);
      }
    } else {
      ScheduleApiService.getConnectorInfo()
        .then((res) => {
          if (res.data) {
            setTimeout(() => {
              console.log("timeOut called...!");
              setConnectorInfo(res.data);
              if (!isFixedOdds) {
                props.setRaceConnectorInfo &&
                  props.setRaceConnectorInfo(res.data);
              }
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const updateTrackByFilter = (
    tracksObj: any,
    event: string,
    trackType: any,
    searchVal?: string
  ) => {
    let tracks: TrackRacesDTO[] = Object.values(tracksObj);
    if (tracks) {
      let list = [];

      for (const track of tracks) {
        if (event !== "ALL" && track.eventType !== event) {
          continue;
        }
        if (
          trackType === RaceTypes.UPCOMING &&
          track.trackStatus === "RESULTED"
        ) {
          continue;
        }
        if (
          trackType === RaceTypes.RESULTED &&
          track.trackStatus !== "RESULTED"
        ) {
          continue;
        }
        if (
          searchVal &&
          !track.trackName.toLowerCase().includes(searchVal.toLowerCase())
        ) {
          continue;
        }
        list.push(track);
      }
      return list.sort((a, b) => (a.trackName > b.trackName ? 1 : -1));
    }
  };

  // Get all race data
  const getAllRaceData = (dateObj: Date) => {
    let date = format(dateObj, FORMAT.yyyyMMdd);
    setIsLoading(true);
    ScheduleApiService.getScheduleRaces(date)
      .then((res) => {
        if (res?.data?.length) {
          let raceDetails = res.data as RaceDetailsDTO[];
          CLOG("=> API___TODAY RACES FETCH___", "purple");
          setTrackListData(raceDetails, dateObj);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        if (err.response?.data?.statusCode === 401) {
          showToast("Session Expired! Please Login Again!", TOAST.ERROR);
          window.location.href = ROUTES.LOGIN;
          return;
        }
        showAPIToast(err, "while fetching races data", "race_schedular");
        setIsLoading(false);
      });
  };

  const onTrackSelect = (track: TrackRacesDTO) => {
    if (!isFixedOdds) {
      props.setIsSelectedTrackwisePools(false);
    }
    trackWiseSetRaces(track);
  };

  const trackWiseSetRaces = (track: TrackRacesDTO) => {
    props.setSelectedTrack({ ...track });
  };

  //setTrack Details
  const setTrackListData = (racesInfo: RaceDetailsDTO[], date: Date) => {
    let _lists: any = { ...provinceEventListData };
    let _selected: any = getLocalStorageObject(LOCAL_STORAGE.RM_SEL_EVENTTYPE);

    let eventTypeKeys = new Set();
    let countryCodes = new Set();

    let trackObj: any = {};
    for (const race of racesInfo) {
      let _race: RaceDetailsDTO = convertRace(date, race) as RaceDetailsDTO;

      if (_race) {
        eventTypeKeys.add(_race.eventType);
        countryCodes.add(_race.country);
        let trackCode = _race.raceKey.localDate + _race.raceKey.pzoneCode;

        if (trackObj[trackCode]) {
          trackObj[trackCode]["races"][_race.raceKeyString] = _race;
        } else {
          trackObj[trackCode] = {} as any;
          trackObj[trackCode]["races"] = {} as any;
          trackObj[trackCode]["pzoneCode"] = _race.raceKey.pzoneCode;
          trackObj[trackCode]["trackName"] = getTrackName(
            _race.raceKey.pzoneCode
          );
          trackObj[trackCode]["trackStatus"] = _race.raceStatus;
          trackObj[trackCode]["postTime"] = _race.formattedPosttime;
          trackObj[trackCode]["eventType"] = _race.eventType;
          trackObj[trackCode]["countryType"] = _race.country;
          trackObj[trackCode]["tc"] = trackCode;
          trackObj[trackCode]["races"][_race.raceKeyString] = _race;
        }
      }
    }
    tracksObj.current = trackObj;

    checkStatusData(tracksObj.current);

    let rKey1 = localStorage.getItem(LOCAL_STORAGE.SELECTEDRACEKEY);
    if (rKey1) {
      let navigationRaceKey = JSON.parse(rKey1);
      let trackCo = navigationRaceKey.localDate + navigationRaceKey.pzoneCode;
      _selected.selectedTrackType =
        tracksObj?.current[trackCo]?.trackStatus === "RESULTED"
          ? RaceTypes.RESULTED
          : RaceTypes.UPCOMING;
    }
    // if(!_selected.selectedTrackType) return;/
    let tList: any = updateTrackByFilter(
      trackObj,
      _selected?.selectedEvent,
      _selected?.selectedTrackType
    );
    setFilteredTrackList(tList);
    _lists.eventTypes = [...Array.from(eventTypeKeys)].sort() as string[];
    _lists.countryCodes = [...Array.from(countryCodes)].sort() as string[];
    setProvinceEventListData(_lists);

    setLocalStorage(LOCAL_STORAGE.RM_SEL_EVENTTYPE, JSON.stringify(_selected));
    setProvinceEventListFilter(_selected);
    let rKey = localStorage.getItem(LOCAL_STORAGE.SELECTEDRACEKEY);
    if (rKey) {
      let navigationRaceKey = JSON.parse(rKey);
      let datas: TrackRacesDTO[] = Object.values(tList);
      let track = datas.filter(
        (x) =>
          x.tc === navigationRaceKey.localDate + navigationRaceKey.pzoneCode
      )[0];
      trackWiseSetRaces(track);
    } else {
      trackWiseSetRaces(tList[0]);
    }
    setIsLoading(false);
  };

  const checkStatusData = (trackObj: any) => {
    for (const trackCode in trackObj) {
      let races: RaceDetailsDTO[] = Object.values(trackObj[trackCode].races);
      let validTrackResult = true;
      for (const _race of races) {
        if (_race.foGenerated === true) {
          trackObj[trackCode]["isFo"] = true;
        }
        if (_race.raceStatus !== "RESULTED" && _race.raceStatus !== "CLOSED") {
          validTrackResult = false;
          trackObj[trackCode]["trackStatus"] = _race.raceStatus;
          trackObj[trackCode]["raceNo"] = _race.raceKey.raceNo;
          trackObj[trackCode]["postTime"] = _race.formattedPosttime;
          break;
        }
      }
      if (validTrackResult) {
        let race = races[races.length - 1];
        trackObj[trackCode]["trackStatus"] = race.raceStatus;
        trackObj[trackCode]["raceNo"] = race.raceKey.raceNo;
        trackObj[trackCode]["postTime"] = race.formattedPosttime;
      }
    }
  };
  // Extract message from websocket
  const extractScheduleMessage = (data: any) => {
    switch (data.messageType) {
      case "RACE":
        let raceData = data.msg as RaceDetailsDTO;
        raceData.raceKeyString = data.raceKey;
        updateRaceData(raceData);
        break;
      case "TRIP":
        let raceTrip = data.msg as RaceTripDTO;
        if (data.raceKey === selectedRace.current?.raceKeyString)
          props.updateRaceTrips(raceTrip, data.raceKey);
        break;
      case "POOL":
        let poolData = data.msg as PoolDTO;
        if (data.raceKey === selectedRace.current?.raceKeyString)
          // if (!isFixedOdds)
          props.updatePoolData(poolData, data.raceKey);
        break;
      case "CONNECTOR_MSG":
        if (!isFixedOdds && props.setRaceConnectorInfo)
          getConnectorStatus(data.msg);
        // props.setRaceConnectorInfo(data.msg);
        // if (!isFixedOdds) props.updateConnectorInfo(data.msg);
        break;
      default:
        break;
    }
  };

  const convertRace = (selectedDate: Date, race: RaceDetailsDTO) => {
    if (isFixedOdds && !race.foGenerated) {
      return null;
    }
    if (!race.postTime || !race.timezoneId) {
      return null;
    }
    race.raceKeyString = getRaceKeyStr(race.raceKey);
    race.country = raceKeySplit(race.raceKey.pzoneCode, "-", 0);
    race.eventType = raceKeySplit(race.raceKey.pzoneCode, "-", 2);

    race.formattedPosttime = getPostTimeFormat(
      race.timezoneId,
      race.postTime,
      FORMAT.ddMMyyyyHHmmss_DASH
    );

    let postTime = new Date(
      Number(
        getPostTimeFormat(race.timezoneId, race.postTime, FORMAT.yyyyMMddHHmmss)
      )
    );

    race.localPostTime = postTime.getTime();
    let selDate = format(selectedDate, FORMAT.yyyyMMdd);
    // let postDate = race.localPostTime.toString().slice(0, 8);
    // if (selDate !== postDate) {
    //   return null;
    // }

    if (Number(selDate) !== race.raceKey.localDate) {
      return null;
    }
    race.trackName = getTrackName(race.raceKey.pzoneCode);
    return race;
  };

  const getSelectedTypes = () => {
    let _selected: any = getLocalStorageObject(LOCAL_STORAGE.RM_SEL_EVENTTYPE);

    if (!_selected) {
      _selected = {};
      _selected["selectedEvent"] = "ALL";
      _selected["selectedTrackType"] = RaceTypes.UPCOMING;
      _selected["selectedCountries"] = [] as string[];
    } else if (!_selected["selectedEvent"]) {
      _selected["selectedEvent"] = "ALL";
    } else if (!_selected["selectedTrackType"]) {
      _selected["selectedTrackType"] = RaceTypes.UPCOMING;
    } else if (!_selected["selectedCountries"]) {
      _selected["selectedCountries"] = [] as string[];
    }

    setProvinceEventListFilter({ ..._selected });
    return _selected;
  };

  // Update race data from websocket
  const updateRaceData = (raceData: RaceDetailsDTO) => {
    let _selected: any = getSelectedTypes();
    let _date = { ...selectedDate };
    let race = convertRace(_date.date, raceData);

    if (race) {
      let trackCode = race.raceKey.localDate + race.raceKey.pzoneCode;
      let trackObjTemp = { ...tracksObj.current };
      if (trackObjTemp[trackCode]) {
        trackObjTemp[trackCode]["races"][race.raceKeyString ?? ""] = race;
      } else {
        trackObjTemp[trackCode] = {} as any;
        trackObjTemp[trackCode]["races"] = {} as any;
        trackObjTemp[trackCode]["races"][race.raceKeyString ?? ""] = race;
        trackObjTemp[trackCode]["pzoneCode"] = race.raceKey.pzoneCode;
        trackObjTemp[trackCode]["tc"] = trackCode;
        trackObjTemp[trackCode]["trackName"] = getTrackName(
          race.raceKey.pzoneCode
        );
        trackObjTemp[trackCode]["trackStatus"] = race.raceStatus;
        trackObjTemp[trackCode]["postTime"] = race.formattedPosttime;
        trackObjTemp[trackCode]["eventType"] = race.eventType;
        trackObjTemp[trackCode]["countryType"] = race.country;
      }
      tracksObj.current = trackObjTemp;
      console.log("************************88");

      //fixme not update all track status

      checkStatusData(trackObjTemp);

      let tList: any = updateTrackByFilter(
        trackObjTemp,
        _selected?.selectedEvent,
        _selected.selectedTrackType,
        search_string
      );

      setFilteredTrackList(tList);

      if (selectedTrack.current?.pzoneCode === race.raceKey.pzoneCode) {
        let trackNewData = trackObjTemp[trackCode];
        // onTrackSelect(trackNewData);
        trackWiseSetRaces(trackNewData);
        if (selectedRace.current?.raceKeyString === race.raceKeyString)
          props.updateRaceDetails({ ...race });
      }
    }
  };

  // Filter races on meeting click
  const onEventTypeClick = (event: any): void => {
    let _selected: any = getSelectedTypes();

    if (event === _selected.selectedEvent) return;
    _selected.selectedEvent = event;

    let tList: any = updateTrackByFilter(
      tracksObj.current,
      event,
      _selected.selectedTrackType
    );
    setFilteredTrackList(tList);
    setLocalStorage(LOCAL_STORAGE.RM_SEL_EVENTTYPE, JSON.stringify(_selected));
    setProvinceEventListFilter(_selected);
    search_string = "";
    setSearchString("");
  };

  // Manage country filter array
  const onCountrySelect = (country: string) => {
    let _selected: any = getSelectedTypes();

    let index = findIndex(_selected.selectedCountries, country);
    if (index === -1) {
      _selected.selectedCountries.push(country);
    } else {
      _selected.selectedCountries.splice(index, 1);
    }
    searchTracks({ target: { value: "" } });
    search_string = "";
    setSearchString("");
    setLocalStorage(LOCAL_STORAGE.RM_SEL_EVENTTYPE, JSON.stringify(_selected));
    setProvinceEventListFilter(_selected);
  };

  // Upcoming and resulted race type clicks
  const onRaceTypeClick = (type: RaceTypes) => {
    let _selected: any = getSelectedTypes();

    _selected.selectedTrackType = type;
    let tList: any = updateTrackByFilter(
      tracksObj.current,
      _selected.selectedEvent,
      type,
      search_string
    );
    setFilteredTrackList(tList);
    console.log("On Race Type Click", tList);
    setLocalStorage(LOCAL_STORAGE.RM_SEL_EVENTTYPE, JSON.stringify(_selected));
    setProvinceEventListFilter(_selected);
  };

  const countryFilterTemplate = () => {
    return (
      <div
        className="country-filter"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>
          {provinceEventListData.countryCodes?.map((country) => {
            return (
              <button
                key={country}
                onClick={() => onCountrySelect(country)}
                className={
                  provinceEventListFilter.selectedCountries.includes(country)
                    ? "active"
                    : ""
                }
                style={{ fontWeight: 700 }}
              >
                <ReactCountryFlag
                  style={{
                    fontSize: "1.2em",
                  }}
                  svg
                  aria-label={country}
                  className="emojiFlag"
                  countryCode={country}
                  title={country}
                />
                &nbsp;&nbsp; {country}
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  const onChangeDate = (date: Date) => {
    if (date) {
      setSelectedDate({ date: date });
      getAllRaceData(date);
    }
  };

  const dateFilterTemplate = () => {
    return (
      <div className="calender-container">
        <Calendar
          className="calender"
          onChange={(e: any) => onChangeDate(e.target.value)}
          value={selectedDate.date}
          dateFormat="dd / mm / yy"
        ></Calendar>
        <div className="racetype-btns">
          <button
            className={
              provinceEventListFilter.selectedTrackType === RaceTypes.UPCOMING
                ? "active-btn"
                : ""
            }
            onClick={() => onRaceTypeClick(RaceTypes.UPCOMING)}
          >
            Upcoming Tracks
          </button>
          <button
            className={
              provinceEventListFilter.selectedTrackType === RaceTypes.RESULTED
                ? "active-btn"
                : ""
            }
            onClick={() => onRaceTypeClick(RaceTypes.RESULTED)}
          >
            Resulted Tracks
          </button>
        </div>
      </div>
    );
  };
  const searchTracks = (e: any) => {
    let _selected: any = getLocalStorageObject(LOCAL_STORAGE.RM_SEL_EVENTTYPE);

    let searchValue = e.target.value;
    search_string = searchValue;
    setSearchString(searchValue);
    const filteredData = Object.values(tracksObj.current).filter(
      (value: any) => {
        return value.trackName
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      }
    );
    let tList = updateTrackByFilter(
      filteredData,
      _selected.selectedEvent,
      _selected.selectedTrackType,
      searchValue
    ) as TrackRacesDTO[];
    setFilteredTrackList(tList);
  };

  const warningTemplate = (track: TrackRacesDTO) => {
    let races: RaceDetailsDTO[] = Object.values(track.races);
    if (races) {
      let _connectorInfo = { ...connectorInfo };
      for (const raceObj of races) {
        if (
          _connectorInfo[raceObj.raceKeyString] &&
          _connectorInfo[raceObj.raceKeyString].status !== "CONNECTED"
        ) {
          return (
            <img
              src={warning}
              style={{ height: 20, width: 30 }}
              alt="Connector Disconnected"
              title={`Connector is ${_connectorInfo[raceObj.raceKeyString].status
                }`}
            />
          );
        }
      }
    }
  };

  const raceCardTemplate = (track: TrackRacesDTO) => {
    return (
      <button
        onClick={() => onTrackSelect(track)}
        className={
          props.selectedTrack?.pzoneCode === track.pzoneCode
            ? "race-card active-card"
            : "race-card"
        }
        key={`${track.pzoneCode} - ${track.postTime}`}
        id={track.pzoneCode}
      // ref={scrollref.current[track.tc]}
      >
        <div className="d-row">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={`${getDynamicImage(track.eventType)}`}
              alt={track.eventType}
              style={{ width: "25px", height: "20px" }}
            />
            &nbsp;&nbsp;
            <span className="trackName">{getTrackName(track.pzoneCode)}</span>
          </div>
          <div className="country-flag">
            <ReactCountryFlag
              style={{
                fontSize: "2em",
              }}
              svg
              aria-label={raceKeySplit(track.pzoneCode, "-", 0)}
              className="emojiFlag"
              countryCode={raceKeySplit(track.pzoneCode, "-", 0)}
              title={raceKeySplit(track.pzoneCode, "-", 0)}
            />
          </div>
        </div>
        <div className="d-row align-items-end p-0">
          <span className="p-2">
            {" "}
            <span className="text-muted">{track.postTime}</span>
            {<span className="race-no">R{track.raceNo}</span>}
          </span>
          <div>
            {/* TODO: Conditions */}
            <div className="d-flex align-items-center">
              <span>{warningTemplate(track)}</span>
              {track.isFo === true && <span className="fo-badge">FO</span>}
              <span
                className={`race-status ${track.trackStatus.toLowerCase()}`}
              >
                {/* {track.trackStatus === 'NON_DISPLAY' ? 'ND' : track.trackStatus} */}
                {track.trackStatus}
              </span>
            </div>
          </div>
        </div>
      </button>
    );
  };

  const onClickSideBtn = () => {
    setScheduleToggle(!scheduleToggle);
  };

  const clearSearch = () => {
    search_string = "";
    setSearchString("");

    let tList: any = updateTrackByFilter(
      tracksObj.current,
      provinceEventListFilter?.selectedEvent,
      provinceEventListFilter.selectedTrackType
    );
    setFilteredTrackList(tList);
  };

  return (
    <div className="d-flex align-items-stretch h-100">
      <InlineLoader showSpinner={isLoading} />
      <div className="meet-container">
        <button
          className={
            provinceEventListFilter.selectedEvent === "ALL"
              ? "meeting active-btn"
              : "meeting"
          }
          onClick={() => onEventTypeClick("ALL")}
        >
          <span className="meeting-name">All</span>
        </button>
        {provinceEventListData.eventTypes?.map((meeting) => {
          return (
            <button
              onClick={() => onEventTypeClick(meeting)}
              className={
                provinceEventListFilter.selectedEvent === meeting
                  ? "meeting active-btn"
                  : "meeting"
              }
              key={meeting}
            >
              <span>
                <img src={`${getDynamicImage(meeting)}`} alt={meeting} />
              </span>
              <span className="meeting-name">{meeting}</span>
            </button>
          );
        })}
        {!provinceEventListData.eventTypes.length && (
          <h4 className="text-muted">
            No <br /> Meetings
          </h4>
        )}
        <div className="side-btn">
          <button onClick={onClickSideBtn}>
            <i className="fa fa-bars"></i>
          </button>
        </div>
      </div>
      <div className={scheduleToggle ? "events open" : "events close"}>
        <div className="filter">
          {countryFilterTemplate()}
          {dateFilterTemplate()}
          <div className="search-input px-3 py-2">
            <input
              type="search"
              onChange={searchTracks}
              placeholder="Search track here."
              name=""
              value={searchString}
              id=""
              className="p-2"
            />

            {searchString.length > 0 ? (
              <span onClick={() => clearSearch()}>
                <i className="fa fa-close icon pr-4"></i>
              </span>
            ) : (
              <i className="fa fa-search icon pr-4"></i>
            )}
          </div>
        </div>
        <div className="meet-event-container">
          {filteredTrackList.map((track: any, index: number) => {
            if (
              !provinceEventListFilter.selectedCountries.length ||
              provinceEventListFilter.selectedCountries.includes(
                raceKeySplit(track.pzoneCode, "-", 0)
              )
            ) {
              return raceCardTemplate(track);
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
}
