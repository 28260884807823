import { format } from "date-fns";
import { useEffect, useState } from "react";

//helper
import { FORMAT } from "../../../Helpers/constants";
import { StepsDTO, StepsList } from "../../../Helpers/StaticData/race-data";
//model
import { RaceDetailsDTO } from "../../../Models/RaceDetailsDTO";
import { RaceTripDTO } from "../../../Models/raceTripsDTO";
import { TrackInfoDTO } from "../../../Models/trackInfoDTO";
import "./addNewRaceComponent.scss";

//steps for add race
import RaceDetailsStep from "./Steps/raceDetailsStep";
import RaceTripDetailsStep from "./Steps/raceTripDetailsStep";
import TrackDetailsStep from "./Steps/trackDetailsStep";
import VerificationStep from "./Steps/verificationStep";

export interface IAddNewRaceComponentProps {
  toggleAddRace: (state: any) => void;
}

export default function AddNewRaceComponent(props: IAddNewRaceComponentProps) {

  const [steps, setSteps] = useState<StepsDTO[]>([]);
  const [selectedStep, setSelectedStep] = useState(1);

  const [newRaceDetails, setNewRaceDetails] = useState({} as RaceDetailsDTO);
  const [newRaceTripDetails, setNewRaceTripDetails] = useState<RaceTripDTO[]>([]);

  const [selectedTrack, setSelectedTrack] = useState({} as TrackInfoDTO);


  const [selectedDate, setSelectedDate] = useState(new Date());

  

  useEffect(() => {
    setSteps(JSON.parse(JSON.stringify(StepsList)));
    setSelectedStep(1);
    setNewRaceDetails(setDefaultRaceDetails());
    setSelectedDate(new Date());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // Set default values for race
  const setDefaultRaceDetails = () => {
    let newRaceDetails: RaceDetailsDTO = {
      country: "",
      courseType: "",
      distance: 0,
      foGenerated: null,
      jumpTime: "",
      raceKeyString: "",
      eventType: selectedTrack?.eventType ?? "",
      formattedPosttime: format(new Date(), FORMAT.HHmma_COLON),
      formattedDate: format(selectedDate, FORMAT.ddMMMyyyy_DASH),
      lastUpdated: 0,
      localPostTime: 0,
      postTime: format(selectedDate, FORMAT.yyyyMMddHHmmss),
      providerId: "",
      raceKey: {
        localDate: Number(format(selectedDate, FORMAT.yyyyMMdd)),
        pzoneCode: selectedTrack?.pzoneCode ?? "",
        raceNo: 0,
      },
      raceStatus: "NON_DISPLAY",
      raceTemp: 0,
      raceType: "",
      surfaceType: "",
      timezoneId: "",
      trackCondition: "",
      trackName: selectedTrack?.trackName ?? "",
      trackVariant: 0,
      weather: "",
      windDirection: "",
      windSpeed: 0,
      skipFields: null,
      cancelled:false
    };

    return newRaceDetails;
  };

  const getStepTemplate = () => {
    switch (selectedStep) {
      case 1:
        return <TrackDetailsStep onStepChange={onStepChange}
          newRaceDetails={newRaceDetails}
          setNewRaceDetails={setNewRaceDetails}
          selectedTrack={selectedTrack}
          setSelectedTrack={setSelectedTrack}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />;
      case 2:
        return <RaceDetailsStep onStepChange={onStepChange} newRaceDetails={newRaceDetails}
          setNewRaceDetails={setNewRaceDetails}
          selectedTrack={selectedTrack}
          setSelectedTrack={setSelectedTrack}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate} />;
      case 3:
        return <RaceTripDetailsStep onStepChange={onStepChange} newRaceDetails={newRaceDetails}
          setNewRaceDetails={setNewRaceDetails}
          selectedTrack={selectedTrack}
          setSelectedTrack={setSelectedTrack}
          raceTrips={newRaceTripDetails}
          setRaceTrips={setNewRaceTripDetails} />;
      case 4:
        return <VerificationStep toggleAddRace={props.toggleAddRace} resetStates={resetState} newRaceDetails={newRaceDetails}
          setNewRaceDetails={setNewRaceDetails}
          selectedTrack={selectedTrack}
          setSelectedTrack={setSelectedTrack}
          raceTrips={newRaceTripDetails}
          setRaceTrips={setNewRaceTripDetails} />;
    }
  };


  const resetState = () => {
    setNewRaceDetails(setDefaultRaceDetails());
    setNewRaceTripDetails([]);
    setSelectedStep(1);
    setSteps(JSON.parse(JSON.stringify(StepsList)));
    props.toggleAddRace(false);
  }

  const onStepChange = (index: number) => {
    for (let step of steps) {
      step.active = false;
      if (index === step.index) {
        step.active = true;
      } else if (index - 1 === step.index) {
        step.completed = true;
      }
    }

    setSelectedStep(index);
  };

  const onStepClick = (index: number) => {
    let i = steps.findIndex((x) => x.index === index);
    if (steps[i].completed || steps[i].active) {
      setSelectedStep(index);
    }
  };

  return (
    <div className="pz-card">
      <div className="pz-card-header">
        <i className="fa fa-plus"></i> &nbsp; Add New Race Details
      </div>

      <div className="pz-card-body">
        <div className="stepper">
          <div className="stepper-wrapper">
            {steps?.map((step) => {
              let completed = step.completed ? "completed" : "";
              let active = step.active ? "active" : "";
              return (
                <div
                  onClick={() => onStepClick(step.index)}
                  key={step.index}
                  className={`stepper-item ${active} ${completed}`}
                >
                  <div className="step-counter">{step.index}</div>
                  <div className="step-name">{step.label}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="step-body-container">{getStepTemplate()}</div>
      </div>
    </div>
  );
}
