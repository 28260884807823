import { Calendar } from "primereact/calendar";
import { useState, useEffect } from "react";
import PzDropDown from "../../../../UI-Components/Inputs/pzDropDown";
import "../BetHistoryData/WagerHistory.scss";
import "./FieldLogs.scss";
import { UserApiService } from "../../../../services/userApiService";
import { showToast, TOAST } from "../../../../Helpers/ui-helper";
import { format } from "date-fns";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/components/column/Column";
import { MultiSelect } from "primereact/multiselect";
import {
  dateTimeConversion,
  getStringToDate,
} from "../../../../Helpers/dateTimeHelpers";
import { camelize } from "../../../../Helpers/valueHelper";
import {
  poolList,
  raceList,
  raceTripList,
} from "../../../../Helpers/StaticData/transactionData";
import { InputText } from "primereact/inputtext";
import { CSVLink } from "react-csv";
import LargeLoader from "../../../../UI-Components/Loader/loaders";

export interface logTypeProps {
  logType: string;
}

export default function FieldLogs(props: logTypeProps) {
  const { logType } = props;
  //date
  const [date, setDate] = useState<Date | undefined>();

  const [selectedLogType] = useState<string>(logType);

  //province
  const [provinceList, setProvinceList] = useState<any[]>([]);
  const [province, setProvince] = useState<string>("");

  //all Data List
  const [fieldLogsData, setFieldLogsData] = useState<any[]>([]);
  const [filterData, setFilterData] = useState<any[]>([]);

  //set List for dynamic columns
  const [columns, setColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState(columns);

  //LocalFilter For Race
  const [trackCodeList, setTrackCodeList] = useState<any[]>([]);
  const [selectedTrackCode, setSelectinoTrackCode] = useState<string[]>([]);
  const [trackNoList, setTrackNoList] = useState<any[]>([]);
  const [selectedTrackNo, setSelectedTrackNo] = useState<string[]>([]);
  const [actionList, setActionList] = useState<any[]>([]);
  const [selctedAction, setSelctedAction] = useState<string[]>([]);

  //LocalFilter For Pool
  const [poolTypeList, setpoolTypeList] = useState<any[]>([]);
  const [selectedPoolType, setselectedPoolType] = useState<string[]>([]);
  const [providerList, setproviderList] = useState<any[]>([]);
  const [selectedProvider, setselectedProvider] = useState<string[]>([]);

  //LocalFilter For RaceTrip
  const [selIdList, setselIdList] = useState<any[]>([]);
  const [selectedSelId, setselectedSelId] = useState<string[]>([]);

  //boolean
  const [isSubmited, setIsSubmited] = useState(false);
  const [isDataNotAvailable, setIsDataNotAvailable] = useState(false);
  const [isPoolSelected, setIsPoolSelected] = useState(false);
  const [isRaceTripSelcted, setisRaceTripSelcted] = useState(false);
  const [isResponse_DataNotAvailable, setIsResponse_DataNotAvailable] =
    useState(false);
  //Loader
  const [isLoading, setisLoading] = useState(false);

  const [globalFilter, setGlobalFilter] = useState(null);

  useEffect(() => {
    UserApiService.getProvinceList()
      .then((res) => {
        let list: any = [];
        if (res.data) {
          for (const key of res.data) {
            let obj: any = {};
            obj["label"] = key.province;
            obj["value"] = key.province;
            list.push(obj);
          }
          list.sort((a: any, b: any) => (a.value > b.value ? 1 : -1));
          setProvinceList(list);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) showToast(err.response.data.message, TOAST.ERROR);
        else
          showToast("Server did not respond! Please try later!", TOAST.ERROR);
      });
  }, []);

  const dynamicColumns = selectedColumns.map((col, i) => {
    const { field, header } = col;
    return (
      <Column
        key={field}
        field={field}
        header={header}
        sortable={
          field === "RaceNo" || field === "selId" || field === "timeId"
            ? true
            : false
        }
      />
    );
  });

  const onChangeDate = (date: any) => {
    if (date) {
      setDate(date);
    } else {
      showToast("Select Valid Date", TOAST.ERROR);
      return;
    }
  };

  const checkValidation = () => {
    if (!date) {
      setisLoading(false);
      showToast("Select Date", TOAST.ERROR);
      return false;
    }
    if (!province) {
      setisLoading(false);
      showToast("Select Province", TOAST.ERROR);
      return false;
    }
    return true;
  };

  const resetData = () => {
    setTrackNoList([]);
    setTrackCodeList([]);
    setActionList([]);
    setproviderList([]);
    setpoolTypeList([]);
    setSelctedAction([]);
    setSelectedTrackNo([]);
    setSelectinoTrackCode([]);
    setselectedPoolType([]);
    setselectedProvider([]);
    setselectedSelId([]);
    setselIdList([]);
    setIsDataNotAvailable(false);
  };

  const setDefaultColumns = () => {
    let tList: any = [];
    if (selectedLogType === "RACE") {
      tList = raceList;
    } else if (selectedLogType === "RACETRIP") {
      tList = raceTripList;
    } else {
      tList = poolList;
    }
    setSelectedColumns(tList);
  };

  const addColumns = (columnsList: any) => {
    let tempList: any = [];
    for (const column of columnsList) {
      let obj: any = {};
      obj["field"] = column;
      let str: any = column;
      let str1 = str.replace(/([A-Z])/g, " $1").trim();
      obj["header"] = camelize(str1);
      tempList.push(obj);
    }
    setColumns(tempList);
  };

  const addData = (resData: any) => {
    let dataSet = new Set();
    let List: any = [];
    let trackcodeSet = new Set();
    let racenoSet = new Set();
    let actionSet = new Set();
    let poolTypeSet = new Set();
    let providerSet = new Set();
    let selIdSet = new Set();

    for (const data of resData) {
      let object: any = {};
      let fields = Object.keys(data);
      for (const field of fields) {
        if (field === "logKey" || field === "poolLogKey") {
          let Keys;
          if (field === "logKey") {
            Keys = Object.keys(data.logKey);
          } else {
            Keys = Object.keys(data.poolLogKey);
          }
          for (const key of Keys) {
            if (key === "raceKey") {
              let string = data.logKey[key].split("~", 2);
              object["TrackCode"] = string[0];
              object["RaceNo"] = string[1];
              dataSet.add("RaceNo");
              dataSet.add("TrackCode");
              trackcodeSet.add(string[0]);
              racenoSet.add(string[1]);
            } else if (key === "poolKey") {
              let string = data.logKey[key].split("~", 2);
              object["poolType"] = string[0];
              object["provider"] = string[1];
              dataSet.add("poolType");
              dataSet.add("provider");
              poolTypeSet.add(string[0]);
              providerSet.add(string[1]);
            } else if (key === "timeId") {
              let tempdate = new Date(data.logKey[key]);
              let dateConvertStr = format(tempdate, "yyyyMMddHHmmss");
              var formatedDate = dateTimeConversion(
                data.timezone,
                dateConvertStr
              );
              object[key] = formatedDate;
            } else if (key === "selId") {
              object[key] = data.logKey[key];
              selIdSet.add(data.logKey[key]);
            } else if (key === "localDate") {
              let dateFormatee = getStringToDate(data.logKey[key].toString());
              object[key] = format(
                new Date(dateFormatee.toString()),
                "dd-MM-yyyy"
              );
            } else {
              object[key] = data.logKey[key];
            }
            dataSet.add(key);
          }
        } else if (field === "updatedFields") {
          let updatedKeys = Object.keys(data.updatedFields);
          for (const key of updatedKeys) {
            if (key === "PostTime") {
              data.updatedFields.PostTime = dateTimeConversion(
                data.timezone,
                data.updatedFields.PostTime
              );
            } else if (key === "JumpTime") {
              data.updatedFields.JumpTime = dateTimeConversion(
                data.timezone,
                data.updatedFields.JumpTime
              );
            }
            object[key] = data.updatedFields[key];
            if (
              key !== "RaceKey" &&
              key !== "PoolKey" &&
              key !== "LastUpdated"
            ) {
              dataSet.add(key);
            }
          }
        } else {
          if (field === "action") {
            actionSet.add(data[field]);
          }
          if (field !== "timezone") {
            dataSet.add(field);
          }
          object[field] = data[field];
        }
      }
      dataSet.delete("raceKey");
      dataSet.delete("poolKey");
      List.push(object);
    }
    List.sort((a: any, b: any) => (a.timeId < b.timeId ? 1 : -1));
    let columnsList = Array.from(dataSet);
    addColumns(columnsList);
    setFieldLogsData(List);
    setFilterData(List);
    localFilterData(
      trackcodeSet,
      racenoSet,
      actionSet,
      poolTypeSet,
      providerSet,
      selIdSet
    );
  };

  const onSubmit = () => {
    setisLoading(true);
    resetData();
    setDefaultColumns();
    if (selectedLogType === "POOL_CONFIG") {
      setIsPoolSelected(true);
      setisRaceTripSelcted(false);
    } else if (selectedLogType === "RACETRIP") {
      setisRaceTripSelcted(true);
      setIsPoolSelected(false);
    } else {
      setIsPoolSelected(false);
      setisRaceTripSelcted(false);
    }
    if (checkValidation()) {
      let tempDate: any = date;
      let formatedDate = format(tempDate, "yyyyMMdd");
      UserApiService.getRaceLogsData(formatedDate, selectedLogType, province)
        .then((res) => {
          if (res.data.length > 0) {
            addData(res.data);
            setIsSubmited(true);
            setIsResponse_DataNotAvailable(false);
          } else {
            setIsSubmited(false);
            setIsResponse_DataNotAvailable(true);
            showToast("Data Not Available", TOAST.ERROR);
          }
          setisLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsResponse_DataNotAvailable(true);
          if (err.response) showToast(err.response.data.message, TOAST.ERROR);
          else
            showToast("Server did not respond! Please try later!", TOAST.ERROR);
          setisLoading(false);
        });
    }
  };

  const onColumnToggle = (event: any) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col: any) =>
      selectedColumns.some((sCol: any) => sCol.field === col.field)
    );
    setSelectedColumns(orderedSelectedColumns);
  };

  const header = (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ textAlign: "left" }}>
        <MultiSelect
          value={selectedColumns}
          options={columns}
          optionLabel="header"
          placeholder="Select Columns"
          onChange={onColumnToggle}
          style={{ width: "20em" }}
          filter
          filterBy="field"
        />
      </div>
      <div>
        <span className="p-input-icon-left">
          <i className="pi pi-search" style={{ color: "white" }} />
          <InputText
            type="search"
            onInput={(e: any) => setGlobalFilter(e.target.value)}
            placeholder="Global Search"
          />
        </span>
      </div>
    </div>
  );

  const localFilterData = (
    trackcodeSet: any,
    racenoSet: any,
    actionSet: any,
    poolTypeSet: any,
    providerSet: any,
    selIdSet: any
  ) => {
    let tcode = [];
    let rno = [];
    let act = [];
    let ptype = [];
    let proList = [];
    let selIdList = [];

    let tcodeData = Array.from(trackcodeSet);
    let rnoData = Array.from(racenoSet);
    let actData = Array.from(actionSet);
    let pTypeData = Array.from(poolTypeSet);
    let providerData = Array.from(providerSet);
    let selIdData = Array.from(selIdSet);

    tcodeData.sort((a: any, b: any) => (a > b ? 1 : -1));
    rnoData.sort((a: any, b: any) => (Number(a) < Number(b) ? -1 : 1));
    selIdData.sort((a: any, b: any) => (Number(a) < Number(b) ? -1 : 1));

    for (const data of tcodeData) {
      tcode.push({ label: data, value: data });
    }
    for (const data of rnoData) {
      rno.push({ label: data, value: data });
    }
    for (const data of actData) {
      act.push({ label: data, value: data });
    }
    if (selectedLogType === "POOL_CONFIG") {
      for (const data of pTypeData) {
        ptype.push({ label: data, value: data });
      }
      for (const data of providerData) {
        proList.push({ label: data, value: data });
      }
    } else if (selectedLogType === "RACETRIP") {
      for (const data of selIdData) {
        selIdList.push({ label: data, value: data });
      }
    }
    setTrackCodeList(tcode);
    setTrackNoList(rno);
    setActionList(act);
    setpoolTypeList(ptype);
    setproviderList(proList);
    setselIdList(selIdList);
  };

  const onFilteredData = () => {
    var tempList: any[] = [];
    for (const data of filterData) {
      if (
        selectedTrackNo.length !== 0 &&
        !selectedTrackNo.includes(data.RaceNo)
      ) {
        continue;
      }
      if (
        selectedTrackCode.length !== 0 &&
        !selectedTrackCode.includes(data.TrackCode)
      ) {
        continue;
      }
      if (selctedAction.length !== 0 && !selctedAction.includes(data.action)) {
        continue;
      }
      if (selectedLogType === "POOL_CONFIG") {
        if (
          selectedPoolType.length !== 0 &&
          !selectedPoolType.includes(data.poolType)
        ) {
          continue;
        }
        if (
          selectedProvider.length !== 0 &&
          !selectedProvider.includes(data.provider)
        ) {
          continue;
        }
      } else if (selectedLogType === "RACETRIP") {
        if (selectedSelId.length !== 0 && !selectedSelId.includes(data.selId)) {
          continue;
        }
      }
      tempList.push(data);
    }
    if (tempList.length === 0) {
      setIsDataNotAvailable(true);
    } else {
      setIsDataNotAvailable(false);
    }
    setFieldLogsData(tempList);
  };
  const onCsvLink = () => {
    if (fieldLogsData.length === 0) {
      return false;
    }
  };

  return (
    <>
      <LargeLoader isLoading={isLoading} />
      <div className="content-middle-panel w-100">
        <div className="content-panel-header head multiple">
          {selectedLogType === "RACE"
            ? "Race Logs"
            : selectedLogType === "RACETRIP"
            ? "Race Trip Logs"
            : "Pool Logs"}
        </div>
        <div className="inpage-nav m-3">
          <div
            className="page-nav row"
            style={{ padding: "10px", alignItems: "center" }}
          >
            <div style={{ minWidth: "250px", padding: "0px 1.8rem" }}>
              <Calendar
                id="range"
                onChange={(e: any) => onChangeDate(e.value)}
                value={date}
                placeholder="Select date for Logs"
                className="calendercss w-100"
              ></Calendar>
            </div>
            <div style={{ minWidth: "250px" }}>
              <PzDropDown
                value={province}
                options={provinceList}
                onChange={(e: any) => {
                  setProvince(e.value);
                }}
                placeHolder="Select Province"
                filter
                showclear={province?.length > 0 ? true : false}
                filterName="label"
                className="drpdwn"
              />
            </div>
            <div className="ml-2">
              <button onClick={onSubmit} className="pz-btn primary rounded">
                SUBMIT
              </button>
            </div>
          </div>
        </div>

        {isSubmited && (
          <>
            <div className="contain pz-card ">
              <div style={{ display: "flex", padding: "10px" }} className="row">
                <div
                  className="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-6"
                  style={{ margin: "10px 0px" }}
                >
                  <MultiSelect
                    value={selctedAction}
                    options={actionList}
                    onChange={(e) => setSelctedAction(e.value)}
                    optionLabel="label"
                    placeholder="Select Action"
                  />
                </div>
                <div
                  className="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-6"
                  style={{ margin: "10px 0px" }}
                >
                  <MultiSelect
                    value={selectedTrackCode}
                    options={trackCodeList}
                    onChange={(e) => setSelectinoTrackCode(e.value)}
                    optionLabel="label"
                    placeholder="Select Track Code"
                    filter
                  />
                </div>
                <div
                  className="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-6"
                  style={{ margin: "10px 0px" }}
                >
                  <MultiSelect
                    value={selectedTrackNo}
                    options={trackNoList}
                    onChange={(e) => setSelectedTrackNo(e.value)}
                    optionLabel="label"
                    placeholder="Select Race No"
                    filter
                  />
                </div>

                {isPoolSelected && (
                  <>
                    <div
                      className="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-6"
                      style={{ margin: "10px 0px" }}
                    >
                      <MultiSelect
                        value={selectedProvider}
                        options={providerList}
                        onChange={(e) => setselectedProvider(e.value)}
                        optionLabel="label"
                        placeholder="Select Provider"
                      />
                    </div>
                    <div
                      className="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-6"
                      style={{ margin: "10px 0px" }}
                    >
                      <MultiSelect
                        value={selectedPoolType}
                        options={poolTypeList}
                        onChange={(e) => setselectedPoolType(e.value)}
                        optionLabel="label"
                        placeholder="Select Pool Type"
                      />
                    </div>
                  </>
                )}
                {isRaceTripSelcted && (
                  <>
                    <div
                      className="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-6"
                      style={{ margin: "10px 0px" }}
                    >
                      <MultiSelect
                        value={selectedSelId}
                        options={selIdList}
                        onChange={(e) => setselectedSelId(e.value)}
                        optionLabel="label"
                        placeholder="Select SelId"
                      />
                    </div>
                  </>
                )}
                <div
                  className="btn-container col-12 col-lg-3 col-xl-3 col-md-4 col-sm-6"
                  style={{ margin: "10px 0px" }}
                >
                  <button
                    className="pz-btn primary rounded"
                    onClick={onFilteredData}
                  >
                    FILTER
                  </button>
                </div>
              </div>
              <div>
                <div style={{ margin: "2rem" }}>
                  <CSVLink
                    data={fieldLogsData}
                    filename={date + "_field_logs.csv"}
                    className="pz-btn primary rounded"
                    target="_blank"
                    onClick={onCsvLink}
                  >
                    Export CSV
                  </CSVLink>
                </div>
              </div>
            </div>
            {!isDataNotAvailable && (
              <div className="pz-card">
                <div
                  className="table-scroll-racelog"
                  // className={
                  //   selectedLogType === "RACE"
                  //     ? "table-scroll-racelog"
                  //     : "table-scroll-fieldlogs"
                  // }
                >
                  <DataTable
                    style={{ width: "auto !important" }}
                    value={fieldLogsData}
                    className="table-class"
                    header={header}
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rows={25}
                    rowsPerPageOptions={[25, 50, 100]}
                    globalFilter={globalFilter}
                    selectionMode="multiple"
                  >
                    {dynamicColumns}
                  </DataTable>
                </div>
              </div>
            )}
            <div className="pz-card">
              {isDataNotAvailable && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "20px",
                    padding: "10px",
                  }}
                >
                  <span>Data Not Available!</span>
                </div>
              )}
            </div>
          </>
        )}
        <div className="pz-card">
          {!isSubmited && !isResponse_DataNotAvailable && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "20px",
                padding: "10px",
              }}
            >
              <span>Select Fields For Race Logs </span>
            </div>
          )}
          {isResponse_DataNotAvailable && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "20px",
                padding: "10px",
              }}
            >
              <span>Data Not Available!</span>
            </div>
          )}
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
