import React from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ColsCustomer } from "../../../../Helpers/columns";
import LargeLoader from "../../../../UI-Components/Loader/loaders";
import SidebarNavigation from "../SidebarNavigation/SidebarNavigation";
import Header from "../../../../UI-Components/Header/header";
import { InputText } from "primereact/inputtext";
import "./ViewSearchCustomer.scss";

export interface ActiveCustomerProps {
  customerData: any;
  isLoading?: any;
  activeCustomerSession?: any;
  customerType: string;
  getSessionIds?: any;
  onPage?: any;
  openCustomerPanel: any;
  onCustomerFilterChange?: any;
  customerFilter?: any;
  lazyParams: any;
}

const ViewSearchCustomer = (props: ActiveCustomerProps) => {
  // props
  const {
    customerData,
    isLoading,
    activeCustomerSession,
    customerType,
    getSessionIds,
    onPage,
    openCustomerPanel,
    onCustomerFilterChange,
    customerFilter,
    lazyParams,
  } = props;

  // function
  const cutomerViewTemplate = (rowData: any) => {
    return (
      <>
        <button className="pool-btn" onClick={() => openCustomerPanel(rowData)}>
          <i className="fa fa-eye" style={{ color: "#41f541" }}></i>{" "}
          &nbsp;&nbsp; View
        </button>
      </>
    );
  };

  return (
    <div className="search-wrapper">
      <LargeLoader isLoading={isLoading} />
      <Header pageTitle="Customer Search" />
      <div className="content heightfull ">
        <div className="content-left-panel" style={{ width: "25rem" }}>
          <SidebarNavigation customerType={customerType} />
        </div>
        <div className="content-middle-panel w-100">
          <div className="content-panel-header head multiple">
            {customerType === "active" ? "Active Customer" : "Search Customer"}
          </div>
          <div className="inpage-nav m-3 d-block p-3">
            {customerType === "active" ? (
              <>
                {customerData.length > 0 && (
                  <div className="active_customer_badge">
                    <span className="textHeading">
                      Total Active Customers : {activeCustomerSession?.length}
                      <i
                        className="fa fa-refresh ml-3"
                        role="button"
                        aria-hidden="true"
                        onClick={getSessionIds}
                      ></i>
                    </span>
                  </div>
                )}
              </>
            ) : (
              <div className="center">
                <span className="p-input-icon-left filter-width">
                  <i className="pi pi-search" />
                  <InputText
                    className="input-width"
                    value={customerFilter}
                    onChange={onCustomerFilterChange}
                    placeholder="Customer Search"
                    autoFocus
                  />
                </span>
              </div>
            )}
            {customerData.length !== 0 ? (
              <DataTable
                className="dataTable"
                value={customerData}
                paginator
                rows={lazyParams.rows}
                first={lazyParams.first}
                totalRecords={
                  customerType === "active"
                    ? activeCustomerSession?.length
                    : customerData.length
                }
                lazy={customerType === "active" && true}
                onPage={onPage}
              >
                {ColsCustomer.map((col, index) => {
                  if (col.field === "view")
                    return (
                      <Column
                        key={index}
                        style={{ width: col.width }}
                        field={col.field}
                        header={col.header}
                        body={(rowData) => cutomerViewTemplate(rowData)}
                        sortable={col.field !== "view"}
                      />
                    );
                  return (
                    <Column
                      key={index}
                      style={{ width: col.width }}
                      field={col.field}
                      header={col.header}
                      sortable={
                        col.field !== "view" && customerType !== "active"
                      }
                    />
                  );
                })}
              </DataTable>
            ) : (
              <>
                {customerData.length === 0 && customerType === "active" && (
                  <span className="customer-empty-data ">
                    No Active Customer Data Found
                  </span>
                )}
                {customerType === "search" && (
                  <span className="customer-empty-data ">
                    Customer Search Here...
                  </span>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSearchCustomer;
