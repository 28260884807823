import axios from "axios";
import { SERVER } from "../Helpers/constants";
import { getHeaders } from "../Helpers/valueHelper";

export const ToteApiService = {
  getToteBucketSummary(raceKey: string) {
    let header = getHeaders();
    let url =
      SERVER.URL_RISK + SERVER.MODULE.BET + "get-tote-summary/" + raceKey;

    return axios.get(url, { headers: header });
  },
  // getToteBucketSummaryByPool(poolKey: string) {
  //   let header = getHeaders();
  //   let url =
  //     SERVER.URL_RISK +
  //     SERVER.MODULE.BET +
  //     "get-tote-summary-by-pool/" +
  //     poolKey;

  //   return axios.get(url, { headers: header });
  // },
};
