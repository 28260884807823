import { Duration, LocalDateTime, ZonedDateTime, ZoneId } from "@js-joda/core";
import { useEffect, useRef, useState } from "react";
import { DATE_FORMATTER, FORMAT } from "../../../Helpers/constants";
import "@js-joda/timezone";
import { format } from "date-fns";
import "./timer.scss";
export interface ITimerPostTimeProps {
  postTimeString: string;
  timeZone: string;
}

interface TimeState {
  timeStr: string;
  isNormal: boolean;
  isNegative: boolean;
  isNearToJump: boolean;
}

var InitTimeObj: TimeState = {
  timeStr: "",
  isNormal: false,
  isNegative: false,
  isNearToJump: false,
};
export default function TimerPostTime(props: ITimerPostTimeProps) {
  const [timeObj, setTimeObj] = useState<TimeState>(InitTimeObj);
  const intervalRef = useRef<any>(null);
  useEffect(() => {
    const startPostTimeTimers = () => {
      let rigionalZoneId = ZoneId.of(props.timeZone);
      let localDateTime = LocalDateTime.parse(
        props.postTimeString,
        DATE_FORMATTER.YYYYMMDDHHMMSS_FORMATTER
      )
        .atZone(rigionalZoneId)
        .withZoneSameInstant(ZoneId.systemDefault())
        .toLocalDateTime();
      let localDT = format(
        new Date(localDateTime.toString()),
        FORMAT.yyyyMMddHHmmss
      );
      let temp: LocalDateTime = LocalDateTime.parse(
        localDT,
        DATE_FORMATTER.YYYYMMDDHHMMSS_FORMATTER
      );
      let raceTime: ZonedDateTime = ZonedDateTime.of(temp, ZoneId.SYSTEM);
      let timeObj = {} as TimeState;

      const duration: Duration = Duration.between(
        ZonedDateTime.now(),
        raceTime
      );

      let hour = duration.toHours();
      let minute = duration.toMinutes() % 60;
      let second = duration.seconds() % 60;

      if (hour > 0) {
        timeObj.isNormal = true;
        timeObj.timeStr = hour + "h:" + minute + "m";
      } else if (minute > 0) {
        timeObj.isNormal = true;
        timeObj.timeStr = minute + "m";
        if (minute < 3) {
          timeObj.isNearToJump = true;
          timeObj.isNormal = false;
          timeObj.timeStr = minute + "m:" + second + "s";
        }
      } else if (second > 0) {
        timeObj.isNearToJump = true;
        timeObj.isNormal = false;
        timeObj.timeStr = second + "s";
      } else {
        timeObj.isNegative = true;
        timeObj.isNormal = false;
        timeObj.isNearToJump = false;

        hour = Math.abs(hour);
        minute = Math.abs(minute);
        second = Math.abs(second);
        if (hour > 0)
          timeObj.timeStr = "-" + hour + "h:" + minute + "m:" + second + "s";
        else if (minute > 0)
          timeObj.timeStr = "-" + minute + "m:" + second + "s";
        else if (second > 0) timeObj.timeStr = "-" + second + "s";
        else timeObj.timeStr = second + "s";
      }
      setTimeObj(timeObj);
    };

    if (props.postTimeString) {
      intervalRef.current = setInterval(() => {
        startPostTimeTimers();
      }, 1000);
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [props.postTimeString, props.timeZone]);
  return (
    <span
      className={
        timeObj.isNearToJump
          ? "timer-green timer"
          : timeObj.isNegative
          ? "timer-red timer"
          : timeObj.isNormal
          ? "timer-blue timer"
          : ""
      }
    >
      {timeObj.timeStr}
    </span>
  );
}
