import "./header.scss";
import { ROUTES } from "../../Helpers/constants";
import { useHistory } from "react-router";
import { AppContext } from "../../Context/appContext";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { confirmDialog } from "primereact/confirmdialog";
import { UserApiService } from "../../services/userApiService";
import { showToast, TOAST } from "../../Helpers/ui-helper";
import { Sidebar } from "primereact/sidebar";
import {
  hasConnectorPermission,
  hasCustomerSummaryPermission,
  hasLogsPermission,
  hasReportPermission,
  hasRiskPermission,
  hasRulesPermission,
  hasSchedulePermission,
  hasTotePermission,
  hasUsersAccess,
} from "../../Helpers/permission-helpers";

import SheduleManagementIcon from "../../assets/images/Schedule.png";
import FixedOddsManagementIcon from "../../assets/images/fixed_odds.png";
import RulesIcon from "../../assets/images/rules.png";
import ConnectorIcon from "../../assets/images/connectors.png";
import LogsIcon from "../../assets/images/logs.png";
import CustomerySummeryIcon from "../../assets/images/customer_summary.png";
import UserAccessIcon from "../../assets/images/user_access.png";
import DashboardIcon from "../../assets/images/dashboard.png";
import ManagementIcon from "../../assets/images/management.png";

interface IHeaderProps {
  pageTitle: string;
}
interface RouteDTO {
  path: string;
  src: string;
  label: string;
}

const InitRoutes = [
  { path: ROUTES.RULES, src: RulesIcon, label: "Rules" },
  { path: ROUTES.CONNECTORS, src: ConnectorIcon, label: "Connectors" },
  { path: ROUTES.RACELOGS, src: LogsIcon, label: "Logs" },
  {
    path: ROUTES.ACTIVE_CUSTOMER,
    src: CustomerySummeryIcon,
    label: "Customers",
  },
  { path: ROUTES.USER_ACCESS, src: UserAccessIcon, label: "User & Access" },
  { path: ROUTES.WAGERSHISTORY, src: UserAccessIcon, label: "Reports" },
];

function Header(props: IHeaderProps) {
  const history = useHistory();
  const appContext = useContext(AppContext);
  const [menu, setMenu] = useState<RouteDTO[]>([]);
  const [isSchedulePermission, setIsSchedulePermission] = useState(false);
  const [isTotePermission, setIsTotePermission] = useState(false);
  const [isFixedOddsPermission, setFixedOddsPermission] = useState(false);
  const [isSiderbarOpen, setIsSidebarOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isDesktopSize, setIsDesktopSize] = useState(false);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    if (width < 1150) {
      setIsDesktopSize(true);
    } else {
      setIsDesktopSize(false);
    }
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  useEffect(() => {
    let menuItems = [...InitRoutes] as RouteDTO[];
    if (appContext.user?.roles && menuItems) {
      let roles = appContext.user?.roles;
      if (!hasUsersAccess(roles)) {
        console.error("No USER Permissions");
        let i = menuItems.findIndex((x) => x.path === ROUTES.USER_ACCESS);
        menuItems.splice(i, 1);
      }
      if (!hasRulesPermission(roles)) {
        console.error("No Rules Permission");
        let i = menuItems.findIndex((x) => x.path === ROUTES.RULES);
        menuItems.splice(i, 1);
      }
      if (!hasConnectorPermission(roles)) {
        console.error("No Connectors Permission");
        let i = menuItems.findIndex((x) => x.path === ROUTES.CONNECTORS);
        menuItems.splice(i, 1);
      }
      if (!hasCustomerSummaryPermission(roles)) {
        console.error("No Customer Summary Permission");
        let i = menuItems.findIndex((x) => x.path === ROUTES.CUSTOMER_SUMMARY);
        menuItems.splice(i, 1);
      }
      if (!hasReportPermission(roles)) {
        console.error("No Report Permission");
        let i = menuItems.findIndex((x) => x.path === ROUTES.REPORTS);
        menuItems.splice(i, 1);
      }
      if (!hasLogsPermission(roles)) {
        console.error("No Logs Permission");
        let i = menuItems.findIndex((x) => x.path === ROUTES.LOGS);
        menuItems.splice(i, 1);
      }
      console.log(menuItems);
      setMenu(menuItems);

      if (hasRiskPermission(roles)) {
        setFixedOddsPermission(true);
      }
      if (hasSchedulePermission(roles)) {
        setIsSchedulePermission(true);
      }
      if (hasTotePermission(roles)) {
        setIsTotePermission(true);
      }
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext.user]);

  const logoutUser = () => {
    UserApiService.userLogout()
      .then(() => {
        appContext.clearData();
        history.push(ROUTES.LOGIN);
      })
      .catch((err) => {
        if (err.response) {
          showToast(err.response.data.error, TOAST.ERROR);
        } else {
          showToast("Something went wrong while logout!", TOAST.ERROR);
        }
        appContext.clearData();
        history.push(ROUTES.LOGIN);
      });
  };

  const handleSidebarClose = () => {
    setIsSidebarOpen(false);
  };

  const onLogoutClick = () => {
    confirmDialog({
      message: `Are you Sure to logout?`,
      header: "Log Out Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => logoutUser(),
      acceptLabel: "Yes! I'm Sure",
      rejectLabel: "No, Be Here",
      reject: () => {},
      style: { width: "35vw" },
    });
  };

  const ManagementDropdown = (
    <div className="drop-btn">
      <span>
        <img style={{ width: 30 }} src={ManagementIcon} alt="" /> &nbsp;
        Management
      </span>
      <div className="drop-menu">
        {menu.map((m) => {
          return (
            <Link
              className="menu_links"
              to={m.path}
              key={m.path}
              onClick={handleSidebarClose}
            >
              <img style={{ width: 30, marginRight: 10 }} src={m.src} alt="" />
              {m.label}
            </Link>
          );
        })}
      </div>
    </div>
  );

  const ProfileDropdown = (
    <div className="drop-btn b-left">
      <span className="avatar" style={{ background: "#A409E2" }}>
        {appContext.user?.userName && appContext.user?.userName[0]}
      </span>
      <span>{appContext.user?.userName}</span>
      <div className="drop-menu">
        <div className="profile-detail">
          <label>{appContext.user?.email}</label>
          <label>{appContext.user?.userName}</label>
        </div>
        <span onClick={onLogoutClick}>
          <i
            style={{ transform: "translate(90deg)" }}
            className="pi pi-sign-out"
          ></i>{" "}
          Logout
        </span>
      </div>
    </div>
  );

  const MenuTemplate = () => {
    return (
      <>
        <Link
          to={ROUTES.DASHBOARD}
          className={props.pageTitle === "Dashboard" ? "selectedUrl" : ""}
          onClick={handleSidebarClose}
        >
          {" "}
          <img style={{ width: 25 }} src={DashboardIcon} alt="" />
          &nbsp; Dashboard
        </Link>
        {isSchedulePermission && (
          <Link
            to={ROUTES.SCHEDULE}
            className={
              props.pageTitle === "Schedule Management" ? "selectedUrl" : ""
            }
            onClick={handleSidebarClose}
          >
            {" "}
            <img style={{ width: 25 }} src={SheduleManagementIcon} alt="" />
            &nbsp; Schedule
          </Link>
        )}
        {isTotePermission && (
          <Link
            to={ROUTES.TOTE_MANAGEMENT}
            className={
              props.pageTitle === "Tote Management" ? "selectedUrl" : ""
            }
            onClick={handleSidebarClose}
          >
            {" "}
            <img style={{ width: 25 }} src={SheduleManagementIcon} alt="" />
            &nbsp; Tote
          </Link>
        )}
        {isFixedOddsPermission && (
          <Link
            to={ROUTES.FIXED_ODDS_MANAGEMENT}
            className={
              props.pageTitle === "Fixed Odds Management" ? "selectedUrl" : ""
            }
            onClick={handleSidebarClose}
          >
            <img style={{ width: 25 }} src={FixedOddsManagementIcon} alt="" />
            &nbsp; Fixed Odds
          </Link>
        )}
        {ManagementDropdown}
        {ProfileDropdown}
      </>
    );
  };

  return (
    <div className="header-container b-bottom">
      <div className="title">
        <div className="main-title b-right">
          <Link to={ROUTES.FIXED_ODDS_MANAGEMENT}>
            {" "}
            <h2>Ashva Platform</h2>{" "}
          </Link>
        </div>
        <div className="d-flex align-items-center">
          <h3 className="font-1"> {props.pageTitle} </h3>
        </div>
      </div>
      <div className="header-links">
        {!isDesktopSize ? (
          <>{MenuTemplate()}</>
        ) : (
          <>
            <button className="menu_bar" onClick={() => setIsSidebarOpen(true)}>
              <span className="display_none">MENU</span>
              <span className="fa fa-bars"></span>
            </button>
            <Sidebar
              visible={isSiderbarOpen}
              onHide={handleSidebarClose}
              className="sidebar"
            >
              {MenuTemplate()}
            </Sidebar>
          </>
        )}
      </div>
    </div>
  );
}
export default Header;
