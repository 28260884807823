import "./footer.scss";
import { useContext, useEffect, useRef, useState } from "react";
import { JWTResponseDTO, UserDTO } from "../../Models/UserManagementDto";
import { LOCAL_STORAGE, SERVER } from "../../Helpers/constants";
import { CLOG, showToast, TOAST } from "../../Helpers/ui-helper";
import { PoolRequestDTO } from "../../Models/PoolsDTO";
import { AppContext } from "../../Context/appContext";
import { getLocalStorageObject, getTrackName } from "../../Helpers/valueHelper";
import PoolReqNotification from "../../Pages/Components/PoolReqNotification/poolReqNotification";
import { ScheduleApiService } from "../../services/scheduleApiService";

var ws: WebSocket | null = null;

function Footer() {
  const { user } = useContext(AppContext);

  const isSocketConnect = useRef<Boolean>(false);
  const interval = useRef<any>(null);

  const wsNotification = useRef<WebSocket | null>(null);
  const requestNotificationList = useRef<PoolRequestDTO[]>([]);
  const [waitingToReconnect, setWaitingToReconnect] = useState<Boolean>(true);
  const [requests, setRequests] = useState<PoolRequestDTO[]>([]);

  // useEffect(() => {
  //   if (user && !ws) {
  //     let jwt = getLocalStorageObject(LOCAL_STORAGE.JWT);
  //     let user = getLocalStorageObject(LOCAL_STORAGE.USER);
  //     CLOG("____STARTING GLOBAL WEBSOCKET____", "#84ab12");
  //     if (jwt && user) startGlobalSocket(jwt, user);
  //   }

  //   return () => {
  //     if (ws?.readyState === WebSocket.OPEN) {
  //       isSocketConnect.current = false;
  //       ws.close();
  //     }
  //   };

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user]);


  useEffect(() => {

    if (!waitingToReconnect) {
      return;
    }

    if (!wsNotification.current) {
      let jwtData: JWTResponseDTO = getLocalStorageObject(LOCAL_STORAGE.JWT);
      if (!jwtData || !jwtData.token) {
        return;
      }

      let userData: UserDTO = getLocalStorageObject(LOCAL_STORAGE.USER);
      if (!userData || !userData.uid) {
        return;
      }
      
      let wsURL =
      SERVER.WS_URL +
      "notifications?userId=" +
      userData.uid +
      "&token=" +
      jwtData.token;


      const client = new WebSocket(wsURL);
      wsNotification.current = client;
      wsNotification.current.onopen = () => {
        console.log('ws connected');
        getPoolRequested();
   
      }

      wsNotification.current.onmessage = (socketMessage: any) => {
        if (socketMessage?.data) {
          let message = JSON.parse(socketMessage.data);

          switch (message?.messageType) {
            case "MANAGED_REQ":
              managePoolRequest(message.msg);
              break;
            default:
              break;
          }
        }

      }
      wsNotification.current.onclose = () => {

        if (wsNotification.current) {
          
          
          console.log('ws closed by server');
        } else {
          
          setWaitingToReconnect(false);
          // Cleanup initiated from app side, can return here, to not attempt a reconnect
          console.log('ws closed by app component unmount');
          return;
        }

        // if (waitingToReconnect) {
        //   return;
        // };

        // Setting this will trigger a re-run of the effect,
        // cleaning up the current websocket, but not setting
        // up a new one right away
        setWaitingToReconnect(false);

        // This will trigger another re-run, and because it is false,
        // the socket will be set up again
        setTimeout(() => setWaitingToReconnect(true), 500);

      }
      return () => {

        console.log('Cleanup');
        // Dereference, so it will set up next time
        wsNotification.current = null;

        client.close();
      }
    }
  }, [waitingToReconnect]);
  // Starting web socket on app level
  // const startGlobalSocket = (jwt: JWTResponseDTO, user: UserDTO) => {
    
  //     if (ws && ws.readyState === WebSocket.OPEN){
  //       ws.close();
  //     } 
  //     let wsURL =
  //       SERVER.WS_URL +
  //       "notifications?userId=" +
  //       user.uid +
  //       "&token=" +
  //       jwt.token;

  //     ws = new WebSocket(wsURL);

  //     ws.onopen = () => {
  //       CLOG("___WSO___GLOBAL SOCKET OPEN___", "green");
  //       isSocketConnect.current = true;
  //       if (interval.current) {
  //         clearInterval(interval.current);
  //       }
  //       getPoolRequested();
  //     };

  //     ws.onclose = () => {
  //       CLOG("___WSO___GLOBAL SOCKET CLOSED___", "orange");

  //       if (isSocketConnect.current) {
  //         isSocketConnect.current = false;
  //         console.info(
  //           "-o-o-o-o- NOTIFICATION SOCKET RECONNECTING IN 2 SECONDS"
  //         );

  //         if (interval.current) {
  //           clearInterval(interval.current);
  //         }
  //         let jwt = getLocalStorageObject(LOCAL_STORAGE.JWT);
  //         let user = getLocalStorageObject(LOCAL_STORAGE.USER);
  //         if (jwt && user) {
  //           interval.current = setInterval(() => {
  //             if (!isSocketConnect.current) startGlobalSocket(jwt, user);
  //           }, 2000);
  //         }        
  //       }
  //     };

  //     ws.onerror = () => {
  //       CLOG("___WSO___GLOBAL SOCKET ERROR___", "red");
  //     };

  //     ws.onmessage = (socketMessage: any) => {
  //       if (socketMessage?.data) {
  //         let message = JSON.parse(socketMessage.data);

  //         switch (message?.messageType) {
  //           case "MANAGED_REQ":
  //             managePoolRequest(message.msg);
  //             break;
  //           default:
  //             break;
  //         }
  //       }
  //     };
    
  // };

  const getPoolRequested = () => {
    ScheduleApiService.getAllUsersPoolReq()
      .then((res) => {
        if (res && res.data) {
          for (const reqObj of res.data) {
            if (reqObj.currentUserId === user.uid) {
              requestNotificationList.current.push(reqObj);
            }
          }
          setRequests([...requestNotificationList.current]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const managePoolRequest = (req: PoolRequestDTO) => {
    if (req.requestedUserId === user.uid) {
      if (req.requestStatus === "ACCEPTED") {
        return showToast(
          "In track " +
            getTrackName(req.poolKey.pzoneCode) +
            " race No " +
            req.poolKey.raceNo +
            "  request is accepted for " +
            req.poolKey.poolType.toLocaleLowerCase(),
          TOAST.SUCCESS
        );
      }
      if (req.requestStatus === "REJECTED") {
        return showToast(
          "In track " +
            getTrackName(req.poolKey.pzoneCode) +
            " race No " +
            req.poolKey.raceNo +
            "  request is rejected for " +
            req.poolKey.poolType.toLocaleLowerCase(),
          TOAST.WARNING
        );
      }
    } else if (
      req.currentUserId === user.uid &&
      req.requestStatus === "PENDING"
    ) {
      requestNotificationList.current.push(req);
      setRequests([...requestNotificationList.current]);
    } else if (req.currentUserId === user.uid) {
      deleteReqObj(req.reqId);
      if (req.requestStatus === "ACCEPTED") {
        return showToast(
          "Track " +
            getTrackName(req.poolKey.pzoneCode) +
            "  race No " +
            req.poolKey.raceNo +
            "  is accepted the request for  " +
            req.poolKey.poolType.toLocaleLowerCase(),
          TOAST.SUCCESS
        );
      }
      if (req.requestStatus === "REJECTED") {
        return showToast(
          "Track " +
            getTrackName(req.poolKey.pzoneCode) +
            "  race No " +
            req.poolKey.raceNo +
            "  is rejected the request for  " +
            req.poolKey.poolType.toLocaleLowerCase(),
          TOAST.WARNING
        );
      }
    }
  };

  const deleteReqObj = (reqId: string) => {
    let index = requestNotificationList.current.findIndex(
      (x) => x.reqId === reqId
    );
    if (index !== -1) {
      requestNotificationList.current.splice(index, 1);
      setRequests([...requestNotificationList.current]);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <PoolReqNotification
        requestedNotifications={requests}
        deleteReqObj={deleteReqObj}
      />

      <div className="footer-container">
        <div className="footer-content">
          <div>
            &copy;{" "}
            <a
              href="https://www.protocolzone.com"
              rel="noreferrer"
              target="_blank"
            >
              {" "}
              ProtocolZone.com
            </a>
          </div>
          <div>version - 0.0.1</div>
        </div>
      </div>
    </div>
  );
}
export default Footer;
