import { useContext, useState } from "react";
import { useEffect } from "react";
import { RulesContext } from "../../../../Context/rulesContext";
import {
  _formatRule,
  _sortPoolRules,
} from "../../../../Helpers/RulesManage/rulesHelper";
import { PrimeItem } from "../../../../Helpers/StaticData/race-data";
import { CLOG, showAPIToast } from "../../../../Helpers/ui-helper";
import { PoolRuleDTO } from "../../../../Models/poolRuleDTO";
import { ConnectorDTO } from "../../../../Models/temp";
import { RulesService } from "../../../../services/rulesApiService";
import { ScreenType } from "../ScratchProfile/scratchProfile";
import AddPoolRuleScreen from "./Screens/addToteRule";
import ViewPoolRulesScreen from "./Screens/viewToteRules";

export interface IPoolRulesProps {}

export default function ToteRules(props: IPoolRulesProps) {
  const ruleContext = useContext(RulesContext);
  const [selectedScreen, setSelectedScreen] = useState(ScreenType.VIEW);
  const [connectorList, setConnectorList] = useState([] as any);
  
  // const {setConnectorOptions} = useContext(ScheduleContext);
  useEffect(() => {
      // if (!ruleContext.poolRules) {
    getPoolRules();
    onSetConnectorOptions();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSetConnectorOptions = () => {
    let options = [] as PrimeItem[];
    RulesService.getConnectors()
      .then((res) => {
        CLOG("=>___API___Connectors Options____ ", "purple");
        if (res.data) {
          let connectors = res.data as ConnectorDTO[];
          for (let connector of connectors) {
            options.push({
              label: connector.connectorName,
              value: connector.accountId,
              currency:connector.currency
            });
          }
          setConnectorList(options);
          // setConnectorOptions({...options});        
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
   
  const getPoolRules = () => {
    let context = { ...ruleContext };
    context.startLoader();
    RulesService.getPoolRules()
      .then((res) => {
        if (res.data) {
          CLOG("=> API____POOL RULES FETCHED", "purple");

          let poolRules = res.data as PoolRuleDTO[];
          let poolRuleRows = [] as PoolRuleDTO[];
          let ruleObj = {} as any;
          for (let poolRule of poolRules) {
            let _rule = _formatRule(poolRule);
            ruleObj[poolRule.poolRuleKey.provider] = _rule;
            poolRuleRows.push(_rule);
          }
          context.poolRules = ruleObj;
          context.poolRuleRows = _sortPoolRules(poolRuleRows);
        }
        context.isLoading = false;
        context.updateRuleContext(context);
      })
      .catch((err) => {
        context.stopLoader();
        console.error(err);
        console.log(err);
        showAPIToast(err, "while fetching tote rules", "rules_fetch");
      });
  }
  
  const changeScreen = (screen: number) => {
    if (screen === 1) {
      setSelectedScreen(ScreenType.VIEW);
    } else {
      setSelectedScreen(ScreenType.ADD);
    }
  };

  const ScreenTemplate = () => {
    switch (selectedScreen) {
      case ScreenType.VIEW:
        return <ViewPoolRulesScreen changeScreen={changeScreen} connectorList={connectorList} getPoolRules={getPoolRules}/>;
      case ScreenType.ADD:
        return <AddPoolRuleScreen changeScreen={changeScreen}  getPoolRules={getPoolRules}  connectorList={connectorList}/>;
      default:
        break;
    }
  };
  return <div>{ScreenTemplate()}</div>;
}
