import { format } from "date-fns";
import { ChangeEvent, useState } from "react";

//helper
import { FORMAT } from "../../../../Helpers/constants";
import { showToast, TOAST } from "../../../../Helpers/ui-helper";
import { validateMinValue } from "../../../../Helpers/Validators/input-validations";

//models
import { RaceDetailsDTO } from "../../../../Models/RaceDetailsDTO";
import { TrackInfoDTO } from "../../../../Models/trackInfoDTO";

//ui compont
import DateTimePicker from "../../../../UI-Components/Inputs/dateTime";
import PZInputField from "../../../../UI-Components/Inputs/inputField";

export interface IRaceDetailsStepProps {
  onStepChange: (index: number) => void;

  newRaceDetails: RaceDetailsDTO;
  setNewRaceDetails: (raceDetails: RaceDetailsDTO) => void;

  selectedTrack: TrackInfoDTO;
  setSelectedTrack: (track: TrackInfoDTO) => void;

  selectedDate: Date;
  setSelectedDate: (date: Date) => void;

}

export default function RaceDetailsStep(props: IRaceDetailsStepProps) {
  const [selectedPostTime, setSelectedPostTime] = useState(new Date());


  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let raceDetails = { ...props.newRaceDetails } as any;
    raceDetails[e.target.name] = e.target.value;
    props.setNewRaceDetails(raceDetails);
  };



  const onPostTimeChange = (postTime: any) => {
    try {
      setSelectedPostTime(postTime);
      let race = { ...props.newRaceDetails };
      race.postTime = format(postTime, FORMAT.yyyyMMddHHmmss);
      race.formattedPosttime = format(postTime, FORMAT.HHmma_COLON);
      props.setNewRaceDetails(race);


    } catch (error) {
      console.error(error);
    }
  }

  const handleRaceKeyChange = (e: ChangeEvent<HTMLInputElement>) => {
    let raceDetails = { ...props.newRaceDetails } as any;
    let valid = validateMinValue(Number(e.target.value), 0);
    if (!valid) {
      return;
    }
    raceDetails.raceKey.raceNo = Number(e.target.value);
    props.setNewRaceDetails(raceDetails);
  };

  const getNumberValue = (value: string) => (value === "0" ? "" : value);

  const onStepChange = (step: number) => props.onStepChange(step);

  const onNext = (step: number) => {

    if (props.newRaceDetails?.raceKey.raceNo === 0) {
      showToast("Enter Race No", TOAST.ERROR);
      return;
    }

    props.onStepChange(step);
  };


  return (
    <div className="pz-card w-75 m-auto">
      <div className="pz-card-header">Enter race details in below form...</div>

      <div className="pz-card-body d-flex p-3">
        <div className="w-100 input-grid">
          <PZInputField
            onChange={() => { }}
            type="text"
            value={props.selectedTrack.province}
            label="Province"
            disabled
          />
          <PZInputField
            onChange={() => { }}
            type="text"
            value={props.selectedTrack.trackName}
            label="Track Name"
            disabled
          />
          <PZInputField
            onChange={() => { }}
            type="text"
            value={props.selectedTrack.eventType}
            label="Event Type"
            disabled
          />
          <PZInputField
            className=""
            placeholder="Race No"
            onChange={handleRaceKeyChange}
            name="raceNo"
            type="number"
            value={getNumberValue(
              props.newRaceDetails?.raceKey?.raceNo?.toString()
            )}
            label="Race No"
          />
          <PZInputField
            className=""
            placeholder="Enter type of course"
            onChange={handleChange}
            name="courseType"
            type="text"
            value={props.newRaceDetails?.courseType}
            label="Course Type"
          />
          <PZInputField
            className=""
            placeholder="Enter total distance of race"
            onChange={handleChange}
            name="distance"
            type="number"
            value={getNumberValue(props.newRaceDetails?.distance?.toString())}
            label="Distance"
          />

          <DateTimePicker
            value={props.selectedDate}
            onChange={(e: any) => props.setSelectedDate(e.target.value)}
            label="Local Date"
            minDate={new Date()}
          />

          <DateTimePicker
            value={selectedPostTime}
            onChange={(e: any) => onPostTimeChange(e.target.value)}
            label="Post Time"
            timeOnly
          />

          <PZInputField
            className=""
            placeholder="Enter provider Id"
            onChange={handleChange}
            name="providerId"
            type="text"
            value={props.newRaceDetails?.providerId}
            label="Provider"
          />
          <PZInputField
            className=""
            placeholder="Enter race type"
            onChange={handleChange}
            name="raceType"
            type="text"
            value={props.newRaceDetails?.raceType}
            label="Race Type"
          />

          <PZInputField
            className=""
            placeholder="Enter Track Variant"
            onChange={handleChange}
            name="trackVariant"
            type="number"
            value={getNumberValue(
              props.newRaceDetails?.trackVariant?.toString()
            )}
            label="Track Variant"
          />

          <PZInputField
            className=""
            placeholder="EnterSurface Type"
            onChange={handleChange}
            name="surfaceType"
            type="text"
            value={props.newRaceDetails?.surfaceType}
            label="Surface Type"
          />
          <PZInputField
            className=""
            placeholder="Enter track's condition"
            onChange={handleChange}
            name="trackCondition"
            type="text"
            value={props.newRaceDetails?.trackCondition}
            label="Track Condition"
          />

          <PZInputField
            className=""
            placeholder="Enter weather condition"
            onChange={handleChange}
            name="weather"
            type="text"
            value={props.newRaceDetails?.weather}
            label="Weather"
          />
          <PZInputField
            className=""
            placeholder="Enter Track Temprature"
            onChange={handleChange}
            name="raceTemp"
            type="number"
            value={getNumberValue(props.newRaceDetails?.raceTemp?.toString())}
            label="Temprature"
          />
          <PZInputField
            className=""
            placeholder="Enter wind direction"
            onChange={handleChange}
            name="windDirection"
            type="text"
            value={props.newRaceDetails?.windDirection}
            label="Wind Direction"
          />
          <PZInputField
            className=""
            placeholder="Enter wind speed"
            onChange={handleChange}
            name="windSpeed"
            type="number"
            value={getNumberValue(props.newRaceDetails?.windSpeed?.toString())}
            label="Wind Speed"
          />
        </div>
      </div>

      <div className="pz-card-footer text-right pr-4">
        <button
          onClick={() => onStepChange(1)}
          className="pz-btn text-btn mr-2"
        >
          <i className="fa fa-chevron-left"></i> &nbsp;&nbsp;&nbsp; Back
        </button>
        <button onClick={() => onNext(3)} className="pz-btn primary">
          Next &nbsp;&nbsp;&nbsp;<i className="fa fa-chevron-right"></i>
        </button>
      </div>
    </div>
  );
}
