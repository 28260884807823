import React, { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import "./CustomerRules.scss";
import { showToast, TOAST } from "../../../Helpers/ui-helper";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CustomerRulesCols } from "../../../Helpers/columns";
// api
import { UserApiService } from "../../../services/userApiService";

// interface
export interface CheckCustomerRules {
  ruleId: string;
  ruleName: string;
}

const CustomerRules = (props: any) => {
  // variables
  const { customerId, setIsCheckRules, isCheckRules } = props;
  const [customerRules, setCustomerRules] = useState<any>([]);
  const [globalFilter, setGlobalFilter] = useState("" as string);

  // callbacks
  useEffect(() => {
    UserApiService.getCustomerRules(customerId)
      .then((res) => {
        setCustomerRules(res.data);
      })
      .catch((error) => {
        showToast(error.response?.data.message, TOAST.ERROR);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   functions
  const handleCancel = () => {
    setIsCheckRules(false);
  };

  const handleFilter = (val: string) => {
    setGlobalFilter(val);
  };

  const header = (
    <div className="d-flex justify-content-end">
      <div className="search-input">
        <input
          placeholder="Global Search"
          type="search"
          value={globalFilter}
          onChange={(e: any) => handleFilter(e.target.value)}
        />
        <i className="fa fa-search icon"></i>
      </div>
    </div>
  );

  return (
    <div className="sidebar_wrapper">
      <Sidebar visible={isCheckRules} onHide={handleCancel} className="sidebar">
        <div className="sidebar_body">
          {customerRules.length !== 0 ? (
            <>
              <div className="title">Passed Customer Rules</div>
              <div className="table_body_wrapper">
                <DataTable
                  value={customerRules}
                  globalFilter={globalFilter}
                  header={header}
                  emptyMessage={`No Data Found  "${globalFilter}"`}
                >
                  {CustomerRulesCols.map((col, indx) => {
                    return (
                      <Column
                        key={indx}
                        field={col.field}
                        sortable
                        header={col.header}
                      />
                    );
                  })}
                </DataTable>
              </div>
            </>
          ) : (
            <div className="empty_data">
              <span>No Rules Found</span>
            </div>
          )}
        </div>
      </Sidebar>
    </div>
  );
};

export default CustomerRules;
