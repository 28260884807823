import { confirmPopup } from "primereact/confirmpopup";
import { useContext, useEffect, useState } from "react";
import { ScheduleContext } from "../../../../Context/scheduleContext";
import {
  CurrencyList,
  FOPoolsList,
} from "../../../../Helpers/StaticData/pool-data-static";
import { showAPIToast, showToast, TOAST } from "../../../../Helpers/ui-helper";
import { validateMinValue } from "../../../../Helpers/Validators/input-validations";
import {
  getNumberValue,
  onPoolKeySeparate,
} from "../../../../Helpers/valueHelper";
import { PoolConfigDTO, PoolDTO } from "../../../../Models/PoolsDTO";
import { ScheduleApiService } from "../../../../services/scheduleApiService";
import PzCheckBox from "../../../../UI-Components/Inputs/checkBoxInput";
import PZInputField from "../../../../UI-Components/Inputs/inputField";
import PzDropDown from "../../../../UI-Components/Inputs/pzDropDown";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { FixOddsRuleList, MAIValuesList } from "../../../../Helpers/constants";
import DateTimePicker from "../../../../UI-Components/Inputs/dateTime";
import { format } from "date-fns";
import { timeZoneConversion } from "../../../../Helpers/dateTimeHelpers";

export interface IAddNewPoolFormProps {
  onFilterConnectorList: any;
}
enum PoolCategory {
  TOTE,
  FO,
}

export default function AddNewPoolForm(props: IAddNewPoolFormProps) {
  const InitPoolKey = {
    localDate: 0,
    poolType: "",
    provider: "DEFAULT",
    pzoneCode: "",
    raceNo: 0,
  };
  const InitPoolConfig: PoolConfigDTO = {
    openTime: "",
    lastUpdated: null,
    minBookPer: 0,
    isSystemOpen: false,
    accountId: "",
    scratchedDedPer: 0,
    deductionUsing: null,
    scratchedProfileId: "",
    fixOddsValue: 0,
    fixOddsRule: 0,
    userId: "",
    oddsManagedBy: null,
    timeZone: "",
    mbaType: "",
    mbaVal: 0,
    layoffPer: 0,
  };

  const InitPool: PoolDTO = {
    poolConfig: InitPoolConfig,
    poolDetails: {
      poolKey: InitPoolKey,
      layOff: false,
      cancelled: false,
      currency: "",
      incBetAmt: 0,
      lastUpdated: 0,
      minBetAmt: 0,
      otherLegs: "",
      payOffList: [],
      payoff: {},
      linkedWith: "",
      poolKeyString: "",
      skipFields: null,
      providerId: "",
      resulted: false,
      poolStatus: "NON_DISPLAY",
    },
  };

  const {
    usersList,
    scrProfileList,
    selectedRace,
    stopLoader,
    startLoader,
    setPoolToEdit,
    togglePoolForm,
    totalRaces,
  } = useContext(ScheduleContext);

  const [pool, setPool] = useState(InitPool);
  const [isFOPool, setisFOPool] = useState(false);
  const [linkedPoolOptions, setlinkedPoolOptions] = useState([]);

  const [selectedPoolCategory, setSelectedPoolCategory] = useState(
    PoolCategory.TOTE
  );
  const [poolTypeList, setPoolTypeList] = useState([]);
  const [poolSelected, setPoolSelected] = useState<any>(null);
  const [selectedLinkedWith, setSelectedLinkedWith] = useState(null);
  const [openTime, setOpenTime] = useState(new Date());

  useEffect(() => {
    resetPoolDetails();
    onSetPoolCategory(PoolCategory.TOTE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetPoolDetails = () => {
    let _pool = { ...pool };
    _pool.poolDetails.poolKey = { ...InitPoolKey };
    _pool.poolDetails.poolKey.poolType = "";
    _pool.poolDetails.linkedWith = "";
    _pool.poolDetails.minBetAmt = null as any;
    _pool.poolDetails.incBetAmt = null as any;
    _pool.poolDetails.otherLegs = "";
    _pool.poolDetails.currency = "";
    _pool.poolDetails.layOff = false;
    _pool.poolConfig = { ...InitPoolConfig };
    setPool(_pool);
  };

  const onBack = () => {
    setPoolToEdit(null);
    togglePoolForm(false);
  };

  const onPoolBooleansChange = (e: any) => {
    let _pool = { ...pool } as any;
    _pool.poolDetails[e.name] = e.value;
    setPool(_pool);
  };

  const onPoolDetailChange = (e: any) => {
    let _pool = { ...pool } as any;
    let name = e.target.name;
    let value = e.target.value;
    if (name === "minBetAmt" || name === "incBetAmt") {
      let valid = validateMinValue(Number(value), 0);
      if (!valid) return console.error("not valid");
    }
    _pool.poolDetails[e.target.name] = value;
    setPool(_pool);
  };

  const handleLayoutPoolChange = (e: any) => {
    let _pool = { ...pool } as any;
    const value = e.target.value;
    if ((Number(value) && value <= 0) || value > 100) {
      return showToast(
        "Layoff Percentage should be between 1 to 100",
        TOAST.ERROR
      );
    }
    _pool.poolConfig[e.target.name] = value;
    setPool(_pool);
  };

  const onSetPoolCategory = (poolCategory: PoolCategory) => {
    let tList: any = [];

    if (poolCategory === PoolCategory.TOTE) {
      tList = FOPoolsList.filter((x) => !x.value.startsWith("FO_"));
    } else if (poolCategory === PoolCategory.FO) {
      tList = FOPoolsList.filter((x) => x.value.startsWith("FO_"));
    }

    setSelectedPoolCategory(poolCategory);
    setPoolTypeList(tList);
    setlinkedPoolOptions([]);
    setPoolSelected(null);
    setSelectedLinkedWith(null);
  };

  const onPoolTypeChange = (e: any) => {
    startLoader();
    let poolCategory = selectedPoolCategory === 0 ? "TOTE" : "FO";
    let _pool = { ...pool };
    _pool.poolDetails.poolKey = { ...InitPoolKey };
    _pool.poolDetails.linkedWith = "";
    _pool.poolDetails.minBetAmt = null as any;
    _pool.poolDetails.incBetAmt = null as any;
    _pool.poolDetails.otherLegs = "";
    _pool.poolDetails.currency = "";
    _pool.poolConfig = { ...InitPoolConfig };

    if (poolCategory === "FO") {
      setisFOPool(true);
      let dateConvertStr = format(new Date(), "yyyyMMddHHmmss");
      let strDateTime = timeZoneConversion(
        selectedRace.timezoneId,
        dateConvertStr
      );
      setOpenTime(strDateTime);
      _pool.poolConfig.openTime = format(
        new Date(strDateTime),
        "yyyyMMddHHmmss"
      );
    } else {
      _pool.poolDetails.layOff = false;
      setisFOPool(false);
    }
    setPool(_pool);
    let country = selectedRace.raceKey.pzoneCode.split("-")[0];
    setPoolSelected(e.value);
    _getLinkedWithPools(country, poolCategory);
    setSelectedLinkedWith(null);
  };

  const _getLinkedWithPools = (country: string, poolCategory: string) => {
    ScheduleApiService.getProviderList(country, poolCategory)
      .then((res) => {
        stopLoader();
        if (res.data) {
          setlinkedPoolOptions(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        stopLoader();
        showAPIToast(err, "while fetchin linked with pools!", "linked_with");
      });
  };

  const onConfigDropChange = (e: any) => {
    if (e.target.value === null) {
      return;
    }
    let _pool = { ...pool } as any;
    if (!_pool.poolConfig) {
      _pool.poolConfig = InitPoolConfig;
    }
    _pool.poolConfig[e.target.name] = e.target.value;
    if (e.target.name === "openTime") {
      setOpenTime(e.target.value);
      let dateConvertStr = format(e.target.value, "yyyyMMddHHmmss");
      _pool.poolConfig[e.target.name] = dateConvertStr;
    }
    setPool(_pool);
  };

  const onPoolKeyDropDownChange = (e: any) => {
    let _pool = { ...pool } as any;
    _pool.poolDetails.poolKey[e.target.name] = e.target.value;
    setPool(_pool);
  };

  const onDropdownChange = (e: any) => {
    let _pool = { ...pool } as any;
    _pool.poolDetails[e.target.name] = e.target.value;
    setPool(_pool);
  };

  const onLinkedWithPoolChange = (e: any) => {
    let _pool = { ...pool };
    _pool.poolDetails.linkedWith = e.target.value.provider;
    _pool.poolDetails.currency = e.target.value.currency;
    setSelectedLinkedWith(e.target.value);
    setPool(_pool);
  };

  const checkValidationOnPool = () => {
    const poolType = "DOUBLE";
    if (Object.values(poolSelected).includes(poolType)) {
      if (pool.poolDetails?.otherLegs.length === 0) {
        showToast("Other Lags Is Required", TOAST.ERROR);
        return false;
      } else if (selectedRace.raceKey.raceNo === totalRaces) {
        showToast(
          `you cannot create double pull on this race ${totalRaces}`,
          TOAST.ERROR
        );
        return false;
      } else if (
        pool.poolDetails?.otherLegs <= selectedRace.raceKey.raceNo ||
        pool.poolDetails?.otherLegs > totalRaces
      ) {
        showToast(
          `Race should be greater than ${selectedRace.raceKey.raceNo} or less  or equal ${totalRaces}`,
          TOAST.ERROR
        );
        return false;
      }
    }
    return true;
  };

  const validateEditPool = () => {
    if (!poolSelected) {
      showToast("Please select pool type!", TOAST.ERROR, "poolType");
      return false;
    }
    if (checkValidationOnPool() === false) return;

    // if (!pool.poolDetails.poolKey.provider) {
    //   showToast("Please select provider!", TOAST.ERROR, "provider");
    //   return false;
    // }
    if (
      !pool.poolDetails.linkedWith &&
      pool.poolConfig?.oddsManagedBy === "AUTO"
    ) {
      showToast("Please select linked with pool.", TOAST.ERROR);
      return false;
    }
    if (!pool.poolDetails.currency) {
      showToast("Please select currency!", TOAST.ERROR, "currency");
      return false;
    }
    if (!pool.poolDetails.minBetAmt || !pool.poolDetails.incBetAmt) {
      showToast("Please select Bet Amount!", TOAST.ERROR, "BetAmount");
      return false;
    }
    if (isFOPool && Number(pool.poolConfig?.minBookPer) < 100) {
      showToast("minBookPer value should be greaterthan 100 !", TOAST.ERROR);
      return false;
    }
    return true;
  };

  const confirmNewAdd = (event: any) => {
    if (validateEditPool()) {
      confirmPopup({
        target: event.currentTarget,
        message: "Are you sure you want to proceed to add this pool?",
        icon: "pi pi-exclamation-triangle",
        accept: () => addNewPoolData(),
        reject: () => {},
      });
    }
  };
  // const openRaceInSchedule = () => {
  //   localStorage.setItem(
  //     LOCAL_STORAGE.SELECTEDRACEKEY,
  //     JSON.stringify(selectedRace.raceKey)
  //   );
  //   history.push(ROUTES.RISK_MANAGEMENT);
  // };

  const modifyPoolData = () => {
    let _pool = { ...pool } as any;
    delete _pool.poolDetails.payOffList;
    delete _pool.poolDetails.poolKeyString;

    if (!isFOPool) {
      delete _pool.poolConfig.mbaType;
      delete _pool.poolConfig.mbaVal;
    }

    _pool.poolConfig.timeZone = selectedRace.timezoneId;
    let reqPoolObj: any = {};
    reqPoolObj.poolDto = _pool;
    reqPoolObj.pools = poolSelected;
    reqPoolObj.raceKey = selectedRace.raceKey;
    reqPoolObj.poolDto.poolDetails.poolKey = null;
    reqPoolObj.poolDto.poolConfig.layoffPer = Number(
      pool.poolConfig?.layoffPer
    );

    delete reqPoolObj.poolDto.poolConfig.poolKey;
    return reqPoolObj;
  };

  const addNewPoolData = () => {
    let pool = modifyPoolData();
    ScheduleApiService.getCreatePools(pool)
      .then((res) => {
        if (res && res.data) {
          for (const data of res.data) {
            if (data.trackPoolStatus === "SUCCESS") {
              showToast(
                "In " +
                  onPoolKeySeparate(data.poolKey) +
                  "  Pool Added Successfully",
                TOAST.SUCCESS,
                data.poolKey
              );
            } else if (data.trackPoolStatus === "FAIL") {
              showToast(
                data.message + " In  " + onPoolKeySeparate(data.poolKey),
                TOAST.ERROR,
                data.poolKey
              );
            }
          }
          togglePoolForm(false);
        }
      })
      .catch((err) => {
        console.log(err);
        showAPIToast(err, "while updating pool data");
      });
  };

  const FOInputTemplate = () => {
    if (isFOPool)
      return (
        <>
          {/* <PzDropDown
            label="Odds Manage By"
            value={pool.poolConfig?.oddsManagedBy}
            options={ManagedByList}
            className="py-4"
            name="oddsManagedBy"
            onChange={onConfigDropChange}
          /> */}
          <PzDropDown
            label="Scratch Profile ID"
            value={pool.poolConfig?.scratchedProfileId}
            options={scrProfileList}
            className="py-4"
            name="scratchedProfileId"
            onChange={onConfigDropChange}
          />
          <PZInputField
            className="py-4"
            onChange={onConfigDropChange}
            type="number"
            name="minBookPer"
            value={getNumberValue(pool?.poolConfig?.minBookPer ?? 0)}
            label="minBookPer"
          />
          <PzDropDown
            label="Max Bet Amount Type"
            value={pool?.poolConfig?.mbaType}
            options={MAIValuesList}
            className="py-4"
            name="mbaType"
            onChange={onConfigDropChange}
          />
          <PZInputField
            className="py-4"
            onChange={onConfigDropChange}
            type="number"
            name="mbaVal"
            value={getNumberValue(pool?.poolConfig?.mbaVal ?? 0)}
            label="Max Bet Amount Value"
          />
          <PzDropDown
            label="FixOdds Rule"
            value={pool?.poolConfig?.fixOddsRule}
            options={FixOddsRuleList}
            className="py-4"
            name="fixOddsRule"
            onChange={onConfigDropChange}
          />
          {(pool?.poolConfig?.fixOddsRule === "TIMED" ||
            pool.poolConfig?.fixOddsRule === "LIB") && (
            <PZInputField
              className="py-4"
              onChange={onConfigDropChange}
              type="number"
              name="fixOddsValue"
              value={getNumberValue(pool?.poolConfig?.fixOddsValue ?? 0)}
              label={pool?.poolConfig?.fixOddsRule.toLowerCase() + " value"}
            />
          )}
          <div className="input-container py-2">
            <div className="input">
              <label className="input-label">Open Time : -</label>
              <DateTimePicker
                value={openTime}
                onChange={onConfigDropChange}
                name="openTime"
                id="time24"
                showTime
                showSeconds
              />
            </div>
          </div>
        </>
      );
  };

  const manualManageTemplate = () => {
    if (pool.poolConfig?.oddsManagedBy === "MANUAL")
      return (
        <>
          <PzDropDown
            label="Managing User"
            placeHolder="Select user to manage"
            value={pool.poolConfig?.userId ?? ""}
            options={usersList}
            className="py-4"
            name="userId"
            onChange={onConfigDropChange}
          />
        </>
      );
  };

  const connectorTemplate = () => {
    if (!poolSelected) return null;
    if (isFOPool && !pool.poolDetails.layOff) return null;
    let connectorsOptions = props.onFilterConnectorList(pool);
    return (
      <>
        <PzDropDown
          label="Connector"
          value={pool.poolConfig?.accountId ?? ""}
          options={connectorsOptions ?? []}
          className="py-4"
          emptyMsg="No connector account info available!"
          name="accountId"
          onChange={onConfigDropChange}
        />
        {pool.poolDetails?.layOff && (
          <PZInputField
            className="py-4"
            onChange={handleLayoutPoolChange}
            type="text"
            name="layoffPer"
            value={getNumberValue(pool.poolConfig?.layoffPer ?? 0)}
            label="Layoff Per"
          />
        )}
      </>
    );
  };

  return (
    <div className="pl-form-container">
      <div className="form-title">
        <span></span>
        <span>Add New Pool</span>
        <button className="pz-btn icon-btn hover" onClick={onBack}>
          <i className="fa fa-arrow-right"></i>
        </button>
      </div>
      <div className="form-body">
        <div className="row m-0">
          <div className="col-6 b-right m-0">
            <div className="col-title">Enter Pool Details</div>

            <div className="pooltype">
              <button
                className={
                  selectedPoolCategory === PoolCategory.TOTE
                    ? "active-selectedPoolCategory"
                    : "selectedPoolCategory"
                }
                onClick={() => onSetPoolCategory(PoolCategory.TOTE)}
              >
                TOTE
              </button>
              <button
                className={
                  selectedPoolCategory === PoolCategory.FO
                    ? "active-selectedPoolCategory"
                    : "selectedPoolCategory"
                }
                onClick={() => onSetPoolCategory(PoolCategory.FO)}
              >
                FO
              </button>
            </div>

            <div className="input-grid-2 p-2">
              <div
                className="input-container"
                style={{
                  width: "auto",
                  display: "inline",
                  alignSelf: "center",
                }}
              >
                <div className="input">
                  <label className="input-label">Pool Type</label>
                  <MultiSelect
                    value={poolSelected}
                    options={poolTypeList}
                    onChange={onPoolTypeChange}
                    optionLabel="label"
                    className="prime-drop"
                  />
                </div>
              </div>
              <div
                className="input-container"
                style={{
                  width: "auto",
                  display: "inline",
                  alignSelf: "center",
                }}
              >
                <div className="input">
                  <label className="input-label">Linked With</label>
                  <Dropdown
                    options={linkedPoolOptions}
                    onChange={onLinkedWithPoolChange}
                    className="prime-drop"
                    optionLabel="provider"
                    value={selectedLinkedWith ?? ""}
                  />
                </div>
              </div>
              {/* <PzDropDown
                label="Provider"
                value={pool?.poolDetails?.poolKey?.provider ?? ""}
                options={ProvidersList}
                className="py-4"
                name="provider"
                onChange={onPoolKeyDropDownChange}
                disabled
              /> */}
              <PZInputField
                className="py-4"
                onChange={onPoolKeyDropDownChange}
                type="string"
                name="minBetAmt"
                value="DEFAULT"
                label="Provider"
                disabled
              />
              <PzDropDown
                label="Currency"
                value={pool?.poolDetails?.currency ?? ""}
                options={CurrencyList}
                className="py-4"
                name="currency"
                onChange={onDropdownChange}
              />
              <PZInputField
                className="py-4"
                onChange={onPoolDetailChange}
                type="number"
                name="minBetAmt"
                value={getNumberValue(pool?.poolDetails?.minBetAmt ?? 0)}
                label="Min Bet Amount"
              />
              <PZInputField
                className="py-4"
                onChange={onPoolDetailChange}
                type="number"
                name="incBetAmt"
                value={getNumberValue(pool?.poolDetails?.incBetAmt ?? 0)}
                label="Inc. Bet Amount"
              />
              <PZInputField
                className="py-4"
                onChange={onPoolDetailChange}
                type="text"
                name="otherLegs"
                value={pool.poolDetails?.otherLegs ?? ""}
                label="Other Legs"
              />
            </div>
            <div className="d-flex pl-3">
              <PzCheckBox
                value={pool.poolDetails?.cancelled}
                onChange={onPoolBooleansChange}
                label="Canceled ?"
                name="cancelled"
              />
              {isFOPool && (
                <PzCheckBox
                  value={pool.poolDetails?.layOff}
                  onChange={onPoolBooleansChange}
                  label="Layoff ?"
                  name="layOff"
                />
              )}
            </div>
          </div>
          <div className="col-6 m-0">
            {/* <div className="col-title">Enter Pool Config</div> */}
            <div className="input-grid-2  p-2" style={{ marginTop: "5rem" }}>
              {connectorTemplate()}
              {FOInputTemplate()}
              {manualManageTemplate()}
            </div>
          </div>
        </div>
      </div>
      <div className="form-footer">
        <button onClick={confirmNewAdd} className="pz-btn primary rounded my-4">
          <i className="fa fa-check"></i> &nbsp; Submit
        </button>
      </div>
    </div>
  );
}
