import axios from "axios";
import { SERVER, LOCAL_STORAGE } from "../Helpers/constants";
import { getHeaders, getLocalStorageObject } from "../Helpers/valueHelper";
import { CustomerSummaryReq } from "../Models/CustomerSummaryData";
import { betHistoryRequest } from "../Models/TransactionDto";
import { UserDTO, UserRegisterDTO } from "../Models/UserManagementDto";

export const UserApiService = {
  userLogin(loginDto: any) {
    let url = SERVER.URL_USER_MNG + "user/login";
    return axios.post(url, loginDto);
  },
  userLogout() {
    let url = SERVER.URL_USER_MNG + "user/logout";
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  refreshToken() {
    let user = getLocalStorageObject(LOCAL_STORAGE.USER);
    let jwt = getLocalStorageObject(LOCAL_STORAGE.JWT);

    let url =
      SERVER.URL_USER_MNG +
      "user/refresh-token?uid=" +
      user?.uid +
      "&email=" +
      user?.email;
    let token = jwt?.type + " " + jwt?.token;
    let headers = { Authorization: token, Refresh: jwt?.refreshToken };
    return axios.get(url, { headers: headers });
  },
  addNewUser(userDto: any) {
    let header = getHeaders();
    let url = SERVER.URL_USER_MNG + "user/register";
    return axios.post(url, userDto, { headers: header });
  },
  updateUser(_user: UserDTO) {
    let url = SERVER.URL_USER_MNG + "user/update";
    let headers = getHeaders();
    let tempUser: UserRegisterDTO = {
      uid: _user.uid,
      email: _user.email,
      password: null as any,
      phoneNo: _user.phoneNo,
      roles: _user.roles,
      userName: _user.userName,
    };
    return axios.post(url, tempUser, { headers: headers });
  },
  deletUser(user: UserDTO) {
    let url = SERVER.URL_USER_MNG + "user/delete?uid=" + user.uid;
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  getAllUsers() {
    let url = SERVER.URL_USER_MNG + "user/all";
    let header = getHeaders();
    return axios.get(url, { headers: header });
  },
  reActivateDeletedUser(uid: string) {
    let headers = getHeaders();
    let url = SERVER.URL_USER_MNG + "user/re-activate?uid=" + uid;
    return axios.get(url, { headers: headers });
  },
  wagersHistoryData(date: string, poolType: string) {
    let headers = getHeaders();
    let url = SERVER.URL_RISK + "util/wagers/" + date + "/" + poolType;
    return axios.get(url, { headers: headers });
  },
  getCustomerHistoryData(requestBody: betHistoryRequest) {
    let headers = getHeaders();
    let url = SERVER.URL_RISK + "util/wagers";
    return axios.post(url, requestBody, { headers: headers });
  },
  getCustomerDataForExort(requestBody: betHistoryRequest) {
    let headers = getHeaders();
    let url = SERVER.URL_RISK + "util/export-wagers";
    return axios.post(url, requestBody, { headers: headers });
  },
  getProvinceList() {
    let headers = getHeaders();
    let url = SERVER.URL_SCHEDULE_MNG + "schedule/province";
    return axios.get(url, { headers: headers });
  },
  getRaceLogsData(date: string, logType: string, province: string) {
    let headers = getHeaders();
    let url =
      SERVER.URL_RISK +
      "report/log?date=" +
      date +
      "&logType=" +
      logType +
      "&province=" +
      province;
    return axios.get(url, { headers: headers });
  },
  getClientFilteredData(word: any) {
    let headers = getHeaders();
    let url = SERVER.URL_RISK + "customer/search-customer/" + word;
    return axios.get(url, { headers: headers });
  },
  getCustomerSummaryData(requestBody: CustomerSummaryReq) {
    let headers = getHeaders();
    let url = SERVER.URL_RISK + "customer/get-statistics";
    return axios.post(url, requestBody, { headers: headers });
  },
  getCustomerData(customerId: any) {
    let headers = getHeaders();
    let url = `${SERVER.URL_RISK}customer/statistics/${customerId}`;
    return axios.get(url, { headers: headers });
  },
  getActiveCustomersSession() {
    let headers = getHeaders();
    let url = SERVER.URL_RISK + "customer/get-active-customer-session";
    return axios.get(url, { headers: headers });
  },
  getCustomers(sessionId: any) {
    let headers = getHeaders();
    let url = SERVER.URL_RISK + "customer/get-customers";
    return axios.post(url, sessionId, { headers: headers });
  },
  getActiveCustomerSessions(customerId: string) {
    let headers = getHeaders();
    let url = SERVER.URL_RISK + "customer/get-active-session/" + customerId;
    return axios.get(url, { headers: headers });
  },
  getCustomerRules(customerId: string) {
    let headers = getHeaders();
    let url = `${SERVER.URL_RISK}customer/check-rules/${customerId}`;
    return axios.get(url, { headers: headers });
  },
  findCustomerReport(requestBody: any) {
    let headers = getHeaders();
    let url = `${SERVER.URL_RISK}customer/find-customer-report`;
    return axios.post(url, requestBody, { headers: headers });
  },
  getToteSummaryByDate(date: any) {
    let headers = getHeaders();
    let url = `${SERVER.URL_RISK}${SERVER.MODULE.BET}get-tote-summary-by-date/${date}`;
    return axios.get(url, { headers: headers });
  },
};
